import { useTranslation } from 'utils/translation';
import { ContentLayout, SearchInput } from 'Molecules';
import { Button, EmptyState, Infobox } from 'Atoms';
import { EUTaxonomyIllustration, NothingFoundIllustration } from 'Tokens';
import {
  CompanyAssessmentDetails,
  CompanyAssessmentsDocument_,
  QuarterEnum_Enum_,
  useCompanyAssessmentsQuery,
  useInvestorsQuery,
} from 'models';
import { useCurrentCompanyId } from 'utils/hooks';
import { useMemo, useState } from 'react';
import { Box, HStack, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PlayIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { useKnowledgeBase } from 'Features';
import { useUserSetting } from 'containers/Navigation';
import { ShareAssessmentModal } from './ShareAssessmentModal';
import { AccessStatusType } from './Assessment';
import { useShareCompanyAssessment, useUnshareCompanyAssessment } from 'containers/Investors';
import { ARTICLE_SIX_STEPS } from 'Features/KnowledgeBase/KnowledgeBaseArticles';
import { AssessmentsViewForPortfolioCompanies } from './AssessmentsViewForPortfolioCompanies';
import { AssessmentsViewForRegularCompanies } from './AssessmentsViewForRegularCompanies';
import {
  formatDisplayQuarterYear,
  formatQuarterYear,
  getEndQuarterYear,
  getQuarterFromDate,
  quarterValue,
} from 'utils/date';
import { ErrorElement } from 'App';

export const TAXONOMY_LEARN_MORE_MODAL = 'taxonomy_learn_more_modal_is_closed';

export const AssessmentsEmptyState = () => {
  const { t } = useTranslation(['assessment', 'common']);

  const navigate = useNavigate();

  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('common:report.noReport')}
        description={t('common:report.noReportDescription')}
        callToAction={{
          text: t('common:assessment.createAssessment'),
          variant: 'primary',
          onClick: () => navigate('new'),
        }}
        icon={<EUTaxonomyIllustration boxSize="120px" color="border.default" />}
      />
    </Box>
  );
};

export const AssessmentsSearchEmptyState = ({ setSearch }: { setSearch: (s: string) => void }) => {
  const { t } = useTranslation('common');
  return (
    <Box w="100%" flexGrow="1">
      <EmptyState
        title={t('common:search.filter.emptyTitle')}
        description={t('common:search.filter.emptyDescription')}
        icon={<NothingFoundIllustration boxSize="120px" />}
        callToAction={{
          text: t('common:search.filter.emptyBtn'),
          variant: 'secondary',
          onClick: () => {
            setSearch('');
          },
          leftIcon: <RefreshIcon color="inherit" />,
        }}
      />
    </Box>
  );
};

export const AssessmentsList = () => {
  const { companyId } = useCurrentCompanyId();
  const [cAssessment, setCompanyAssessment] = useState<CompanyAssessmentDetails>();
  const [selectedReportingPeriod, setSelectedReportingPeriod] = useState<string>();
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'home']);
  const { onOpen } = useKnowledgeBase();
  const [isTaxonomyLearnMoreClosed, onToggleTaxonomyModal] = useUserSetting(
    TAXONOMY_LEARN_MORE_MODAL,
    false
  );
  const {
    data,
    loading,
    error,
    refetch: refetchCompanyAssessments,
  } = useCompanyAssessmentsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });
  const assessments = useMemo(() => {
    return data?.assessments ?? [];
  }, [data]);

  const { data: investorsData } = useInvestorsQuery({
    variables: {
      companyId: companyId,
    },
    skip: !companyId,
  });

  const investors = useMemo(() => {
    return investorsData?.investors ?? [];
  }, [investorsData]);

  const {
    isOpen: isOpenShareModal,
    onClose: onCloseShareModal,
    onOpen: onOpenShareModal,
  } = useDisclosure();

  const [startQuarter, startYear] = useMemo(() => {
    const startDate = new Date(cAssessment?.startDate);
    const quarter = getQuarterFromDate(startDate);
    const year = startDate.getFullYear();

    return [quarter, year];
  }, [cAssessment]);

  const [endQuarter, endYear] = useMemo(() => {
    return getEndQuarterYear(startQuarter, startYear);
  }, [startQuarter, startYear]);

  const formattedPeriod = useMemo(
    () => formatQuarterYear(startQuarter, startYear),
    [startQuarter, startYear]
  );

  const hasInvestor = !!investors?.find(
    (investor) => formatQuarterYear(investor.quarter, investor.year) === formattedPeriod
  );

  const assessmentPeriod = useMemo(() => {
    if (hasInvestor && endYear) {
      return `${quarterValue[startQuarter as QuarterEnum_Enum_]}.${startYear} - ${endQuarter}.${endYear}`;
    }
    return `${formatDisplayQuarterYear(`${startQuarter}-${startYear}`)}`;
  }, [cAssessment, hasInvestor, startYear, startQuarter, endQuarter, endYear]);

  const { shareCompanyAssessment } = useShareCompanyAssessment();
  const { unshareCompanyAssessment } = useUnshareCompanyAssessment();
  const handleSaveChanges = async (updatedList: AccessStatusType[]) => {
    const changed = updatedList.filter(
      ({ portfolioCompanyId, portfolioId, assessmentId }) =>
        !investors.find(
          (investor) =>
            investor.portfolioId === portfolioId &&
            investor.id === portfolioCompanyId &&
            investor.sharedCompanyAssessmentId === assessmentId
        )
    );

    const added = changed.filter(({ assessmentId }) => assessmentId !== null);
    const removed = changed.filter(({ assessmentId }) => assessmentId === null);

    Promise.all([
      ...added.map(({ portfolioCompanyId, portfolioId, assessmentId }) =>
        shareCompanyAssessment(assessmentId ?? '', portfolioCompanyId, portfolioId)
      ),
      ...removed.map(({ portfolioCompanyId, portfolioId }) =>
        unshareCompanyAssessment(portfolioCompanyId, portfolioId)
      ),
    ]).then(() => {
      refetchCompanyAssessments();
      onCloseShareModal();
    });
  };

  const filteredAssessments = useMemo(() => {
    if (!search) return assessments;
    return assessments.filter((assessment) =>
      assessment.aggregate.title.toLowerCase().includes(search.toLowerCase())
    );
  }, [search, assessments]);

  if (error) {
    return <ErrorElement />;
  }

  return (
    <ContentLayout isLoading={loading} header={t('common:menu.assessments')}>
      {!isTaxonomyLearnMoreClosed && (
        <Infobox
          status="neutral"
          withIcon={false}
          title={t('home:emptyState.taxonomy.subTitle')}
          description={t('home:taxonomy.description')}
          onClose={() => onToggleTaxonomyModal(true)}
          extra={
            <Button
              ml="-8px"
              variant="ghost"
              onClick={() => onOpen(ARTICLE_SIX_STEPS.slug)}
              size="sm"
              color="text.selected"
              leftIcon={<PlayIcon color="inherit" />}
            >
              {t('home:emptyState.taxonomy.infoButton')}
            </Button>
          }
        />
      )}
      {assessments.length || investors.length ? (
        <>
          <HStack width="100%" justifyContent="space-between" paddingY="16px">
            <SearchInput
              placeholder={t('common:words.filter')}
              search={search}
              setSearch={setSearch}
            />
            {!investors.length && (
              <Button
                onClick={() => navigate('new')}
                minWidth="none"
                paddingX="16px"
                variant="primary"
                size="md"
              >
                {t('common:assessment.createAssessment')}
              </Button>
            )}
          </HStack>
          {filteredAssessments.length || investors.length ? (
            investors.length ? (
              <AssessmentsViewForPortfolioCompanies
                assessments={filteredAssessments}
                allAssessments={assessments}
                openModal={onOpenShareModal}
                investors={investors}
                setCompanyAssessment={setCompanyAssessment}
                setSelectedReportingPeriod={setSelectedReportingPeriod}
              />
            ) : (
              <AssessmentsViewForRegularCompanies
                assessments={filteredAssessments}
                investors={investors}
                setCompanyAssessment={setCompanyAssessment}
              />
            )
          ) : (
            <AssessmentsSearchEmptyState setSearch={setSearch} />
          )}
        </>
      ) : (
        <AssessmentsEmptyState />
      )}
      <ShareAssessmentModal
        key="share-assessment-modal"
        isOpen={isOpenShareModal}
        onClose={onCloseShareModal}
        investors={investors}
        cAssessment={cAssessment}
        reportingPeriod={selectedReportingPeriod}
        handleSaveChanges={handleSaveChanges}
        assessmentPeriod={assessmentPeriod}
      />
    </ContentLayout>
  );
};
