import { Box, Table as ChakraTable, HStack, Tr, VStack } from '@chakra-ui/react';
import { IconButton, Infobox, TruncatableText } from 'Atoms';
import { Modal } from 'Molecules';
import { Typography } from 'Tokens';
import { GetReportingUnitsQuery_, GetTargetQuery_, useDeleteMilestoneMutation } from 'models';
import { useEffect, useMemo } from 'react';
import {
  KeyResultEnums,
  MilestoneFields,
  TargetFields,
  TargetFieldsEnum,
} from '../../../Requirement';
import { getBUMilestoneTotal, getSubMilestoneTotal } from './Milestones.hooks';
import {
  AbsoluteValueCells,
  RelativeValueCells,
  TableHeader,
  TableTitleSection,
  BaselineCell,
  MilestoneDirectionIcon,
} from './MilestonesUtils';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { getMilestoneInfoBoxText } from '../TextUtils';
import { DeleteIcon } from 'Tokens/Icons/Function';
import { NA, NotAdded, TableTD, TotalWithWarning } from '../AddTargetUtils';
import { SubsidiariesTargetsType, openSubsidiary } from '../AddTargets.hooks';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { uniq } from 'lodash';
import { percentageToNumber } from 'containers/Esrs/utils';
import { isNullish } from 'utils/numbers';

const TopDownInputTable = ({
  milestone,
  baseline,
  isGroup,
  isBottomUp,
  handleChangeValue,
  companyName,
  unit,
}: {
  milestone: MilestoneFields | undefined;
  baseline: number;
  isGroup: boolean;
  isBottomUp: boolean;
  handleChangeValue: (fields: Partial<MilestoneFields>) => void;
  companyName: string;
  unit: string;
}) => {
  return (
    <VStack alignItems="start" width="100%">
      <TableTitleSection
        companyName={companyName}
        handleChangeValue={handleChangeValue}
        milestone={milestone}
        isBottomUp={isBottomUp}
        isGroup={isGroup}
        baseline={baseline}
      />
      <Box border="1px solid" borderColor="border.decorative" borderRadius="8px">
        <ChakraTable sx={{ tableLayout: 'fixed' }}>
          <TableHeader unit={unit} baseline={baseline} />
          <Tr height="48px">
            <TableTD hasBorder={false}>
              <Typography variant="bodyStrong" as="span">
                {companyName}
              </Typography>
            </TableTD>
            <TableTD hasBorder={false}>
              {baseline ? (
                <Typography variant="body" as="span">
                  {baseline}
                </Typography>
              ) : (
                <NA />
              )}
            </TableTD>
            {milestone?.isRelative ? (
              <RelativeValueCells
                milestoneValue={milestone?.value ?? 0}
                baseline={baseline}
                handleChangeValue={handleChangeValue}
              />
            ) : (
              <AbsoluteValueCells
                milestoneValue={milestone?.value ?? 0}
                baseline={baseline}
                handleChangeValue={handleChangeValue}
              />
            )}
          </Tr>
        </ChakraTable>
      </Box>
    </VStack>
  );
};

export const Milestones = ({
  isOpen,
  onClose,
  isGroup,
  selectedMilestone,
  companyName,
  subsidiariesTargets,
  reportingUnits,
  watch,
  setValue,
  hideDeleteButton,
  targetData,
  setMilestoneYears,
  unit,
}: {
  isOpen: boolean;
  onClose: () => void;
  isGroup: boolean;
  selectedMilestone: MilestoneFields | undefined;
  companyName: string;
  subsidiariesTargets: SubsidiariesTargetsType;
  reportingUnits?: GetReportingUnitsQuery_['reportingUnits'];
  watch: UseFormWatch<TargetFields>;
  setValue: UseFormSetValue<TargetFields>;
  hideDeleteButton: boolean;
  targetData: GetTargetQuery_['target'];
  setMilestoneYears: (param: number[]) => void;
  unit: string;
}) => {
  const [deleteMilestone] = useDeleteMilestoneMutation();
  const milestones = watch(TargetFieldsEnum.milestones) ?? [];

  const milestone = useMemo(
    () => milestones?.find((m) => m.year === selectedMilestone?.year) ?? selectedMilestone,
    [milestones, selectedMilestone]
  );

  const otherMilestones = useMemo(
    () => milestones?.filter((m) => m.year !== milestone?.year) ?? ([] as MilestoneFields[]),
    [milestones, milestone]
  );

  const isBottomUp = watch('isBottomUp');

  const handleChangeValue = (fields: Partial<MilestoneFields>) => {
    if (milestone) {
      setValue(
        TargetFieldsEnum.milestones,
        [
          ...otherMilestones,
          {
            ...milestone,
            ...fields,
          },
        ],
        { shouldDirty: !(isGroup && isBottomUp) }
      );
    }
  };

  const handleDelete = () => {
    const milestoneId = targetData?.milestones.find((m) => m.year === selectedMilestone?.year)?.id;

    if (!!milestoneId) {
      deleteMilestone({
        variables: {
          milestoneId,
        },
      });
      onClose();
    }
    setValue(TargetFieldsEnum.milestones, [...otherMilestones], { shouldDirty: true });
    setMilestoneYears(otherMilestones.map((m) => m.year));
    onClose();
  };

  const { totalBaselines, totalMilestones, totalGoal, missingBaselines, missingMilestones } =
    useMemo(
      () =>
        isGroup
          ? getSubMilestoneTotal(subsidiariesTargets, milestone?.year)
          : getBUMilestoneTotal(watch(), reportingUnits, milestone),
      [subsidiariesTargets, reportingUnits, milestone]
    );

  useEffect(() => {
    if (isBottomUp) {
      handleChangeValue({ value: totalMilestones });
    }
  }, [totalMilestones, isBottomUp]);

  const baseline = useMemo(
      () => targetData?.keyResults[0]?.baseline
  ,[targetData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${milestone?.year} milestone`}
      size="lg"
      confirmText="Done"
      onConfirm={onClose}
      topRightIcon={
        !hideDeleteButton ? (
          <IconButton
            aria-label="delete-baseline"
            variant="ghost"
            icon={<DeleteIcon />}
            onClick={handleDelete}
          />
        ) : undefined
      }
    >
      <VStack alignItems="flex-start" width="100%" spacing="40px">
        <Box width="100%">
          <Infobox
            status="neutral"
            description={getMilestoneInfoBoxText({ isGroup, isBottomUp, companyName })}
            closable={false}
            withIcon={false}
          />
        </Box>
        {!isBottomUp && (
          <TopDownInputTable
            milestone={milestone}
            baseline={watch('baseline')}
            isGroup={isGroup}
            isBottomUp={isBottomUp}
            handleChangeValue={handleChangeValue}
            companyName={companyName}
            unit={unit}
          />
        )}
        {(isBottomUp || isGroup) && (
          <VStack width="100%" alignItems="start">
            <TableTitleSection
              companyName={companyName}
              handleChangeValue={handleChangeValue}
              milestone={milestone}
              isBottomUp={isBottomUp}
              isGroup={isGroup}
              isInputDisabled={true}
            />
            <Box border="1px solid" borderColor="border.decorative" borderRadius="8px">
              <ChakraTable sx={{ tableLayout: 'fixed' }}>
                <TableHeader isGroup={isGroup} unit={unit} baseline={baseline} />
                {isGroup
                  ? subsidiariesTargets?.map((s, index) => {
                      const subMilestone = s.target?.milestones
                        .find((m) => m.year === milestone?.year)
                        ?.milestoneResults.find((m) => m.reportingUnitId === null);
                      const subBaseline = s?.target?.keyResults.find(
                        (k) => k.reportingUnitId === null
                      );
                      return (
                        <Tr key={index} height="48px">
                          <TableTD>
                            <TruncatableText text={s.company.name} variant="body" />
                          </TableTD>
                          <TableTD>
                            <BaselineCell
                              baselineValue={subBaseline?.baseline}
                              baselineYear={new Date(subBaseline?.baseYear)?.getFullYear()}
                              year={new Date(watch('baseYear') ?? '')?.getFullYear()}
                            />
                          </TableTD>
                          {Number(baseline) !== 0 && (
                              <TableTD>
                                {!isNullish(subMilestone?.value) ? (
                                  <HStack>
                                    <MilestoneDirectionIcon value={subMilestone?.value} />
                                    <Typography variant="body" as="span">
                                      {subMilestone?.value}
                                    </Typography>
                                  </HStack>
                                ) : (
                                  <NotAdded />
                                )}
                              </TableTD>
                          )}
                          <TableTD>
                            {subMilestone ? (
                              <Typography variant="body" as="span">
                                {Number(subBaseline?.baseline) !== 0 ?
                                    percentageToNumber(subMilestone?.value ?? 0, Number(subBaseline?.baseline) ?? 0) :
                                    subMilestone?.value
                                }
                              </Typography>
                            ) : (
                              <NotAdded />
                            )}
                          </TableTD>
                          <TableTD>
                            {s.target && (
                              <IconButton
                                aria-label="open sub"
                                variant="ghost"
                                size="sm"
                                icon={<ArrowUpRightIcon />}
                                onClick={() => openSubsidiary(s)}
                              />
                            )}
                          </TableTD>
                        </Tr>
                      );
                    })
                  : reportingUnits?.map((ru, index) => {
                      return (
                        <Tr key={index} height="48px">
                          <TableTD>
                            <TruncatableText text={ru.name ?? ''} variant="body" />
                          </TableTD>
                          <TableTD key={`milestone_${ru?.id}`}>
                            {watch(`${KeyResultEnums.baseline}_${ru?.id}`) ? (
                              <Typography variant="body" as="span">
                                {watch(`${KeyResultEnums.baseline}_${ru?.id}`)}
                              </Typography>
                            ) : (
                              <NotAdded />
                            )}
                          </TableTD>
                          {milestone?.isRelative ? (
                            <RelativeValueCells
                              milestoneValue={Number(milestone?.reportingUnits?.[`${ru?.id}`] ?? 0)}
                              baseline={watch(`${KeyResultEnums.baseline}_${ru?.id}`)}
                              handleChangeValue={handleChangeValue}
                              reportingUnitId={ru?.id}
                              reportingUnits={milestone?.reportingUnits}
                              hasBorder={true}
                            />
                          ) : (
                            <AbsoluteValueCells
                              milestoneValue={Number(milestone?.reportingUnits?.[`${ru?.id}`] ?? 0)}
                              baseline={watch(`${KeyResultEnums.baseline}_${ru?.id}`)}
                              handleChangeValue={handleChangeValue}
                              reportingUnitId={ru?.id}
                              reportingUnits={milestone?.reportingUnits}
                              hasBorder={true}
                            />
                          )}
                        </Tr>
                      );
                    })}

                <Tr bg="bg.muted" height="48px">
                  <TableTD hasBorder={false} borderBottomLeftRadius="8px">
                    <TruncatableText
                      text={isBottomUp ? 'Total (your milestone)' : 'Total'}
                      variant="bodyStrong"
                    />
                  </TableTD>
                  <TableTD hasBorder={false}>
                    {!isNullish(totalBaselines) ? (
                      <TotalWithWarning
                        value={totalBaselines}
                        isGroup={isGroup}
                        missingValues={missingBaselines}
                      />
                    ) : (
                      <NA />
                    )}
                  </TableTD>
                  {(Number(baseline) !== 0) &&
                      <TableTD hasBorder={false}>
                        {!isNullish(totalMilestones) ? (
                          <HStack>
                            <MilestoneDirectionIcon value={totalMilestones} />
                            <TotalWithWarning
                              value={totalMilestones}
                              isGroup={isGroup}
                              missingValues={missingMilestones}
                            />
                          </HStack>
                        ) : (
                          <NA />
                        )}
                      </TableTD>
                  }
                  <TableTD hasBorder={false} borderBottomRightRadius={isGroup ? '' : '8px'}>
                    {totalGoal !== undefined ? (
                      <TotalWithWarning
                        value={totalGoal}
                        isGroup={isGroup}
                        missingValues={uniq([...missingMilestones, ...missingBaselines])}
                      />
                    ) : (
                      <NA />
                    )}
                  </TableTD>
                  {isGroup && <TableTD hasBorder={false} borderBottomRightRadius="8px"></TableTD>}
                </Tr>
              </ChakraTable>
            </Box>
          </VStack>
        )}
      </VStack>
    </Modal>
  );
};
