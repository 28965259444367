import { Box, Center, HStack, VStack, useColorMode } from '@chakra-ui/react';
import { Checkbox } from 'Atoms';
import _ from 'lodash';
import { GetActionsQuery_ } from 'models';
import { useMemo, useState } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  Tooltip,
  Legend,
  Line,
  ReferenceLine,
} from 'recharts';
import { ContentType } from 'recharts/types/component/Tooltip';
import { colors, Typography } from 'Tokens';
import { MilestoneFields } from '../../Requirement';
import { percentageToNumber } from 'containers/Esrs/utils';

type TooltipType = ContentType<string | number | (string | number)[], string | number> | undefined;

const CheckboxCard = ({
  label,
  color,
  isChecked,
  setIsChecked,
}: {
  label: string;
  color: string;
  isChecked: boolean;
  setIsChecked: (param: boolean) => void;
}) => (
  <HStack w="100%">
    <Checkbox
      isChecked={isChecked}
      onChange={(e) => setIsChecked(e.target.checked)}
      variant={color}
    />
    <Typography variant="body">{label}</Typography>
  </HStack>
);

const customTooltip = ({
  active,
  payload,
  label,
}: {
  active: boolean;
  payload: { [key: string]: any }[];
  label: string;
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <Box
        bg="bg.default"
        border="1px solid #ccc"
        borderColor="bg.muted"
        p="10px"
        boxShadow="2px 2px 5px rgba(0, 0, 0, 0.1)"
        fontSize="14px"
      >
        {data?.target && (
          <Typography variant="body" color="text.muted">
            target : {data.target}
          </Typography>
        )}
        {!data?.target && !!data?.interpolated && (
          <Typography variant="body" color="text.muted">
            interpolation : {data.interpolated}
          </Typography>
        )}
        {data?.action && (
          <Typography variant="body" color="text.muted">
            action : {data.action}
          </Typography>
        )}
        <Typography variant="body" color="text.muted">
          year : {label}
        </Typography>
      </Box>
    );
  }
  return null;
};

const getAggregatedEstimatedActions = (
  targetId: string,
  actions?: GetActionsQuery_['actions'],
  selectedActions?: { actionId: string; estimate: number | null }[],
  parentActions?: GetActionsQuery_['actions'],
  parentTargetId?: string,
  baseline?: number,
  baselineYear?: number
) => {
  const selectedActionsData = selectedActions?.map((a) =>
    actions?.find((action) => action.id === a.actionId)
  );

  const actionEstimateWithYears =
    selectedActionsData?.map((action) => ({
      start: new Date(action?.startDate).getFullYear(),
      end: new Date(action?.deadline).getFullYear(),
      estimate:
        selectedActions?.find((a) => a.actionId === action?.id)?.estimate ??
        action?.actionTargets.find((at) => at.target.id === targetId)?.estimate,
    })) ?? [];

  const parentActionEstimateWithYears =
    parentActions?.map((action) => ({
      start: new Date(action?.startDate).getFullYear(),
      end: new Date(action?.deadline).getFullYear(),
      estimate: action?.actionTargets.find((at) => at.target.id === parentTargetId)?.estimate,
    })) ?? [];

  const estimateWithYears = [...actionEstimateWithYears, ...parentActionEstimateWithYears];

  const uniqueYears = [
    ...new Set(estimateWithYears?.flatMap((action) => [action.start, action.end])),
  ]
    ?.filter((year) => year !== undefined && year !== null)
    .sort((a, b) => a - b);

  const allYears: number[] = [];
  for (let y = uniqueYears[0]; y <= uniqueYears[uniqueYears.length - 1]; y++) {
    allYears.push(y);
  }

  const aggregatedActions = allYears.map((year) => {
    const concernedActions = estimateWithYears.filter((action) => action.start <= year);
    const yearTotal = concernedActions.reduce((acc, action) => {
      const actionYears = allYears.slice(
        allYears.indexOf(action.start),
        allYears.indexOf(action.end) + 1
      );
      const yearIndex = action.end < year ? actionYears.length : actionYears.indexOf(year) + 1;

      const currEstimate = (yearIndex / (action.end - action.start + 1)) * action.estimate;
      return acc + currEstimate;
    }, baseline ?? 0);
    return {
      year,
      action: yearTotal,
    };
  });

  const aggregatedActionsWithBaselineYear =
    aggregatedActions.some((data) => data.year === baselineYear) || !baselineYear
      ? aggregatedActions
      : [
          {
            year: baselineYear,
            action: baseline,
          },
          ...aggregatedActions,
        ];

  return aggregatedActionsWithBaselineYear;
};

export const TargetGraph = ({
  localAnswers,
  baseline,
  showControls = true,
  chartHeight,
  marginBottom,
  actions,
  parentActions,
  targetId,
  parentTargetId,
}: {
  localAnswers: any;
  baseline: number;
  showControls?: boolean;
  chartHeight?: number;
  marginBottom?: number;
  actions?: GetActionsQuery_['actions'];
  parentActions?: GetActionsQuery_['actions'];
  targetId: string;
  parentTargetId?: string;
}) => {
  const [showMilestones, setShowMilestones] = useState(true);
  const [showBaseline, setShowBaseline] = useState(true);
  const [showInterpolated, setShowInterpolated] = useState(true);
  const [showActions, setShowActions] = useState(true);
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);
  const sortedMilestones = useMemo(
    () =>
      _.sortBy(
        localAnswers.milestones?.map((m: MilestoneFields) => ({
          year: m.year,
          value: m.value ?? 0,
          subsidiaries: m.subsidiaries,
        })),
        'year'
      ) ?? [],
    [baseline, localAnswers]
  );

  const initialGraphArray: { year?: number; target?: number }[] = useMemo(
    () => [
      baseline
        ? {
            year: new Date(localAnswers?.baseYear ?? '').getFullYear(),
            target: baseline,
            action: baseline,
          }
        : {},
      ...sortedMilestones?.map((m) => {
        return {
          year: m.year,
          target: percentageToNumber(m.value, baseline),
        };
      }),
    ],
    [baseline, localAnswers, sortedMilestones]
  );

  function interpolateMissingYears(data: { year?: number; target?: number; action?: number }[]) {
    const filteredData = data?.filter((d) => Object.keys(d).length !== 0);
    const interpolatedData: {
      year?: number;
      target?: number;
      interpolated?: number;
      action?: number;
    }[] = [];

    filteredData.forEach((currentData, index, arr) => {
      const currentYear = currentData?.year ?? 0;
      const currentValue = currentData?.target ?? 0;

      interpolatedData.push({
        year: currentYear,
        target: currentValue,
        interpolated: currentValue,
        ...(currentData?.action && { action: currentData.action }),
      });

      if (index < arr.length - 1) {
        const nextData = arr[index + 1];
        const nextYear = nextData?.year ?? 0;
        const nextValue = nextData?.target ?? 0;

        const yearDifference = nextYear - currentYear;
        const valueDifference = nextValue - currentValue;

        for (let year = currentYear + 1; year < nextYear; year++) {
          const yearFraction = (year - currentYear) / yearDifference;
          const interpolatedValue = currentValue + yearFraction * valueDifference;

          interpolatedData.push({
            year: year,
            interpolated: interpolatedValue,
          });
        }
      }
    });
    return interpolatedData;
  }

  const estimatedActions = useMemo(
    () =>
      actions?.length
        ? getAggregatedEstimatedActions(
            targetId,
            actions,
            localAnswers.actions,
            parentActions,
            parentTargetId,
            baseline,
            new Date(localAnswers?.baseYear).getFullYear()
          )
        : [],
    [actions, localAnswers, targetId, parentActions, parentTargetId, baseline]
  );

  const targetGraphData = useMemo(
    () => interpolateMissingYears(initialGraphArray),
    [initialGraphArray]
  );

  const graphData = useMemo(() => {
    const targetAndActionData = targetGraphData.map((data) => {
      const action = estimatedActions.find((a) => a.year === data.year);
      if (action)
        return { ...data, action: action.action, year: `\`${String(data.year)?.slice(-2)}` };
      return { ...data, year: `\`${String(data.year)?.slice(-2)}` };
    });

    const actionOnlyData = estimatedActions
      ?.filter((a) => !targetGraphData.some((t) => t.year === a.year))
      .map((action) => ({
        action: action.action,
        year: action.year ? `\`${String(action.year)?.slice(-2)}` : '',
      }));

    return _.sortBy([...targetAndActionData, ...actionOnlyData], 'year');
  }, [estimatedActions, targetGraphData]);

  if (!graphData.length) {
    return (
      <Center w="100%" h="100%">
        <Typography variant="body" color="text.muted">
          Add a baseline and milestones to visualize the progress trend here
        </Typography>
      </Center>
    );
  }

  return (
    <HStack alignItems="start" w="100%" h="100%" pt="8px">
      <ResponsiveContainer width="100%" height={chartHeight ?? 180}>
        <LineChart
          width={400}
          height={300}
          data={graphData ?? []}
          margin={{
            top: 8,
            right: 10,
            left: 15,
            bottom: marginBottom ?? 8,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="year"
            style={{
              fontSize: '14px',
              color: colors['border.decorative'][color],
            }}
          />
          <Tooltip content={customTooltip as TooltipType} />
          <Legend />
          <Line
            type="linear"
            dataKey="interpolated"
            stroke={colors['text.info'][color]}
            fill={colors['text.info'][color]}
            dot={{ r: 3 }}
            legendType="none"
            isAnimationActive={false}
            connectNulls={true}
            hide={!showInterpolated}
          />
          <Line
            type="linear"
            dataKey="target"
            stroke={colors['text.info'][color]}
            fill={colors['text.info'][color]}
            dot={{
              r: 4,
              stroke: colors['text.info'][color],
            }}
            activeDot={{ r: 7 }}
            legendType="none"
            isAnimationActive={false}
            connectNulls={true}
            hide={!showMilestones}
          />
          <Line
            type="linear"
            dataKey="action"
            stroke={colors['bg.compliant.accent'][color]}
            fill={colors['bg.compliant.accent'][color]}
            activeDot={{ r: 4 }}
            legendType="none"
            isAnimationActive={false}
            connectNulls={true}
            hide={!showActions}
          />
          {showBaseline && (
            <ReferenceLine
              y={baseline}
              label="Baseline"
              stroke={colors['bg.progress'][color]}
              strokeDasharray="4 4"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
      {showControls && (
        <VStack spacing="18px" minWidth="270px" maxWidth="270px" alignItems="start">
          <Typography variant="body" color="text.muted">
            Select what you want to see in this chart:
          </Typography>
          <VStack width="100%" alignItems="start">
            <CheckboxCard
              label="Milestones"
              color="text.info"
              isChecked={showMilestones}
              setIsChecked={setShowMilestones}
            />
            <CheckboxCard
              label="Interpolated values"
              color="text.info"
              isChecked={showInterpolated}
              setIsChecked={setShowInterpolated}
            />
            <CheckboxCard
              label="Estimated impact from actions"
              color="bg.compliant.accent"
              isChecked={showActions}
              setIsChecked={setShowActions}
            />
            <CheckboxCard
              label="Baseline"
              color="bg.progress"
              isChecked={showBaseline}
              setIsChecked={setShowBaseline}
            />
          </VStack>
        </VStack>
      )}
    </HStack>
  );
};
