import {
  Box,
  Table as ChakraTable,
  Tbody,
  Th,
  Thead,
  Tr,
  VStack,
  Td,
  HStack,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import { AutoResizeTextarea, ESRSNumberInput, Input } from 'Atoms';
import { BooleanSelector, LearnMoreDrawer, Select } from 'Molecules';
import { Typography } from 'Tokens';
import { ActionFields, TargetFields } from '../Requirement';
import React, { useEffect, useRef, useState } from 'react';
import { DescriptionIcon, HashIcon } from 'Tokens/Icons/Data';
import { Control, Controller } from 'react-hook-form';
import { actionsFinancialMDR, actionsScopeMDR, targetsMDR } from './MDRData';
import { HelpIcon } from 'Tokens/Icons/Status';

type QuestionType = 'LongText' | 'ShortText' | 'Enum' | 'Boolean' | 'Number';
type LearnMoreData = { title: string; description?: string } | undefined;

export type MDRControllerType = Control<Partial<TargetFields> & Partial<ActionFields>, any>;

const theadStyle = {
  letterSpacing: 'normal',
  borderColor: 'border.decorative',
  px: '8px',
  height: '48px',
};

const ShortText = ({ control, field }: { control: MDRControllerType; field: string }) => {
  return (
    <Controller
      name={field}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Input
          width="100%"
          value={value ?? undefined}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Name"
          borderRadius="6px"
        />
      )}
    />
  );
};

const LongText = ({
  control,
  field,
  placeholder,
}: {
  control: MDRControllerType;
  field: string;
  placeholder?: string;
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.minHeight = 'auto';
      const tdHeight = textareaRef.current.parentElement?.parentElement?.clientHeight;
      textareaRef.current.style.height = tdHeight ? `${tdHeight - 12}px` : 'unset';
      textareaRef.current.style.minHeight = tdHeight ? `${tdHeight - 12}px` : 'unset';
    }
  }, [textareaRef.current]);

  return (
    <Controller
      name={field}
      control={control}
      render={({ field: { onChange, value } }) => (
        <AutoResizeTextarea
          ref={textareaRef}
          value={value ?? undefined}
          variant="ghost"
          onChange={(e) => onChange(e.target.value)}
          borderRadius="6px"
          lineHeight="20px"
          size="md"
          placeholder={placeholder ?? 'Write your answer'}
          minH="80px"
        />
      )}
    />
  );
};

const BooleanInput = ({ control, field }: { control: MDRControllerType; field: string }) => {
  return (
    <Controller
      name={field}
      control={control}
      render={({ field: { onChange, value } }) => (
        <BooleanSelector answer={value} handleChangeAnswer={onChange} />
      )}
    />
  );
};

const Selector = ({
  options,
  control,
  field,
}: {
  options: { value: string; label: string }[];
  control: MDRControllerType;
  field: string;
}) => {
  return (
    <Controller
      name={field}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Box width="100%">
          <Select<{ value: string; label: string }>
            value={options.find((val) => value === val.value)}
            options={options}
            onChange={(val) => onChange(val?.value ?? null)}
            ghostVariant={true}
          />
        </Box>
      )}
    />
  );
};

const QuestionNumberInput = ({
  control,
  field,
  unit,
}: {
  control: MDRControllerType;
  field: string;
  unit?: string;
}) => {
  return (
    <Controller
      name={field}
      control={control}
      render={({ field: { onChange, value } }) => (
        <ESRSNumberInput value={value} onChange={onChange} unit={unit} variant="ghost" />
      )}
    />
  );
};

const QuestionBlockInput = ({
  type,
  control,
  field,
  secondField,
  options,
  unit,
}: {
  type: QuestionType;
  control: MDRControllerType;
  field: string;
  secondField?: string;
  options?: { value: string; label: string }[];
  unit?: string;
}) => {
  if (type === 'LongText') return <LongText control={control} field={field} />;
  if (type === 'ShortText') return <ShortText control={control} field={field} />;
  if (type === 'Boolean')
    return (
      <VStack alignItems="start" w="100%">
        <BooleanInput control={control} field={field} />
        {secondField && <LongText control={control} field={secondField} />}
      </VStack>
    );
  if (type === 'Enum')
    return (
      <VStack alignItems="start" w="100%">
        <Selector control={control} field={field} options={options ?? []} />
        {secondField && (
          <LongText control={control} field={secondField} placeholder="Explain your answer" />
        )}
      </VStack>
    );
  if (type === 'Number') return <QuestionNumberInput control={control} field={field} unit={unit} />;

  return <></>;
};

const QuestionBlock = ({
  title,
  type,
  control,
  field,
  secondField,
  options,
  paddingLeft,
  children,
  unit,
  learnMore,
  onOpen,
  setRow,
}: {
  title: string;
  type: QuestionType;
  control: MDRControllerType;
  field: string;
  secondField?: string;
  options?: { value: string; label: string }[];
  paddingLeft?: string;
  children?: React.ReactElement;
  unit?: string;
  learnMore?: string;
  onOpen: () => void;
  setRow: (param: LearnMoreData) => void;
}) => {
  return (
    <>
      <Tr borderTop="1px solid" borderColor="border.decorative">
        <Td
          p="16px 8px"
          border="none"
          pl="0px"
          verticalAlign="top"
          paddingLeft={paddingLeft}
          colSpan={field == 'none' ? 2 : 1}
        >
          <HStack spacing="8px" alignItems="start" height="100%">
            <VStack alignItems="center" justifyContent="center" h="20px">
              {type === 'Number' ? <HashIcon /> : <DescriptionIcon />}
            </VStack>
            <Typography variant="body">{title}</Typography>
          </HStack>
        </Td>
        {field !== 'none' && (
          <Td p="8px" border="none" verticalAlign="top">
            <VStack alignItems="flex-start" width="100%">
              <QuestionBlockInput
                type={type}
                control={control}
                field={field}
                secondField={secondField}
                options={options}
                unit={unit}
              />
            </VStack>
          </Td>
        )}
        <Td p="16px 8px !important" border="none" verticalAlign="top" paddingLeft={paddingLeft}>
          <IconButton
            aria-label="learn more"
            variant="ghost"
            icon={<HelpIcon />}
            size="md"
            onClick={() => {
              setRow({
                title: title,
                description: learnMore,
              });
              onOpen();
            }}
          />
        </Td>
      </Tr>
      {children}
    </>
  );
};

export const MDR = ({
  control,
  type,
  unit,
}: {
  control: MDRControllerType;
  type: 'target' | 'actionScope' | 'actionFinancial';
  unit?: string;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [row, setRow] = useState<LearnMoreData>();

  return (
    <>
      <ChakraTable w="100%">
        <Thead w="100%" borderBottom="1px solid" borderColor="border.decorative">
          <Tr w="100%">
            <Th {...theadStyle} textTransform="none" w="40%">
              <Typography variant="bodyStrong">Data point</Typography>
            </Th>
            <Th {...theadStyle} textTransform="none" w="fit-content">
              <Typography variant="bodyStrong">Answer</Typography>
            </Th>
            <Th {...theadStyle} w="5%"></Th>
          </Tr>
        </Thead>
        <Tbody>
          {type === 'target' &&
            targetsMDR.map((target) => (
              <QuestionBlock
                title={target.title}
                type={target.type as QuestionType}
                field={target.field}
                control={control}
                secondField={target?.secondField}
                options={target?.options}
                unit={unit}
                onOpen={onOpen}
                setRow={setRow}
                learnMore={target.learnMore}
                children={
                  target.children && (
                    <>
                      {target.children.map((child) => (
                        <QuestionBlock
                          title={child.title}
                          type={child?.type as QuestionType}
                          field={child?.field}
                          paddingLeft="32px"
                          control={control}
                          onOpen={onOpen}
                          setRow={setRow}
                          learnMore={child.learnMore}
                        />
                      ))}
                    </>
                  )
                }
              />
            ))}
          {type === 'actionScope' &&
            actionsScopeMDR.map((action) => (
              <QuestionBlock
                title={action.title}
                type={action.type as QuestionType}
                field={action.field}
                control={control}
                secondField={action?.secondField}
                onOpen={onOpen}
                setRow={setRow}
                learnMore={action.learnMore}
                options={action.options}
              />
            ))}
          {type === 'actionFinancial' &&
            actionsFinancialMDR.map((action) => (
              <QuestionBlock
                title={action.title}
                type={action.type as QuestionType}
                field={action.field}
                control={control}
                secondField={action?.secondField}
                onOpen={onOpen}
                setRow={setRow}
                unit={unit}
                learnMore={action.learnMore}
                children={
                  action.children && (
                    <>
                      {action.children.map((child) => (
                        <QuestionBlock
                          title={child.title}
                          type={child.type as QuestionType}
                          field={child.field}
                          paddingLeft="32px"
                          control={control}
                          onOpen={onOpen}
                          setRow={setRow}
                          unit={unit}
                          learnMore={action.learnMore}
                        />
                      ))}
                    </>
                  )
                }
              />
            ))}
        </Tbody>
      </ChakraTable>
      <LearnMoreDrawer
        isOpen={isOpen}
        onClose={onClose}
        header={row?.title}
        description={row?.description}
      />
    </>
  );
};
