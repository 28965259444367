import {
  AttachmentBox_Constraint_,
  AttachmentBox_Update_Column_,
  Esrs_MaterialMetricTag_Constraint_,
  Esrs_MaterialMetricTag_Update_Column_,
  Esrs_MaterialMetric_Constraint_,
  Esrs_MaterialMetric_Update_Column_,
  Esrs_MaterialTagValue_Constraint_,
  Esrs_MaterialTagValue_Update_Column_,
  GetMaterialStandardDocument_,
  GetMaterialStandardQuery_,
  GetParentMaterialityAssessmentQuery_,
  GetRequiredTagsMaterialMetricsDocument_,
  GetStandardMandatoryMetricsQuery_,
  GetSubsidiaryRequiredTagsMaterialMetricsDocument_,
  NoteHistory_Constraint_,
  NoteHistory_Update_Column_,
  useGetEsrsStandardQuery,
  useGetMaterialStandardQuery,
  useGetParentMaterialityAssessmentQuery,
  useUpsertMaterialStandardMutation,
  useUpsertPolicyMutation,
} from 'models';
import {
  MaterialityState,
  ParentMaterialMetricsType,
  ParentMaterialityState,
  StandardMaterialityState,
} from './DoubleMaterialityAssessment.d';
import { useParams } from 'react-router-dom';
import { useCompanyType, useToast } from 'utils/hooks';
import { useCallback, useMemo } from 'react';
import { useGetSubsidiaryType } from 'containers/Esrs/EsrsAssessment.hooks';
import { DataCollectionLevel } from '../../DataCollection';
import {
  DisclosureRequirementGroups,
  MaterialMetric,
  Requirement,
} from '../MaterialityAssessment.d';
import { isParentMaterial } from '../MaterialityAssessment.hooks';

export const getStandardMateriality = (
  isCollectOnlyMandatory: boolean,
  isCollectData: boolean,
  isCollectOnly: boolean,
  isStandardMandatory: boolean,
  materialStandard?: GetMaterialStandardQuery_['materialityAssessment'][number]
) => {
  if (!materialStandard && !isStandardMandatory && !isCollectOnlyMandatory)
    return StandardMaterialityState.toAssess;
  if (isStandardMandatory) {
    return StandardMaterialityState.mandatory;
  } else if (isCollectOnlyMandatory) {
    return StandardMaterialityState.collectDataMandatory;
  } else if (isCollectData) {
    return StandardMaterialityState.collectData;
  } else if (!materialStandard?.isMaterial && isCollectOnly) {
    return StandardMaterialityState.doNotCollect;
  } else if (materialStandard?.isDataGatheringOnly) {
    return StandardMaterialityState.gatherData;
  } else if (materialStandard?.isMaterial) {
    return StandardMaterialityState.material;
  } else if (materialStandard?.isMaterial === null) {
    return StandardMaterialityState.toAssess;
  } else if (!materialStandard?.isMaterial) {
    return StandardMaterialityState.notMaterial;
  }
  return StandardMaterialityState.toAssess;
};

export const getParentStandardMateriality = (isAssessed: boolean, isMaterial?: boolean | null) => {
  if (isMaterial) return ParentMaterialityState.material;
  else if (isAssessed) return ParentMaterialityState.notMaterial;
  return ParentMaterialityState.toAssess;
};

export const useGetStandardMaterialityData = () => {
  const { standardRef = '', esrsAssessmentId = '' } = useParams();
  const { companyType } = useCompanyType();

  const { data: standardData, loading: standardDataLoading } = useGetEsrsStandardQuery({
    variables: { reference: standardRef },
    skip: !standardRef,
  });

  const { data: materialStandardData, loading: materialStandardLoading } =
    useGetMaterialStandardQuery({
      variables: { standardRef: standardRef, assessmentId: esrsAssessmentId },
    });

  const { data: parentData, loading: parentDataLoading } = useGetParentMaterialityAssessmentQuery({
    variables: {
      childAssessmentId: esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);
  const materialStandard = useMemo(
    () => materialStandardData?.materialityAssessment[0],
    [materialStandardData]
  );
  const parentStandardMaterialityData = useMemo(
    () =>
      parentData?.EsrsAssessment_by_pk?.parentAssessment?.materialStandards.find(
        (mA) => mA.standardRef === standardRef
      ),
    [parentData, standardRef]
  );

  const { isCollectOnly, subsidiaryTypeLoading } = useGetSubsidiaryType(esrsAssessmentId);

  const hasParent = useMemo(
    () => parentData?.EsrsAssessment_by_pk?.parentAssessment !== null,
    [parentData]
  );
  const isGroupOwner = useMemo(() => companyType === 'group-company', [companyType]);

  return {
    standard,
    materialStandard,
    parentStandardMaterialityData,
    hasParent,
    isGroupOwner,
    parentData,
    isCollectOnly,
    loading:
      standardDataLoading || materialStandardLoading || parentDataLoading || subsidiaryTypeLoading,
  };
};

export const mapMaterialTags = (
  metric: MaterialMetric['metric'] | GetStandardMandatoryMetricsQuery_['EsrsMetric'][number]
) => {
  const hasTags = metric?.tags?.length;
  const parentHasTags = metric?.parentMetrics.some((met) =>
    met.parentMetric.tags.some((tag) => tag.tagType.alwaysAll && !tag.isOptional)
  );
  const metricTags = metric?.tags.filter((tag) => tag.tagType.alwaysAll && !tag.isOptional) ?? [];

  return hasTags
    ? metricTags.map((tag) => ({
        tagType: tag.tagType.type,
        materialTagValues: {
          data: tag.tagType.tagValues.map((val) => ({
            tagType: tag.tagType.type,
            tagValue: val.value,
          })),
          on_conflict: {
            constraint:
              Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
            update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
          },
        },
      }))
    : parentHasTags
      ? (metric?.parentMetrics.flatMap((met) =>
          met.parentMetric.tags
            .filter((tag) => tag.tagType.alwaysAll)
            .map((tag) => ({
              tagType: tag.tagType.type,
              materialTagValues: {
                data: tag.tagType.tagValues.map((val) => ({
                  tagType: tag.tagType.type,
                  tagValue: val.value,
                })),
                on_conflict: {
                  constraint:
                    Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
                  update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
                },
              },
            }))
        ) ?? [])
      : [];
};

const mapMandatoryMetrics = (
  mandatoryMetrics: GetStandardMandatoryMetricsQuery_['EsrsMetric'],
  isMaterial: boolean | null,
  isDataGatheringOnly: boolean,
  isGroup: boolean
) => {
  const metricMap = new Map();
  const defaultDataCollection = isGroup
    ? DataCollectionLevel.subsidiaries
    : DataCollectionLevel.company;

  mandatoryMetrics.forEach((m) => {
    metricMap.set(m.reference, {
      isMaterial,
      isDataGatheringOnly,
      metricRef: m.reference,
      dataCollection: defaultDataCollection,
      materialMetricTags: {
        data: mapMaterialTags(m),
        on_conflict: {
          constraint:
            Esrs_MaterialMetricTag_Constraint_.MaterialMetricTagMaterialMetricIdTagTypeKey_,
          update_columns: [Esrs_MaterialMetricTag_Update_Column_.TagType_],
        },
      },
    });
  });

  return Array.from(metricMap.values());
};

export const useAddMateriality = () => {
  const { standardRef, esrsAssessmentId } = useParams();
  const [upsertMaterialStandard] = useUpsertMaterialStandardMutation();
  const [upsertPolicy] = useUpsertPolicyMutation();
  const toast = useToast();
  return useCallback(
    (
      material: StandardMaterialityState,
      mandatoryMetrics: GetStandardMandatoryMetricsQuery_['EsrsMetric'],
      isGroup: boolean
    ) => {
      const isMaterial =
        material === StandardMaterialityState.toAssess
          ? null
          : material === StandardMaterialityState.material ||
            material === StandardMaterialityState.gatherData ||
            material === StandardMaterialityState.collectData ||
            material === StandardMaterialityState.collectDataMandatory;
      const isDataGatheringOnly = material === StandardMaterialityState.gatherData;

      upsertMaterialStandard({
        variables: {
          materialStandard: {
            assessmentId: esrsAssessmentId,
            standardRef,
            isDataGatheringOnly,
            isMaterial,
            materialMetrics: {
              data: mapMandatoryMetrics(mandatoryMetrics, isMaterial, isDataGatheringOnly, isGroup),
              on_conflict: {
                constraint:
                  Esrs_MaterialMetric_Constraint_.MaterialMetricMaterialStandardIdMetricRefKey_,
                update_columns: [
                  Esrs_MaterialMetric_Update_Column_.IsMaterial_,
                  Esrs_MaterialMetric_Update_Column_.IsDataGatheringOnly_,
                ],
              },
            },
            attachmentBox: {
              data: {},
              on_conflict: {
                constraint: AttachmentBox_Constraint_.AttachmentBoxMaterialityAssessmentIdKey_,
                update_columns: [AttachmentBox_Update_Column_.MaterialStandardId_],
              },
            },
            noteHistory: {
              data: {},
              on_conflict: {
                constraint: NoteHistory_Constraint_.NoteHistoryMaterialityAssessmentIdKey_,
                update_columns: [NoteHistory_Update_Column_.MaterialStandardId_],
              },
            },
          },
        },
        refetchQueries: [
          GetMaterialStandardDocument_,
          GetRequiredTagsMaterialMetricsDocument_,
          GetSubsidiaryRequiredTagsMaterialMetricsDocument_,
        ],
        awaitRefetchQueries: true,
      })
        .then((res) => {
          toast({
            text: material ? 'Materiality assessment updated!' : 'Materiality assessment added!',
          });
          const result = res.data?.insert_esrs_MaterialStandard_one;

          if (isMaterial !== false) {
            const requirementsWithPolicies = result?.standard.disclosureRequirementGroups.filter(
              (g) => g.requirements.filter((r) => r.type === 'policy').length
            );
            upsertPolicy({
              variables: {
                input:
                  requirementsWithPolicies?.map((group) => {
                    return {
                      assessmentId: result?.id,
                      disclosureRequirementRef: group.requirements[0].reference,
                      noteHistory: {
                        data: {},
                        on_conflict: {
                          constraint: NoteHistory_Constraint_.NoteHistoryPolicyIdKey_,
                          update_columns: [],
                        },
                      },
                      attachmentBox: {
                        data: {},
                        on_conflict: {
                          constraint: AttachmentBox_Constraint_.AttachmentBoxPoliciesIdKey_,
                          update_columns: [],
                        },
                      },
                    };
                  }) ?? [],
              },
            });
          }
        })
        .catch(() => {
          toast({ text: 'Failed to add materiality assessment', variant: 'danger' });
        });
    },
    [upsertMaterialStandard, upsertPolicy]
  );
};

const useGetDRState = () => {
  return useCallback(
    (
      requirement: Requirement,
      materialMetrics: MaterialMetric[],
      parentMaterialMetrics: ParentMaterialMetricsType,
      isStandardMaterial: boolean,
      isCollectOnly: boolean
    ): MaterialityState => {
      const metrics = materialMetrics.filter(
        (metric) => metric.metric?.disclosureRequirementRef === requirement.reference
      );
      const parentMetrics = parentMaterialMetrics.filter(
        (metric) => metric.metric?.disclosureRequirementRef === requirement.reference
      );

      if (isCollectOnly) {
        if (
          metrics?.some((metric) => metric.isMaterial && metric?.isCopiedFromParent) ||
          isParentMaterial(parentMetrics)
        ) {
          return MaterialityState.collectDataMandatory;
        }
        if (metrics?.some((metric) => metric.isMaterial)) {
          return MaterialityState.collectData;
        } else {
          return MaterialityState.doNotCollect;
        }
      }
      if (isStandardMaterial) {
        return MaterialityState.mandatory;
      }
      if (requirement.isMandatory) {
        return MaterialityState.materialMandatory;
      }

      const isDRAssessed = !!metrics.length && metrics.some((m) => m.isMaterial !== null);
      if (!isDRAssessed) return MaterialityState.toAssess;

      const isDataGathering = metrics.some((m) => m.isDataGatheringOnly && m.isMaterial);
      if (isDataGathering) {
        return MaterialityState.gatherData;
      }

      const isMaterial = metrics.some((m) => m.isMaterial);
      return isMaterial ? MaterialityState.material : MaterialityState.notMaterial;
    },
    []
  );
};

const useGetParentDRstate = () => {
  return useCallback(
    (
      requirement: Requirement,
      parentMaterialMetrics: NonNullable<
        NonNullable<
          GetParentMaterialityAssessmentQuery_['EsrsAssessment_by_pk']
        >['parentAssessment']
      >['materialStandards'][number]['materialMetrics'],
      isStandardMaterial: boolean
    ): MaterialityState => {
      const metrics = parentMaterialMetrics.filter(
        (m) => m.metric?.disclosureRequirementRef === requirement.reference
      );
      if (!isStandardMaterial) {
        return MaterialityState.notMaterial;
      }
      if (requirement.isMandatory) {
        return MaterialityState.mandatory;
      }
      const isDRAssessed = !!metrics.length && metrics.some((m) => m.isMaterial !== null);

      if (!isDRAssessed) return MaterialityState.toAssess;

      const isDataGathering = metrics.some((m) => m.isDataGatheringOnly && m.isMaterial);

      if (isDataGathering) return MaterialityState.gatherData;

      const isMaterial = metrics.some((m) => m.isMaterial);
      return isMaterial ? MaterialityState.material : MaterialityState.notMaterial;
    },
    []
  );
};

const getMetricTags = (metric?: MaterialMetric) => {
  const tags = !!metric?.materialMetricTags?.length
    ? metric?.materialMetricTags?.map((tag) => ({
        type: tag.tagType,
        isOptional: tag.isOptional,
        tagType: {
          type: tag.tagType,
          alwaysAll: tag.metricTagType.alwaysAll,
          tagValues: tag.materialTagValues.map((val) => ({
            value: val.tagValue,
          })),
        },
      }))
    : metric?.metric?.tags;
  return tags;
};

export const useMapDisclosureRequirements = () => {
  const getDrState = useGetDRState();
  const getParentDRState = useGetParentDRstate();
  return useCallback(
    ({
      materialMetrics,
      parentMaterialMetrics,
      isGroupOwner,
      isStandardMaterial,
      isCollectOnly,
      requirementGroups,
      isParentStandardMaterial,
    }: {
      materialMetrics: MaterialMetric[];
      parentMaterialMetrics: ParentMaterialMetricsType;
      isGroupOwner: boolean;
      isStandardMaterial: boolean;
      isCollectOnly: boolean;
      requirementGroups?: DisclosureRequirementGroups;
      isParentStandardMaterial?: boolean | null;
    }) => {
      const allRequirements = Object.values(requirementGroups ?? {}).flatMap(
        (group) => group.requirements
      );

      const requirements = allRequirements.slice().sort((a, b) => {
        return a.order - b.order || a.reference.localeCompare(b.reference);
      });

      const mappedRequirements = requirements.map((req) => ({
        title: req.title,
        drRef: req.reference,
        isMandatory: req.isMandatory,
        materialityStatus: getDrState(
          req,
          materialMetrics,
          parentMaterialMetrics,
          isStandardMaterial,
          isCollectOnly
        ),
        order: req.order,
        parentMateriality: !isGroupOwner
          ? getParentDRState(req, parentMaterialMetrics, isParentStandardMaterial ?? false)
          : MaterialityState.toAssess,
        metrics: req.metrics.map((metric) => ({
          ...metric,
          tags:
            getMetricTags((materialMetrics ?? []).find((m) => m.metricRef === metric.reference)) ??
            metric.tags,
        })),
        description: req.description,
        additionalTypes: req.additionalTypes.map((at) => at.additionalType),
      }));
      return mappedRequirements;
    },
    []
  );
};
