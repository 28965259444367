import { useEffect, useMemo, useState } from 'react';
import { Box, VStack } from '@chakra-ui/react';
import { ContentLayout } from 'Molecules';
import { MaterialityHeader } from './MaterialityUtils';
import { AssessStandardMateriality } from './DoubleMaterialitySteps/AssessStandardMateriality';
import { ProvideReasoning } from './DoubleMaterialitySteps/ProvideReasoning';
import { AssessDisclosureRequirements } from './DoubleMaterialitySteps/AssessDisclosureRequirements';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetStandardMandatoryMetricsQuery, useUpdateAssessmentOnboardingMutation } from 'models';
import {
  getParentStandardMateriality,
  getStandardMateriality,
  useAddMateriality,
  useGetStandardMaterialityData,
} from './DoubleMaterialityAssessment.hooks';
import { AdditionalType, StandardMaterialityState } from './DoubleMaterialityAssessment.d';
import { isParentMaterial } from '../MaterialityAssessment.hooks';

export const DoubleMaterialityAssessment = ({ onboarding = false }: { onboarding?: boolean }) => {
  const [step, setStep] = useState(0);

  const { standardRef = '' } = useParams();
  const addMateriality = useAddMateriality();
  const { data: mandatoryMetrics } = useGetStandardMandatoryMetricsQuery({
    variables: {
      standardRef,
    },
  });

  const {
    standard,
    materialStandard,
    parentStandardMaterialityData,
    hasParent,
    isGroupOwner,
    parentData,
    isCollectOnly,
    loading,
  } = useGetStandardMaterialityData();

  const { esrsAssessmentId = '' } = useParams();

  const navigate = useNavigate();

  const [updateOnboardingStep] = useUpdateAssessmentOnboardingMutation();

  const isStandardRequiredByParent = useMemo(
    () =>
      (materialStandard?.isMaterial && materialStandard.isDataGatheringOnly) ||
      (parentStandardMaterialityData?.isMaterial &&
        isParentMaterial(parentStandardMaterialityData.materialMetrics)),
    [materialStandard, parentStandardMaterialityData]
  );

  const [isCollectOnlyMandatory, isCollectData] = useMemo(
    () => [
      isCollectOnly && isStandardRequiredByParent,
      materialStandard?.isMaterial && isCollectOnly,
    ],
    [materialStandard, isCollectOnly, isStandardRequiredByParent]
  );

  const isStandardMandatory = useMemo(() => {
    const isTopicalStandardNotMandatory =
      isCollectOnly && !isStandardRequiredByParent && !standard?.isTopical;
    if (isTopicalStandardNotMandatory) return false;
    return !(standard?.isTopical ?? false);
  }, [, isCollectOnly, isStandardRequiredByParent, standard]);

  const standardMateriality = useMemo(
    () =>
      getStandardMateriality(
        isCollectOnlyMandatory ?? false,
        isCollectData ?? false,
        isCollectOnly,
        isStandardMandatory,
        materialStandard
      ),
    [materialStandard, isCollectOnly, isCollectOnlyMandatory, isCollectData, isStandardMandatory]
  );

  const parentMateriality = useMemo(
    () =>
      getParentStandardMateriality(
        !!parentStandardMaterialityData,
        parentStandardMaterialityData?.isMaterial
      ),
    [parentStandardMaterialityData]
  );

  const standardAdditionalTypes: AdditionalType[] = useMemo(() => {
    return (
      standard?.additionalTypes
        .map((aType) => aType.additionalType)
        .map((type) => ({
          reference: type.reference,
          title: type.title ?? '',
          description: type.description ?? '',
          learnMore: type.learnMore ?? '',
        })) ?? []
    );
  }, [standard]);

  const showStandardMateriality = useMemo(
    () => !isStandardMandatory && !isCollectOnlyMandatory,
    [isStandardMandatory, isCollectOnlyMandatory]
  );

  const showDrTable = useMemo(
    () => (isStandardMandatory || isCollectOnlyMandatory || materialStandard?.isMaterial) ?? false,
    [isStandardMandatory, materialStandard]
  );

  const onDoneClick = () => {
    navigate(-1);
    if (onboarding) {
      updateOnboardingStep({
        variables: {
          id: esrsAssessmentId,
          onboardingStep: 3,
        },
      });
    }
  };

  useEffect(() => {
    if (
      standardMateriality === StandardMaterialityState.collectDataMandatory &&
      !materialStandard
    ) {
      addMateriality(
        StandardMaterialityState.collectDataMandatory,
        mandatoryMetrics?.EsrsMetric ?? [],
        false
      );
    }
  }, [standardMateriality, materialStandard]);

  return (
    <VStack
      bg="bg.defaultToDecorative"
      h="fit-content"
      alignItems="center"
      justifyContent="start"
      flexGrow="1"
    >
      <VStack w="100%" alignItems="start" flexGrow="1">
        <ContentLayout isLoading={loading} backButton={true} header={false}>
          <Box
            width="88%"
            bg="bg.default"
            border="1px solid"
            borderColor="border.decorative"
            borderRadius="8px"
            p="24px"
            margin="0 auto"
          >
            <MaterialityHeader
              title={standard?.title ?? ''}
              reference={standard?.reference ?? ''}
              materialStandard={materialStandard}
              isCollectOnly={isCollectOnly}
              hasParent={hasParent}
              standardAdditionalTypes={standardAdditionalTypes}
              parentMateriality={parentMateriality}
              materiality={standardMateriality}
              isStandardMandatory={isStandardMandatory}
            />
            {showStandardMateriality && (
              <>
                <AssessStandardMateriality
                  step={step}
                  setStep={setStep}
                  isCollectOnly={isCollectOnly ?? false}
                  standardMateriality={standardMateriality}
                  isGroup={isGroupOwner}
                />
                <ProvideReasoning
                  step={step}
                  setStep={setStep}
                  onDoneClick={onDoneClick}
                  materialStandard={materialStandard}
                  showDrTable={showDrTable}
                />
              </>
            )}
            {showDrTable && (
              <AssessDisclosureRequirements
                step={step}
                hasParent={hasParent}
                materialStandard={materialStandard}
                isGroup={isGroupOwner}
                onDoneClick={onDoneClick}
                standardMateriality={standardMateriality}
                parentStandardMaterialityData={parentStandardMaterialityData}
                parentData={parentData}
                isCollectOnly={isCollectOnly}
                standard={standard}
              />
            )}
          </Box>
        </ContentLayout>
      </VStack>
    </VStack>
  );
};
