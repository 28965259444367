import { timeHorizonOptions } from '../DisclosureRequirements.hooks';
import { TargetFieldsEnum, ActionFieldsEnums } from '../Requirement';

export const targetsMDR = [
  {
    title: 'Name of related impacts, risks and opportunities',
    type: 'LongText',
    field: TargetFieldsEnum.relatedIRO,
    learnMore: 'Describe impacts, risks and opportunities the target is related to.',
  },
  {
    title: 'Name of related policy/policies',
    type: 'LongText',
    field: TargetFieldsEnum.relatedPolicy,
    learnMore: '80. (a) a description of the relationship of the target to the policy objectives.',
  },
  {
    title: 'Period to which the target applies',
    type: 'Enum',
    options: timeHorizonOptions,
    field: TargetFieldsEnum.period,
    secondField: TargetFieldsEnum.periodExplanation,
  },
  {
    title:
      'Scope of the target (including activities in own operations, upstream/downstream value chain, and geographical boundaries)',
    type: 'LongText',
    field: TargetFieldsEnum.scope,
    learnMore:
      '80. (c) the scope of the target, including the undertaking’s activities and/or its upstream and/or downstream value chain where applicable and geographical boundaries',
  },
  {
    title:
      'Methodologies and significant assumptions used to define the target, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the target considers the wider context of sustainable development and/or local situation in which impacts take place',
    type: 'LongText',
    field: TargetFieldsEnum.methodologies,
    learnMore:
      '80. (f) the methodologies and significant assumptions used to define targets, including where applicable, the selected scenario, data sources, alignment with national, EU or international policy goals and how the targets consider the wider context of sustainable development and/or local situation in which impacts take place',
  },
  {
    title: 'The target is based on conclusive scientific evidence',
    type: 'Boolean',
    field: TargetFieldsEnum.isEvidenceScientific,
    secondField: TargetFieldsEnum.isEvidenceScientificExplanation,
    learnMore:
      '80. (g) whether the undertaking’s targets related to environmental matters are based on conclusive scientific evidence',
  },
  {
    title: 'Stakeholders have been involved in the target setting',
    type: 'Boolean',
    field: TargetFieldsEnum.withStakeholders,
    secondField: TargetFieldsEnum.withStakeholdersExplanation,
    children: [
      {
        title:
          'Description of how stakeholders have been involved (related to the above data point)',
        type: 'LongText',
        field: TargetFieldsEnum.withStakeholdersDescription,
        learnMore:
          '80. (h) whether and how stakeholders have been involved in target setting for each material sustainability matter.',
      },
    ],
    learnMore:
      '80. (h) whether and how stakeholders have been involved in target setting for each material sustainability matter. Celsia’s guidance: Here, it is sufficient with a short disclosure, e.g on the form “Multiple stakeholders have been involved in target setting”, while the next input field can be used to describe more in depth how these stakeholders have been involved.',
  },
  {
    title:
      'Description of any changes in the target and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and adopted processes to collect data',
    type: 'LongText',
    field: TargetFieldsEnum.targetChangeDescription,
    learnMore:
      '80.(i) any changes in targets and corresponding metrics or underlying measurement methodologies, significant assumptions, limitations, sources and processes to collect data adopted within the defined time horizon. This includes an explanation of the rationale for those changes and their effect on comparability (see Disclosure Requirement BP-2 Disclosures in relation to specific circumstances of this Standard)',
  },
  {
    title: 'Performance against the disclosed target (explanation)',
    type: 'LongText',
    field: TargetFieldsEnum.performanceText,
    learnMore:
      '80. (j) description the performance against its disclosed targets, including information on how the target is monitored and reviewed and the metrics used, whether the progress is in line with what had been initially planned, and an analysis of trends or significant changes in the performance of the undertaking towards achieving the target.',
  },
  {
    title: 'Performance against the disclosed target (absolute value)',
    type: 'Number',
    field: TargetFieldsEnum.performanceNumber,
    learnMore:
      'Celsia’s guidance: Input the progress so far for the target from the baseline value. E.g if the target is to reduce the annual scope 1 emissions with 500 tonnes by 2030 from a baseline year of 2023 and the current reduction compared to the basline value is 200 tonnes, input 200 tonnes.',
  },
  {
    title: 'Progress is in line with initial plan',
    type: 'Boolean',
    field: TargetFieldsEnum.isProgressAligned,
    secondField: TargetFieldsEnum.isProgressAlignedExplanation,
    unit: true,
    learnMore:
      'Celsia’s guidance: Indicate whether or not the progress is following that of the initial plan.',
  },
];

export const actionsScopeMDR = [
  {
    title: 'Related impacts, risks and opportunities',
    type: 'LongText',
    field: ActionFieldsEnums.relatedIRO,
    learnMore:
      'Describe how the action (plan) addresses impacts, risks and opportunities identified.',
  },
  {
    title: 'Related policies',
    type: 'LongText',
    field: ActionFieldsEnums.relatedPolicy,
    learnMore: 'Describe how the action is linked to policy objectives.',
  },
  {
    title: 'Scope of the action',
    type: 'LongText',
    field: ActionFieldsEnums.scope,
    learnMore:
      'The scope of the key actions (i.e., coverage in terms of activities, upstream and/or downstream value chain,geographies and, where applicable, affected stakeholder groups)',
  },
  {
    title: 'Status',
    type: 'Enum',
    options: [
      {
        value: 'takenMember',
        label: 'Taken',
      },
      {
        value: 'plannedMember',
        label: 'Planned',
      },
    ],
    field: ActionFieldsEnums.statusOfKeyAction,
  },
  {
    title:
      'Expected outcomes of the action and how its implementation contributes to achievement of policy objectives and targets',
    type: 'LongText',
    field: ActionFieldsEnums.expectedOutcome,
  },
  {
    title:
      'The action provides for, or supports the provision of remedy for those harmed by actual material impacts',
    type: 'Boolean',
    field: ActionFieldsEnums.provisionOfRemedy,
    secondField: ActionFieldsEnums.provisionOfRemedyExplanation,
    learnMore:
      'Description of key action taken, and its results, to provide for and cooperate in or support provision of remedy for those harmed by actual material impacts.',
  },
  {
    title:
      'Quantitative and qualitative information regarding progress of the action disclosed in prior periods',
    type: 'LongText',
    field: ActionFieldsEnums.progressInfo,
  },
];

export const actionsFinancialMDR = [
  {
    title:
      'Where the implementation of an action requires significant operational expenditures (Opex) and/or capital expenditures (Capex) the undertaking shall provide:',
    field: 'none',
  },
  {
    title:
      'Description of the type of current and future financial investments and resources allocated to the action',
    type: 'LongText',
    field: ActionFieldsEnums.investmentsAndResources,
    learnMore:
      'Describe the type of current and future financial and other resources allocated to the action plan, including if applicable, the relevant terms of sustainable finance instruments, such as green bonds, social bonds and green loans, the environmental or social objectives.',
  },
  {
    title: 'The ability to implement the action depends on specific preconditions',
    type: 'Boolean',
    field: ActionFieldsEnums.hasPreconditions,
    secondField: ActionFieldsEnums.hasPreconditionsExplanation,
    learnMore:
      'Disclosure on whether the ability to implement the actions or action plan depends on specific preconditions, e.g., granting of financial support or public policy and market developments.',
  },
  {
    title: 'Current OpEx allocated to the action',
    type: 'Number',
    field: ActionFieldsEnums.capex,
  },
  {
    title: 'Current CapEx allocated to the action',
    type: 'Number',
    field: ActionFieldsEnums.opex,
  },
  {
    title:
      'Explanation of how the current OpEx and CapEx disclosed relate to the most relevant amounts presented in the financial statements',
    type: 'LongText',
    field: ActionFieldsEnums.noteReference,
  },
  {
    title: 'Future OpEx allocated to the action',
    type: '',
    field: 'none',
    children: [
      {
        title: 'Short-term',
        type: 'Number',
        field: ActionFieldsEnums.opexShortTerm,
      },
      {
        title: 'Medium-term',
        type: 'Number',
        field: ActionFieldsEnums.opexMediumTerm,
      },
      {
        title: 'Long-term',
        type: 'Number',
        field: ActionFieldsEnums.opexLongTerm,
      },
    ],
  },
  {
    title: 'Future CapEx allocated to the action',
    type: '',
    field: 'none',
    children: [
      {
        title: 'Short-term',
        type: 'Number',
        field: ActionFieldsEnums.capexShortTerm,
      },
      {
        title: 'Medium-term',
        type: 'Number',
        field: ActionFieldsEnums.capexMediumTerm,
      },
      {
        title: 'Long-term',
        type: 'Number',
        field: ActionFieldsEnums.capexLongTerm,
      },
    ],
  },
];
