import { useParams } from 'react-router-dom';
import { ContentHeader, ContentLayout } from 'Molecules';
import { Typography } from 'Tokens';
import { MaterialMap, MaterialStandard } from './MaterialityAssessment.d';
import { VStack } from '@chakra-ui/react';
import { MaterialityAssessmentHeader } from './MaterialitySettingsHeader';
import { Button, Infobox } from 'Atoms';
import { ORDER_OF_CATEGORIES } from 'utils/categoriesOrder';
import { MaterialityAssessmentTable } from './MaterialityAssessment';
import { useEffect, useMemo, useState } from 'react';
import { MaterialityStatusBox } from './DoubleMaterialityAssessment/MaterialityUtils';
import { isParentMaterial } from './MaterialityAssessment.hooks';

export const CollectOnlyMaterialityStatus = ({ row }: { row: MaterialStandard }) => {
  if (
    (row.isCopiedFromParent && row.isMaterial) ||
    (row.isParentMaterial && isParentMaterial(row.parentCompanyMetrics))
  ) {
    return <MaterialityStatusBox materiality="collectDataMandatory" />;
  } else if (row.isMaterial && row.isDataGatheringOnly === false) {
    return <MaterialityStatusBox materiality="collectData" />;
  }
  return <MaterialityStatusBox materiality="doNotCollect" />;
};

export const CollectOnlyMaterialityAssessment = ({
  materialityAssessments,
  hasParentMaterialityAssessment,
  loading,
  companyId,
  setStep,
  isOnboarding = false,
}: {
  materialityAssessments: MaterialMap[];
  hasParentMaterialityAssessment: boolean;
  loading: boolean;
  companyId: string | null;
  setStep?: (step: number) => void;
  isOnboarding?: boolean;
}) => {
  const { esrsAssessmentId = '' } = useParams();
  const [showNotMaterial, setShowNotMaterial] = useState(false);
  const [disableCollapse, setDisableCollapse] = useState(false);

  const standardMaterialByParent = useMemo(() => {
    const categoriesWithMaterialStandard =
      materialityAssessments.filter((ma) =>
        ma.materialStandards.some(
          (m) =>
            (m.isCopiedFromParent && m.isMaterial) ||
            (m.isParentMaterial && isParentMaterial(m.parentCompanyMetrics))
        )
      ) ?? [];
    return categoriesWithMaterialStandard.map((category) => ({
      ...category,
      materialStandards: category.materialStandards.filter(
        (m) =>
          (m.isCopiedFromParent && m.isMaterial) ||
          (m.isParentMaterial && isParentMaterial(m.parentCompanyMetrics))
      ),
    }));
  }, [materialityAssessments]);

  const standardNotMaterialByParent = useMemo(() => {
    const categoriesWithNoMaterialStandard =
      materialityAssessments.filter((ma) =>
        ma.materialStandards.some(
          (m) =>
            !(
              (m.isCopiedFromParent && m.isMaterial) ||
              (m.isParentMaterial && isParentMaterial(m.parentCompanyMetrics))
            )
        )
      ) ?? [];
    return categoriesWithNoMaterialStandard.map((category) => ({
      ...category,
      materialStandards: category.materialStandards.filter(
        (m) =>
          !(
            (m.isCopiedFromParent && m.isMaterial) ||
            (m.isParentMaterial && isParentMaterial(m.parentCompanyMetrics))
          )
      ),
    }));
  }, [materialityAssessments]);

  useEffect(() => {
    if (
      standardNotMaterialByParent.flatMap((ma) => ma.materialStandards).some((ms) => ms.isMaterial)
    ) {
      setDisableCollapse(true);
      setShowNotMaterial(true);
    } else {
      setDisableCollapse(false);
      setShowNotMaterial(false);
    }
  }, [standardNotMaterialByParent]);

  return (
    <ContentLayout
      isLoading={loading}
      variant="inline.nopad"
      header={!setStep && isOnboarding && <ContentHeader title="Materiality assessment" />}
      contentProps={{ padding: isOnboarding ? '0px' : '' }}
    >
      <VStack mt={isOnboarding ? '0px' : '28px'} w="100%" alignItems="start" spacing="16px">
        {!isOnboarding && <MaterialityAssessmentHeader title="Change materiality assessment" />}
        <VStack spacing="32px" alignItems="start" w="100%">
          {isOnboarding && (
            <Infobox
              status="info"
              title="No need for a materiality assessment"
              description="Based on configurations set by your parent company, your company’s goal does not include conducting a materiality assessment, as you will not be preparing your own report. Your primary goal is to collect and provide data necessary for the parent company's report based on materiality assessment for the whole group. However, if you decide that your company should independently report, you can adjust the company settings to initiate your own materiality assessment."
            />
          )}
          {!!standardMaterialByParent.length && (
            <VStack spacing="16px" alignItems="start" w="100%">
              <VStack spacing="2px" alignItems="start" w="100%">
                <Typography variant="h2">Review standards material for a parent</Typography>
                <Typography
                  variant="body"
                  color="text.muted"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                >
                  See which standards were defined as material by the parent company. You would need
                  to gather data for metrics related to these standards.
                </Typography>
              </VStack>
              {standardMaterialByParent
                .sort(
                  (a, b) =>
                    ORDER_OF_CATEGORIES?.[a.categoryName] - ORDER_OF_CATEGORIES?.[b.categoryName]
                )
                .map((mAssessment) => {
                  return (
                    <MaterialityAssessmentTable
                      assessment={mAssessment}
                      hasParentMaterialityAssessment={hasParentMaterialityAssessment}
                      isCollectOnly={true}
                      esrsAssessmentId={esrsAssessmentId}
                      companyId={companyId}
                      isOnboarding={isOnboarding}
                      setStep={setStep}
                    />
                  );
                })}
            </VStack>
          )}
          {!!standardNotMaterialByParent.length && (
            <VStack spacing="12px" alignItems="start" w="100%">
              <VStack spacing="2px" alignItems="start" w="100%">
                <Typography variant="h2">Other standards</Typography>
                <Typography
                  variant="body"
                  color="text.muted"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="20px"
                >
                  If you wish to collect additional data for you company (not required by parent
                  company), review standards here:
                </Typography>
              </VStack>
              {!showNotMaterial && !disableCollapse && (
                <Button
                  variant="ghost"
                  onClick={() => setShowNotMaterial(true)}
                  alignVertically={true}
                >
                  Show more standards...
                </Button>
              )}
              {showNotMaterial &&
                standardNotMaterialByParent
                  ?.sort(
                    (a, b) =>
                      ORDER_OF_CATEGORIES?.[a.categoryName] - ORDER_OF_CATEGORIES?.[b.categoryName]
                  )
                  ?.map((mAssessment) => {
                    return (
                      <MaterialityAssessmentTable
                        assessment={mAssessment}
                        hasParentMaterialityAssessment={hasParentMaterialityAssessment}
                        isCollectOnly={true}
                        esrsAssessmentId={esrsAssessmentId}
                        companyId={companyId}
                        isOnboarding={isOnboarding}
                        setStep={setStep}
                      />
                    );
                  })}
              {showNotMaterial && !disableCollapse && (
                <Button
                  variant="ghost"
                  onClick={() => setShowNotMaterial(false)}
                  alignVertically={true}
                >
                  Show less standards
                </Button>
              )}
            </VStack>
          )}
        </VStack>
      </VStack>
    </ContentLayout>
  );
};
