import { KeyResultEnums, MilestoneFields, TargetFields } from '../../../Requirement';
import { GetReportingUnitsQuery_ } from 'models';
import { isNullish } from 'utils/numbers';
import { SubsidiariesTargetsType } from '../AddTargets.hooks';
import { percentageToNumber, numberToPercentage } from 'containers/Esrs/utils';

function sumUpArray(numbers: number[]): number {
  return numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

const getMilestoneTotal = (
  milestones: number[] | undefined,
  baselines: number[] | undefined,
  baselineMilestonePairs: {
    baseline: number | undefined;
    milestone: number | undefined;
  }[]
) => {
  const totalBaselines = baselines && !!baselines.length ? sumUpArray(baselines) : undefined;

  const totalMilestones = milestones && !!milestones.length ? sumUpArray(milestones) : undefined;

  const totalGoal =
    !isNullish(totalBaselines) && !isNullish(totalMilestones)
      ? baselineMilestonePairs.reduce((acc, pair) => {
          const goal = pair.baseline !== 0 ?
              percentageToNumber(pair.milestone ?? 0, pair.baseline ?? 0) :
              pair.milestone ?? 0;
          return goal + acc;
        }, 0)
      : undefined;

  const totalPercentage =
    !isNullish(totalBaselines) && !isNullish(totalMilestones) && !isNullish(totalGoal)
      ? numberToPercentage(totalBaselines!, totalGoal!).toFixed(1)
      : undefined;

  return { totalBaselines, totalMilestones: Number(totalPercentage), totalGoal };
};

export const getBUMilestoneTotal = (
  target: TargetFields,
  reportingUnits: GetReportingUnitsQuery_['reportingUnits'] | undefined,
  milestone?: MilestoneFields
) => {
  const missingBaselines: string[] = [];
  const missingMilestones: string[] = [];

  const baselines = reportingUnits
    ?.map((ru) => {
      const baseline = target[`${KeyResultEnums.baseline}_${ru?.id}`];
      if (!isNullish(baseline)) return Number(baseline);
      else missingBaselines.push(ru.name);
      return baseline;
    })
    .filter((baseline): baseline is number => !!baseline);

  const milestones = reportingUnits
    ?.map((ru) => {
      const milestoneValue = milestone?.reportingUnits?.[`${ru?.id}`];
      if (!isNullish(milestoneValue)) return Number(milestoneValue);
      else missingMilestones.push(ru.name);
      return milestoneValue;
    })
    .filter((m): m is number => !!m);

  const baselineMilestonePairs =
    reportingUnits?.map((ru) => {
      const milestoneValue = milestone?.reportingUnits?.[`${ru?.id}`];
      const baseline = target[`${KeyResultEnums.baseline}_${ru?.id}`];
      return {
        baseline: !!baseline ? Number(baseline) : undefined,
        milestone: !isNullish(milestoneValue) ? Number(milestoneValue) : undefined,
      };
    }) ?? [];

  return {
    ...getMilestoneTotal(milestones, baselines, baselineMilestonePairs),
    missingBaselines,
    missingMilestones,
  };
};

export const getSubMilestoneTotal = (
  subsidiaries: SubsidiariesTargetsType,
  year: number | undefined
) => {
  const missingBaselines: string[] = [];
  const missingMilestones: string[] = [];

  const baselines = subsidiaries
    ?.map((subsidiary) => {
      const baseline = subsidiary.target?.keyResults.find(
        (keyResult) => keyResult.reportingUnitId === null
      )?.baseline;
      if (!!baseline) return Number(baseline);
      else missingBaselines.push(subsidiary.company.name);
    })
    .filter((baseline): baseline is number => !!baseline);

  const milestones = subsidiaries
    ?.map((subsidiary) => {
      const milestone = subsidiary.target?.milestones.find((m) => m.year === year);
      const milestoneValue = milestone?.milestoneResults.find(
        (m) => m.reportingUnitId === null
      )?.value;
      if (!isNullish(milestoneValue)) return Number(milestoneValue);
      else missingMilestones.push(subsidiary.company.name);
      return milestoneValue;
    })
    .filter((milestone): milestone is number => !isNullish(milestone));

  const baselineMilestonePairs =
    subsidiaries?.map((subsidiary) => {
      const milestone = subsidiary.target?.milestones.find((m) => m.year === year);
      const milestoneValue = milestone?.milestoneResults.find(
        (m) => m.reportingUnitId === null
      )?.value;
      const baseline = subsidiary.target?.keyResults.find(
        (keyResult) => keyResult.reportingUnitId === null
      )?.baseline;
      return {
        baseline: !!baseline ? Number(baseline) : undefined,
        milestone: !isNullish(milestoneValue) ? Number(milestoneValue) : undefined,
      };
    }) ?? [];

  return {
    ...getMilestoneTotal(milestones, baselines, baselineMilestonePairs),
    missingBaselines,
    missingMilestones,
  };
};
