import {
  Box,
  HStack,
  VStack,
  Circle,
  useColorMode,
  Th,
  TableColumnHeaderProps,
  Td,
  TableCellProps,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { Typography, colors } from 'Tokens';
import { TargetFields, TargetFieldsEnum } from '../../Requirement';
import { StepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { IconButton, Tag, Tooltip } from 'Atoms';
import { AddIcon } from 'Tokens/Icons/Function';
import { HelpIcon, WarningIcon } from 'Tokens/Icons/Status';
import { ArrowNarrowRightIcon } from 'Tokens/Icons/Direction';
import { HistogramIcon } from 'Tokens/Icons/Data';
import { YearSelector } from 'Molecules/YearSelector';
import { UseFormSetValue } from 'react-hook-form';
import { PieChart } from 'react-minimal-pie-chart';
import {
  SubsidiariesTargetsType,
  getBaselineTotal,
  getSubsidiaryBaselineTotal,
} from './AddTargets.hooks';
import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Table } from 'Molecules';
import { GetReportingUnitsQuery_ } from 'models';
import {isNullish} from 'utils/numbers';

export const TargetSectionWrapper = ({
  title,
  description,
  children,
  subSection = false,
  learnMore,
}: {
  title: string;
  description?: string;
  children: React.ReactNode;
  subSection?: boolean;
  learnMore?: {
    text: string;
    setLearnMore: (param: { title: string; description?: string }) => void;
    onOpen: () => void;
  };
}) => {
  if (subSection)
    return (
      <VStack alignItems="start" spacing="6px" w="100%">
        <HStack spacing="2px">
          <Typography variant="h4">{title}</Typography>
          <IconButton
            aria-label="learn more"
            variant="ghost"
            icon={<HelpIcon />}
            size="xs"
            onClick={() => {
              learnMore?.setLearnMore({
                title: title,
                description: learnMore.text,
              });
              learnMore?.onOpen();
            }}
          />
        </HStack>
        {children}
      </VStack>
    );
  return (
    <Box
      position="relative"
      borderLeft="1px dashed"
      pl="32px"
      borderColor="border.hover"
      pb="40px"
      w="100%"
    >
      <StepLabels
        title={title}
        description={description}
        descriptionColor="text.default"
        ml="33px"
      />
      {children}
    </Box>
  );
};

export const NotAdded = () => {
  return (
    <Typography variant="body" as="span" color="text.hint">
      Not added
    </Typography>
  );
};

export const NA = () => {
  return (
    <Typography variant="body" as="span" color="text.hint">
      N/A
    </Typography>
  );
};

export const TotalWithWarning = ({
  value,
  unit,
  isGroup,
  missingValues,
}: {
  value: number | undefined;
  unit?: string;
  isGroup: boolean;
  missingValues: string[];
}) => {
  return (
    <HStack spacing="4px" width="100%">
      <Typography variant="body" as="span">
        {value} {unit}
      </Typography>
      {!!missingValues?.length && (
        <Box p="8px">
          <Tooltip
            label={
              <VStack alignItems="start">
                <Typography variant="body" color="white">
                  Note that this is a preliminary result. This value is aggregated from the{' '}
                  {isGroup ? "subsidiaries'" : "business units'"} answers, and not all of them have
                  provided their answers yet.
                </Typography>
                <Typography variant="body" color="white">
                  The following companies have not provided an answer:
                </Typography>
                <UnorderedList pl="8px">
                  {missingValues.map((name) => (
                    <ListItem>
                      <Typography variant="body" as="li" color="white">
                        {name}
                      </Typography>
                    </ListItem>
                  ))}
                </UnorderedList>
              </VStack>
            }
            maxW="400px"
          >
            <Tag variant="warning" size="xs" p="2px">
              <WarningIcon color="text.warning" />
            </Tag>
          </Tooltip>
        </Box>
      )}
    </HStack>
  );
};

export const TableTH = ({
  children,
  unit,
  ...props
}: { children?: React.ReactNode; unit?: string } & TableColumnHeaderProps) => {
  return (
    <Th
      p="0px 8px"
      textTransform="none"
      letterSpacing="normal"
      borderColor="border.decorative"
      height="48px"
      {...props}
    >
      <VStack alignItems="start" spacing="0px">
        <Typography variant="bodyStrong" as="span" color="text.default">
          {children}
        </Typography>
        {unit && (
          <Typography variant="micro" color="text.default" as="span">
            {unit}
          </Typography>
        )}
      </VStack>
    </Th>
  );
};

export const TableTD = ({
  children,
  hasBorder = true,
  ...props
}: { children?: React.ReactNode; hasBorder?: boolean } & TableCellProps) => {
  return (
    <Td
      padding="0px 8px"
      borderColor="border.decorative"
      border={hasBorder ? '' : 'none'}
      {...props}
    >
      {children}
    </Td>
  );
};

export const TargetProgressChart = ({
  percentage,
  color,
}: {
  percentage: number;
  color: 'default' | '_dark';
}) => (
  <HStack>
    <Circle size="16px" alignItems="center">
      <PieChart
        lineWidth={25}
        data={[
          {
            title: 'Collected',
            value: percentage,
            color: colors['bg.info.accent'][color],
          },
          {
            title: 'Missing',
            value: 100 - percentage,
            color: colors['bg.unknown'][color],
          },
        ]}
      />
    </Circle>
    <Typography variant="body">{percentage}%</Typography>
  </HStack>
);

const getBaselineProgress = (
  localAnswers: TargetFields,
  subsidiariesTargets: SubsidiariesTargetsType,
  isGroup: boolean,
  requiredLength: number
) => {
  const subAnswers = subsidiariesTargets?.map(
    (sub) => sub.target?.keyResults.find((kr) => kr.reportingUnitId === null)?.baseline
  );
  const subAnswersLength =
    subAnswers?.filter((val) => !!val && val !== '' && val !== '0')?.length ?? 0;

  const ruAnswers = Object.entries(localAnswers)
    .filter(([key]) => key.includes('baseline_'))
    .map(([_, value]) => value);

  const ruAnswersLength = ruAnswers?.filter((val) => !!val && val !== 0)?.length ?? 0;

  const percentage = ((isGroup ? subAnswersLength : ruAnswersLength) / requiredLength) * 100;
  return percentage;
};

export const BaselineOverview = ({
  onBaselineOpen,
  localAnswers,
  unit,
  subsidiariesTargets,
  isGroup,
  requiredLength,
  reportingUnits,
  isFlagged,
}: {
  onBaselineOpen: () => void;
  unit: string;
  localAnswers: TargetFields;
  subsidiariesTargets: SubsidiariesTargetsType;
  isGroup: boolean;
  requiredLength: number;
  reportingUnits: GetReportingUnitsQuery_['reportingUnits'] | undefined;
  isFlagged: boolean;
}) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

  const columns: ColumnDef<TargetFields>[] = [
    {
      header: 'Year',
      id: 'year',
      cell: ({ row }) => (
        <HStack>
          <HistogramIcon />
          <Typography variant="bodyStrong" as="span">
            {new Date(row.original.baseYear ?? '').getFullYear()}
          </Typography>
        </HStack>
      ),
    },
    {
      header: localAnswers.isBottomUp ? 'Total value' : 'Value',
      id: 'value',
      cell: ({ row }) => {
        const { total: totalBaselines, missingBaselines } = isGroup
          ? getSubsidiaryBaselineTotal(subsidiariesTargets ?? [])
          : getBaselineTotal(reportingUnits ?? [], localAnswers);

        const showBaseline = !isNullish(row.original.baseline);

        if (localAnswers.isBottomUp) {
          if (!!totalBaselines && totalBaselines !== 0)
            return (
              <TotalWithWarning
                value={totalBaselines}
                unit={unit}
                isGroup={isGroup}
                missingValues={missingBaselines}
              />
            );
          return <NA />;
        }

        if (showBaseline)
          return (
            <Typography variant="body" as="span">
              {localAnswers.isBottomUp ? totalBaselines : row.original.baseline}{' '}
              {unit === 't' ? 'tonnes' : unit}
            </Typography>
          );
        return <Tag title="To add" variant="warning" size="xs" />;
      },
    },
    {
      header: 'Data collected',
      id: 'progress',
      meta: {
        width: '23%',
      },
      cell: ({ row }) => {
        const percentage = getBaselineProgress(
          row.original,
          subsidiariesTargets,
          isGroup,
          requiredLength
        );
        return <TargetProgressChart percentage={Math.round(percentage)} color={color} />;
      },
    },
    {
      header: 'Parent company status',
      id: 'status',
      cell: () => <Tag title="Requested" variant="info" size="xs" />,
    },
    {
      header: '',
      id: 'arrow',
      meta: {
        width: '5%',
      },
      cell: () => <IconButton aria-label="open" icon={<ArrowNarrowRightIcon />} variant="ghost" />,
    },
  ];

  const filteredColumns = useMemo(
    () =>
      columns
        .filter((m) => (localAnswers.isBottomUp ? true : m.id !== 'progress'))
        .filter((m) => (localAnswers.parentTargetId ? true : m.id !== 'status')),
    [columns, localAnswers]
  );

  return (
    <Table<TargetFields>
      onRowClick={onBaselineOpen}
      rowProps={{
        bg: isFlagged ? 'bg.warning' : '',
        _hover: {
          cursor: 'pointer',
          bg: 'bg.hover',
        },
      }}
      bottomRowBorder={false}
      headerHeight="48px"
      headerPadding="0px 8px"
      cellPadding="0px 8px"
      headerBorderColor="border.decorative"
      columns={filteredColumns}
      data={[localAnswers]}
      withBorder={true}
    />
  );
};

export const Baseline = ({
  localAnswers,
  onBaselineOpen,
  setValue,
  unit,
  subsidiariesTargets,
  isGroup,
  requiredLength,
  isSubsidiaryTarget,
  reportingUnits,
  assessmentYear,
  isFlagged,
}: {
  localAnswers: TargetFields;
  onBaselineOpen: () => void;
  setValue: UseFormSetValue<TargetFields>;
  unit: string;
  subsidiariesTargets: SubsidiariesTargetsType;
  isGroup: boolean;
  requiredLength: number;
  isSubsidiaryTarget: boolean;
  reportingUnits: GetReportingUnitsQuery_['reportingUnits'] | undefined;
  assessmentYear: number | undefined;
  isFlagged: boolean;
}) => {
  return !!localAnswers.baseYear ? (
    <BaselineOverview
      onBaselineOpen={onBaselineOpen}
      unit={unit}
      localAnswers={localAnswers}
      subsidiariesTargets={subsidiariesTargets}
      requiredLength={requiredLength}
      isGroup={isGroup}
      reportingUnits={reportingUnits}
      isFlagged={isFlagged}
    />
  ) : !isSubsidiaryTarget ? (
    <YearSelector
      setValue={(val: number) =>
        setValue(TargetFieldsEnum.baseYear, new Date(Date.UTC(val, 0)), { shouldDirty: true })
      }
      value={localAnswers.baseYear ?? 0}
      showCurrentYear
      buttonLeftIcon={<AddIcon />}
      buttonText="Add baseline"
      startDate={assessmentYear}
      title="Select baseline year"
      previousYearsMode={true}
    />
  ) : (
    <Typography color="text.muted" variant="body">
      The baseline value will be set by a parent company
    </Typography>
  );
};
