import {
  EsrsAssessmentFieldsFragment_,
  GetEsrsCategoriesQuery_,
  GetEsrsStandardQuery_,
  GetMaterialStandardQuery_,
  GetParentMaterialityAssessmentQuery_,
} from 'models';
import { DataCollectionType } from '../DataCollection/DataCollection.d';
import { Materiality } from './DoubleMaterialityAssessment/DoubleMaterialityAssessment.d';

export type DisclosureRequirementGroups = NonNullable<
  GetEsrsStandardQuery_['esrsStandard']
>['disclosureRequirementGroups'];

export type Requirement = DisclosureRequirementGroups[number]['requirements'][number];

export type MaterialStandard = {
  id: string;
  standardRef: string;
  standardName: string;
  description?: string | null;
  isMaterial?: boolean | null;
  isTopical?: boolean;
  isParentMaterial?: boolean | null;
  isDataGatheringOnly?: boolean;
  isCopiedFromParent?: boolean;
  metrics: EsrsAssessmentFieldsFragment_['materialStandards'][number]['materialMetrics'];
  parentCompanyMetrics: NonNullable<
    NonNullable<GetParentMaterialityAssessmentQuery_['EsrsAssessment_by_pk']>['parentAssessment']
  >['materialStandards'][number]['materialMetrics'];
  isDataCollected: boolean;
  disclosureRequirementGroups: GetEsrsCategoriesQuery_['EsrsCategory'][number]['standards'][number]['disclosureRequirementGroups'];
};

export type MaterialMap = {
  categoryName: string;
  categoryRef: string;
  hasParentMaterialityAssessment: boolean;
  materialStandards: MaterialStandard[];
};

export type BusinessUnitMaterialStandard = {
  standardRef: string;
  standardName: string;
  description?: string | null;
  isMaterialForBu?: boolean;
  isMaterialForSubsidiary?: boolean;
  isMaterialForGroupParent?: boolean;
};

export type BusinessUnitMaterialMap = {
  categoryName: string;
  categoryRef: string;
  materialStandards: BusinessUnitMaterialStandard[];
};

export type MaterialMetric = {
  id?: string;
  metricRef: string;
  dataCollection?: string | null;
  isDataGatheringOnly: boolean;
  isMaterial?: boolean | null;
  isParentMaterial?: boolean;
  isCopiedFromParent?: boolean;
  materialMetricTags?: GetMaterialStandardQuery_['materialityAssessment'][number]['materialMetrics'][number]['materialMetricTags'];
  metric?: GetMaterialStandardQuery_['materialityAssessment'][number]['materialMetrics'][number]['metric'];
};

export type MaterialDr = {
  drRef: string;
  isMaterial: boolean | null;
  isDataGatheringOnly: boolean;
};

export type ParentMaterialMetrics = {
  metricRef: string;
  isMaterial?: boolean | null;
  dataCollection?: string | null | undefined;
  metric: {
    disclosureRequirementRef: string;
  };
};

export type Metric = Requirement['metrics'][number] & {
  tags: {
    type: string;
  }[];
};

export type MaterialMetricMapping = {
  [metricRef: string]: {
    isMaterial?: boolean | null;
    isDataGatheringOnly: boolean;
    dataCollection: DataCollectionType;
    metric?: GetMaterialStandardQuery_['materialityAssessment'][number]['materialMetrics'][number]['metric'];
  };
};

export type DisclosureRequirementMateriality = {
  drRef: string;
  materialityStatus: Materiality;
  parentMaterialityStatus?: Materiality;
};

export enum MetricDataCollection {
  companyLevel = 'company',
  reportingUnitLevel = 'reportingUnits',
}
