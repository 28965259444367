import { HStack, VStack } from '@chakra-ui/react';
import { HelpTooltip } from 'Molecules';
import { CompanyAvatarWithName } from 'Organisms';
import { Typography } from 'Tokens';
import { CalendarIcon, ListIcon } from 'Tokens/Icons/Data';
import { InvestorsQuery_ } from 'models';
import React from 'react';
import { useTranslation } from 'utils/translation';

export const ReportingPeriodTileHeader = ({
  isOther,
  investors,
  title,
}: {
  isOther?: boolean;
  investors: InvestorsQuery_['investors'];
  title: string;
}) => {
  const { t } = useTranslation('assessment');

  return (
    <HStack width="100%" justifyContent="space-between">
      <VStack gap="0px" alignItems="start">
        <HStack gap="6px">
          {isOther ? <ListIcon boxSize="20px" /> : <CalendarIcon boxSize="20px" />}
          <Typography variant="h2">
            {isOther ? t('assessment:reportingPeriodTiles.title') : title}
          </Typography>
        </HStack>
        <Typography variant="detail">
          {isOther
            ? t('assessment:reportingPeriodTiles.description')
            : t('assessment:sharedModal.reportingPeriod')}
        </Typography>
      </VStack>
      <HStack gap="16px">
        {!isOther && (
          <HStack gap="16px">
            <Typography variant="body">{t('assessment:reportingPeriodTiles.requested')}</Typography>
            <HStack gap="8px">
              {investors.map((investor) => (
                <CompanyAvatarWithName
                  company={investor.portfolio.ownerCompany}
                  hideName={true}
                  status={!!investor.sharedAssessment ? 'Shared' : 'Not shared'}
                  isRounded={false}
                />
              ))}
            </HStack>
          </HStack>
        )}
        <HelpTooltip label={t('assessment:reportingPeriodTiles.tooltip')} />
      </HStack>
    </HStack>
  );
};
