import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  GetDisclosureRequirementGroupsQuery_,
  useFlaggedTargetsCountQuery,
  useGetDisclosureRequirementGroupsQuery,
  useGetEsrsStandardQuery,
} from 'models';
import { DREnums, useMaterialStandardId } from 'containers/Esrs';

export const timeHorizonOptions = [
  { value: 'short-term', label: 'Short-term' },
  { value: 'medium-term', label: 'Medium-term' },
  { value: 'long-term', label: 'Long-term' },
];

const sortRequirementGroups = (
  requirementGroups: GetDisclosureRequirementGroupsQuery_['requirementGroup']
) => {
  const drGroupOrder = ['Strategy', 'Impact, risk and opportunity management', 'Metrics'];
  const iroTypes = [DREnums.action, DREnums.policy, DREnums.target];

  const groupSort = [...requirementGroups].sort((a, b) => {
    const indexA = drGroupOrder.indexOf(a.title);
    const indexB = drGroupOrder.indexOf(b.title);

    return indexA - indexB;
  });

  const keyTitleArray = [
    ...groupSort?.map((d) => {
      if (d.title === 'Metrics') {
        if (d.requirements.every((r) => r.metrics.length === 0)) return [];
        return [
          { title: d.title, key: d.reference, counter: 0, reference: d.reference },
          ...d.requirements
            .filter((r) => !!r.metrics.length)
            .map((r) => {
              return {
                title: r.title,
                key: `${r?.type}_${r.reference}`,
                reference: r.reference,
                type: r.type,
                counter: 0,
                actionsCount: r.actions_aggregate?.aggregate?.count,
                targetsCount: r.targets_aggregate?.aggregate?.count
              };
            }),
        ];
      } else
        return [
          { title: d.title, key: d.reference, counter: 0, reference: d.reference },
          ...d.requirements
            .filter((req) => iroTypes.includes(req.type as DREnums) || req.metrics.length)
            .map((r) => {
              return {
                title: r.title,
                key: `${r?.type}_${r.reference}`,
                reference: r.reference,
                type: r.type,
                counter: 0,
                actionsCount: r.actions_aggregate?.aggregate?.count,
                targetsCount: r.targets_aggregate?.aggregate?.count
              };
            }),
        ];
    }),
  ];

  return keyTitleArray;
};

export const useGetDRGroupData = () => {
  const { standardRef = '', esrsAssessmentId = '' } = useParams();
  const iroTypes = [DREnums.action, DREnums.policy, DREnums.target];

  const { data: standardData, loading: standardLoading } = useGetEsrsStandardQuery({
    variables: { reference: standardRef },
    skip: !standardRef,
  });
  const standard = useMemo(() => standardData?.esrsStandard, [standardData]);

  const {
    companyAssessmentId,
    parentAssessmentId,
    isDataGatheringOnly,
    loading: materialLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: flaggedTargets, loading: flaggedLoading } = useFlaggedTargetsCountQuery({
    variables: {
      esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const { data, loading } = useGetDisclosureRequirementGroupsQuery({
    variables: {
      standardRef,
      materialStandardId: companyAssessmentId,
      parentStandardId: parentAssessmentId || companyAssessmentId,
      esrsAssessmentId,
    },
    skip: !standardRef || !companyAssessmentId || !esrsAssessmentId,
  });

  const requirementGroups = useMemo(() => {
    return (
      data?.requirementGroup.filter((rg) =>
        rg.requirements.find((req) => req.metrics.length || iroTypes.includes(req.type as DREnums))
      ) ?? []
    );
  }, [data]);

  const sortedRequirements = useMemo(() => {
    const sortedData = sortRequirementGroups(requirementGroups ?? []);
    return sortedData;
  }, [data]);

  const allRequirements = useMemo(
    () => data?.requirementGroup.flatMap((rg) => rg.requirements) ?? [],
    [data]
  );

  const allSubsidiariesStandard = useMemo(
    () => data?.subsidiariesAssessments?.subsidiaries.flatMap((s) => s.materialStandards) ?? [],
    [data]
  );

  const targetsCount = useMemo(
    () =>
      allRequirements.find((req) => req.type === DREnums.target)?.targets_aggregate.aggregate
        ?.count ?? 0,
    [allRequirements]
  );

  const actionsCount = useMemo(
    () =>
      (allRequirements.find((req) => req.type === DREnums.action)?.actions_aggregate.aggregate
        ?.count ?? 0) +
      (allSubsidiariesStandard
        .flatMap((ms) => ms.actions_aggregate.aggregate?.count ?? 0)
        ?.reduce((a, b) => a + b, 0) ?? 0),
    [allRequirements, allSubsidiariesStandard]
  );

  const policiesCount = useMemo(
    () =>
      (allRequirements.find((req) => req.type === DREnums.policy)?.policies?.[0]?.attachmentBox
        ?.attachments?.length ?? 0) +
      (allSubsidiariesStandard
        .flatMap((ms) => ms.policies?.[0]?.attachmentBox?.attachments?.length ?? 0)
        ?.reduce((a, b) => a + b, 0) ?? 0),
    [allRequirements, allSubsidiariesStandard]
  );

  return {
    standard,
    isDataGatheringOnly,
    sortedRequirements,
    targetsCount,
    actionsCount,
    policiesCount,
    flaggedTargets: flaggedTargets?.esrs_Target_aggregate,
    loading: loading || standardLoading || materialLoading || flaggedLoading,
  };
};
