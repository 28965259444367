import { Box, Circle, HStack, Skeleton, VStack, useColorMode } from '@chakra-ui/react';
import { CONTENT_HEADER_HEIGHT } from 'Molecules';
import { BREADCRUMBS_HEIGHT } from 'Molecules/Breadcrumbs';
import { Typography, colors } from 'Tokens';
import { TOP_MENU_HEIGHT } from 'containers/Navigation/pieces';
import { RefObject, useMemo } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { useReportProgress } from './Report.hooks';
import { ReportStructure } from './ReportComponents/ReportStructure';
import { useParams } from 'react-router-dom';
import { StructureNode } from '../Assessment/Structure';

const CompletionProgressBox = ({ assessmentId }: { assessmentId: string }) => {
  const { colorMode } = useColorMode();
  const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
  const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

  const { progress } = useReportProgress(assessmentId);
  return (
    <HStack
      gap="16px"
      bgColor="bg.info"
      border="1px solid"
      borderRadius="8px"
      borderColor="bg.info"
      padding="16px"
    >
      <Circle size="32px" alignItems="center">
        <PieChart
          lineWidth={16}
          data={[
            {
              title: 'Collected',
              value: Number(progress),
              color: colors['bg.progress'][color],
            },
            {
              title: 'Missing',
              value: 100 - Number(progress),
              color: colors['bg.unknown'][color],
            },
          ]}
        />
      </Circle>
      <VStack alignItems="stretch" gap="2px">
        <Typography variant="bodyStrong">Completion progress</Typography>
        <Typography variant="detail">{progress}%</Typography>
      </VStack>
    </HStack>
  );
};

export const ReportSideMenu = ({
  structure,
  selectedStandard,
  selectedDr,
  isPreview,
  isLoading,
  reportRef,
  setSelectedDr,
  setSelectedStandard,
  selectedStandardDRs,
}: {
  structure: StructureNode[][][];
  selectedStandard: string;
  selectedDr: string;
  isPreview: boolean;
  isLoading: boolean;
  reportRef: RefObject<HTMLDivElement>;
  setSelectedDr: (val: string) => void;
  setSelectedStandard: (val: string) => void;
  selectedStandardDRs: StructureNode[];
}) => {
  const { esrsAssessmentId } = useParams();

  return (
    <Skeleton isLoaded={!isLoading}>
      <VStack
        alignItems="stretch"
        minW="242px"
        maxW="242px"
        gap="16px"
        borderBottomColor="border.decorative"
        overflowX="hidden"
        marginTop="16px"
        height={`calc(100vh - ${TOP_MENU_HEIGHT} - ${BREADCRUMBS_HEIGHT} - ${CONTENT_HEADER_HEIGHT})`}
        position="sticky"
        top="56px"
      >
        {!isPreview && <CompletionProgressBox assessmentId={esrsAssessmentId ?? ''} />}
        <Typography variant="overline">CONTENT</Typography>
        <Box
          overflowX="hidden"
          overflowY="scroll"
          css={{
            '&::-webkit-scrollbar': {
              width: '0',
            },
            scrollbarWidth: 'none',
          }}
        >
          <ReportStructure
            nodes={structure}
            selectedDr={selectedDr}
            reportRef={reportRef}
            selectedStandard={selectedStandard}
            setSelectedDr={setSelectedDr}
            setSelectedStandard={setSelectedStandard}
            selectedStandardDRs={selectedStandardDRs}
          />
        </Box>
      </VStack>
    </Skeleton>
  );
};
