import { RadioCardGroup } from 'Molecules';
import { Typography } from 'Tokens';
import {
  MaterialityState,
  MaterialityStatus,
  StandardMaterialityState,
} from './DoubleMaterialityAssessment.d';

const getDisclosureRequirementsOptions = (isMaterialDisabled: boolean | undefined) => [
  {
    title: MaterialityStatus.material,
    disabled: isMaterialDisabled,
    subtitle: (
      <Typography variant="body" color={isMaterialDisabled ? 'text.disabled' : 'text.muted'}>
        Select if this DR is <b> material </b>for your company
      </Typography>
    ),
    value: MaterialityState.material,
  },
  {
    title: MaterialityStatus.notMaterial,
    subtitle: (
      <Typography variant="body" color="text.muted">
        Select if this DR is <b> not material</b>, and you <b>don’t</b> want to collect data in the
        tool
      </Typography>
    ),
    value: MaterialityState.notMaterial,
  },
  {
    title: MaterialityStatus.gatherData,
    subtitle: (
      <Typography variant="body" color="text.muted">
        Select if this DR is <b> not material </b>but you want to collect data in the tool
      </Typography>
    ),
    value: MaterialityState.gatherData,
  },
];

const getStandardOptions = (isMaterialDisabled: boolean | undefined) => [
  {
    title: MaterialityStatus.material,
    disabled: isMaterialDisabled,
    subtitle: (
      <Typography variant="body" color="text.muted">
        Select if this standard is <b> material </b>for the group company
      </Typography>
    ),
    value: MaterialityState.material,
  },
  {
    title: MaterialityStatus.notMaterial,
    subtitle: (
      <Typography variant="body" color="text.muted">
        Select if this standard is <b>not material</b>, and you <b>don’t</b> want to collect data in
        the tool
      </Typography>
    ),
    value: MaterialityState.notMaterial,
  },
  {
    title: MaterialityStatus.gatherData,
    subtitle: (
      <Typography variant="body" color="text.muted">
        Select if this standard is <b> not material </b>but you want to collect data in the tool
      </Typography>
    ),
    value: MaterialityState.gatherData,
  },
];

const getCollectOnlyOptions = () => [
  {
    title: 'Collect data',
    subtitle: (
      <Typography variant="body" color="text.muted">
        Select if this DR is <b> material </b>for your company
      </Typography>
    ),
    value: MaterialityState.collectData,
  },
  {
    title: 'Do not collect data',
    subtitle: (
      <Typography variant="body" color="text.muted">
        Select if this DR is <b> not material</b>, and you <b>don’t</b> want to collect data in the
        tool
      </Typography>
    ),
    value: MaterialityState.doNotCollect,
  },
];

export const StandardMaterialityRadioGroup = ({
  value,
  defaultValue,
  onChange,
  isMaterialDisabled,
  width,
  isCollectOnly = false,
}: {
  value?: StandardMaterialityState | null;
  defaultValue?: StandardMaterialityState | null;
  onChange: (value: StandardMaterialityState | null) => void;
  isMaterialDisabled?: boolean;
  width?: string;
  isCollectOnly?: boolean;
}) => {
  return (
    <RadioCardGroup
      value={value ?? ''}
      defaultValue={defaultValue ?? undefined}
      direction="vertical"
      cardWidth={width ?? '490px'}
      withRadioBtn={true}
      isNullable={true}
      onChange={(val) => onChange(val as StandardMaterialityState)}
      options={isCollectOnly ? getCollectOnlyOptions() : getStandardOptions(isMaterialDisabled)}
    />
  );
};

export const MaterialityRadioGroup = ({
  value,
  defaultValue,
  onChange,
  isMaterialDisabled,
  isDr,
  width,
  isCollectOnly = false,
}: {
  value?: MaterialityState | null;
  defaultValue?: MaterialityState | null;
  onChange: (value: MaterialityState | null) => void;
  isMaterialDisabled?: boolean;
  isDr?: boolean;
  width?: string;
  isCollectOnly?: boolean;
}) => {
  return (
    <RadioCardGroup
      value={value ?? ''}
      defaultValue={defaultValue ?? undefined}
      direction="vertical"
      cardWidth={width ?? '490px'}
      withRadioBtn={true}
      isNullable={true}
      onChange={(val) => onChange(val as MaterialityState)}
      options={
        isCollectOnly
          ? getCollectOnlyOptions()
          : isDr
            ? getDisclosureRequirementsOptions(isMaterialDisabled)
            : getStandardOptions(isMaterialDisabled)
      }
    />
  );
};
