import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Circle, Collapse, HStack, Tooltip, VStack, useColorMode } from '@chakra-ui/react';
import { IconButton } from 'Atoms';
import { Typography, colors } from 'Tokens';
import { RefObject, memo, useEffect, useMemo, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { CompletionStatus, ReportStatusIcon, toRomanNumeral } from '../ReportUtils';
import {
  useCompanyReportCategoryProgress,
  useGroupReportCategoryProgress,
  useStandardProgress,
} from '../../Assessment/StandardProgress/StandardProgress.hooks';
import { useParams } from 'react-router-dom';
import { useCategoryProgress, useDisclosureProgress } from '../Report.hooks';
import { useCompanyType } from 'utils/hooks';

type StructureNode = {
  key?: string;
  title: string;
  description?: string | null;
  isHidden?: boolean;
  type?: string;
};
const ParentNode = memo(
  ({
    node,
    isOpen,
    isVisible = true,
    setIsOpen,
    isClickable,
    isSelected,
    index,
    paddingLeft,
    progress,
  }: {
    node: StructureNode[];
    isOpen: boolean;
    isVisible?: boolean;
    isClickable?: boolean;
    setIsOpen: (param: boolean) => void;
    index?: number;
    isSelected?: boolean;
    paddingLeft?: string;
    progress: number;
  }) => {
    const scrollIntoView = () => {
      const element = document.getElementById(`${node[0].key}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      }
    };
    const { colorMode } = useColorMode();
    const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
    const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

    return (
      <Box width="100%">
        <Collapse in={isVisible} animateOpacity>
          <HStack
            onClick={scrollIntoView}
            cursor={isClickable && !isSelected ? 'pointer' : ''}
            paddingTop="8px"
            userSelect="none"
            paddingBottom="8px"
            paddingRight="8px"
            width="100%"
            spacing="8px"
            alignItems="center"
            borderRadius="8px"
            justifyContent="space-between"
            paddingLeft={paddingLeft}
            _hover={{
              backgroundColor: isClickable && !isSelected ? 'bg.hover' : '',
            }}
          >
            {node[0].isHidden ? (
              <ReportStatusIcon status={CompletionStatus.hidden} />
            ) : progress === 100 ? (
              <ReportStatusIcon status={CompletionStatus.complete} />
            ) : (
              <Tooltip label={`${progress}%`}>
                <Circle size="16px" alignItems="center">
                  <PieChart
                    lineWidth={25}
                    data={[
                      {
                        title: 'Collected',
                        value: progress,
                        color: colors['bg.progress'][color],
                      },
                      {
                        title: 'Missing',
                        value: 100 - progress,
                        color: colors['bg.unknown'][color],
                      },
                    ]}
                  />
                </Circle>
              </Tooltip>
            )}
            <VStack alignItems="stretch" gap="2px" w="100%" opacity={node[0].isHidden ? 0.3 : 1}>
              <Typography variant="bodyStrong" noOfLines={1} wordBreak="break-all">
                {index !== undefined && `${toRomanNumeral(index + 1)}. `}
                {node[0].title}
              </Typography>
            </VStack>
            <IconButton
              variant="ghost"
              aria-label="OpenButton"
              size="xs"
              icon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
              onClick={() => setIsOpen(!isOpen)}
            />
          </HStack>
        </Collapse>
      </Box>
    );
  }
);

const StructureItem = memo(
  ({
    node,
    isVisible,
    paddingLeft,
    isSelected,
    progress = 0,
  }: {
    node: StructureNode;
    isVisible?: boolean;
    paddingLeft?: string;
    isSelected: boolean;
    progress: number;
  }) => {
    const { colorMode } = useColorMode();
    const isDarkMode = useMemo(() => colorMode === 'dark', [colorMode]);
    const color = useMemo(() => (isDarkMode ? '_dark' : 'default'), [isDarkMode]);

    const scrollIntoView = () => {
      const element = document.getElementById(`${node.key}`);
      if (element) {
        element.scrollIntoView({
          behavior: 'instant',
        });
      }
    };

    return (
      <Box width="100%">
        <Collapse in={isVisible} animateOpacity>
          <HStack
            paddingTop="8px"
            cursor={!isSelected ? 'pointer' : ''}
            width="100%"
            paddingBottom="8px"
            spacing="8px"
            userSelect="none"
            alignItems="start"
            borderRadius="8px"
            onClick={scrollIntoView}
            justifyContent="space-between"
            paddingLeft={paddingLeft}
            _hover={{
              backgroundColor: !isSelected ? 'bg.hover' : '',
            }}
          >
            {node.isHidden ? (
              <ReportStatusIcon status={CompletionStatus.hidden} />
            ) : progress === 100 ? (
              <ReportStatusIcon status={CompletionStatus.complete} />
            ) : (
              <Tooltip label={`${progress}%`}>
                <Circle size="16px" alignItems="center">
                  <PieChart
                    lineWidth={25}
                    data={[
                      {
                        title: 'Collected',
                        value: progress,
                        color: colors['bg.progress'][color],
                      },
                      {
                        title: 'Missing',
                        value: 100 - progress,
                        color: colors['bg.unknown'][color],
                      },
                    ]}
                  />
                </Circle>
              </Tooltip>
            )}
            <VStack alignItems="stretch" gap="2px" w="100%" opacity={node.isHidden ? 0.3 : 1}>
              <Typography variant="bodyStrong" noOfLines={1}>
                {node.title}
              </Typography>
            </VStack>
          </HStack>
        </Collapse>
      </Box>
    );
  }
);

const DRNode = ({
  node,
  isVisible,
  setSelectedStandard,
  standardTitle,
  selectedDr,
  setSelectedDr,
  paddingLeft,
  standardRef,
}: {
  node: StructureNode;
  isVisible?: boolean;
  standardTitle: string;
  selectedDr: string;
  setSelectedDr: (val: string) => void;
  setSelectedStandard: (val: string) => void;
  paddingLeft?: string;
  standardRef: string;
}) => {
  const { esrsAssessmentId } = useParams();

  const { progress } = useDisclosureProgress(node?.key ?? '', esrsAssessmentId ?? '', standardRef);
  const isSelected = useMemo(() => selectedDr === node?.title, [selectedDr, node]);
  return (
    <Box position="relative">
      <Box
        onClick={() => {
          setSelectedStandard(standardTitle);
          setSelectedDr(node?.title);
        }}
      >
        <Box
          position="absolute"
          opacity={isSelected ? 1 : 0}
          left={isSelected ? 0 : '20px'}
          height="36px"
          borderRadius="8px"
          w="100%"
          bg="bg.selected"
          transition="0.3s ease-in-out"
          zIndex={-1}
          top="0px"
        />
        <StructureItem
          node={node}
          isVisible={isVisible}
          isSelected={isSelected}
          paddingLeft={paddingLeft}
          progress={Number(progress)}
        />
      </Box>
    </Box>
  );
};
const StandardNode = ({
  node,
  isCategoryOpen,
  selectedStandard,
  selectedDr,
  reportRef,
  setSelectedDr,
  setSelectedStandard,
  selectedStandardDRs,
  isGroup,
}: {
  node: StructureNode[];
  isCategoryOpen: boolean;
  selectedStandard: string;
  setSelectedDr: (val: string) => void;
  reportRef: RefObject<HTMLDivElement>;
  selectedDr: string;
  setSelectedStandard: (val: string) => void;
  selectedStandardDRs: StructureNode[];
  isGroup: boolean;
}) => {
  const [isStandardOpen, setIsStandardOpen] = useState(false);

  const { esrsAssessmentId } = useParams();
  const { progress } = useStandardProgress({
    standardRef: node[0].key ?? '',
    assessmentId: esrsAssessmentId ?? '',
    drRefs: selectedStandardDRs.map((dr) => dr.key ?? ''),
    parentAssessmentId: undefined,
    isGroup,
  });
  const isSelected = useMemo(
    () => selectedStandard === node[0]?.title && selectedDr === '',
    [selectedStandard, selectedDr]
  );

  useEffect(() => {
    setIsStandardOpen(isSelected);
  }, [isSelected]);

  const handleOnClick = () => {
    setSelectedStandard(node[0].title);
    setSelectedDr('');
    reportRef.current?.scrollTo({ top: 0 });
    setIsStandardOpen(!isStandardOpen);
  };

  return (
    <Box position="relative">
      <Box onClick={handleOnClick}>
        <ParentNode
          node={node}
          isVisible={isCategoryOpen}
          isOpen={isStandardOpen}
          isClickable
          isSelected={isSelected}
          setIsOpen={setIsStandardOpen}
          paddingLeft="24px"
          progress={Number(progress)}
        />
        <Box
          position="absolute"
          opacity={isSelected && isCategoryOpen ? 1 : 0}
          left={isSelected ? 0 : '20px'}
          height="36px"
          borderRadius="8px"
          w="100%"
          bg="bg.selected"
          transition="0.3s ease-in-out"
          zIndex={-1}
          top="0px"
        />
      </Box>
      {node.slice(1).map((drNode) => (
        <DRNode
          key={drNode.key}
          node={drNode}
          standardTitle={node[0].title}
          selectedDr={selectedDr}
          setSelectedDr={setSelectedDr}
          setSelectedStandard={setSelectedStandard}
          isVisible={isStandardOpen && isCategoryOpen}
          paddingLeft="48px"
          standardRef={node[0].key ?? ''}
        />
      ))}
    </Box>
  );
};

const CategoryNode = ({
  node,
  index,
  selectedStandard,
  selectedDr,
  reportRef,
  setSelectedDr,
  setSelectedStandard,
  selectedStandardDRs,
  isGroup,
}: {
  node: StructureNode[][];
  index: number;
  selectedStandard: string;
  selectedDr: string;
  reportRef: RefObject<HTMLDivElement>;
  setSelectedDr: (val: string) => void;
  setSelectedStandard: (val: string) => void;
  selectedStandardDRs: StructureNode[];
  isGroup: boolean;
}) => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(true);
  const { esrsAssessmentId = '' } = useParams();
  const { progress } = isGroup
    ? useGroupReportCategoryProgress({
        assessmentId: esrsAssessmentId,
        categoryRef: node[0][0].key ?? '',
      })
    : useCompanyReportCategoryProgress({
        assessmentId: esrsAssessmentId,
        categoryRef: node[0][0].key ?? '',
      });

  useEffect(() => {
    if (node.slice(1).some((cat) => cat[0].title === selectedStandard)) {
      setIsCategoryOpen(true);
    }
  }, [selectedStandard]);
  return (
    <>
      <ParentNode
        index={index}
        node={node[0]}
        isOpen={isCategoryOpen}
        setIsOpen={setIsCategoryOpen}
        progress={Number(progress)}
      />
      {node.slice(1).map((n, key) => (
        <StandardNode
          key={`${key}-standard`}
          node={n}
          selectedDr={selectedDr}
          reportRef={reportRef}
          isCategoryOpen={isCategoryOpen}
          selectedStandard={selectedStandard}
          setSelectedDr={setSelectedDr}
          setSelectedStandard={setSelectedStandard}
          selectedStandardDRs={selectedStandardDRs}
          isGroup={isGroup}
        />
      ))}
    </>
  );
};

export const ReportStructure = memo(
  ({
    nodes,
    selectedStandard,
    selectedDr,
    reportRef,
    setSelectedDr,
    setSelectedStandard,
    selectedStandardDRs,
  }: {
    nodes: StructureNode[][][];
    selectedStandard: string;
    selectedDr: string;
    reportRef: RefObject<HTMLDivElement>;
    setSelectedDr: (val: string) => void;
    setSelectedStandard: (val: string) => void;
    selectedStandardDRs: StructureNode[];
  }) => {
    const { isGroup } = useCompanyType();
    return (
      <VStack alignItems="stretch" display="flex" p="0px" spacing="0px">
        {nodes.map((node, index) => (
          <CategoryNode
            key={`${index}-category`}
            index={index}
            node={node}
            selectedDr={selectedDr}
            reportRef={reportRef}
            selectedStandard={selectedStandard}
            setSelectedDr={setSelectedDr}
            setSelectedStandard={setSelectedStandard}
            selectedStandardDRs={selectedStandardDRs}
            isGroup={isGroup}
          />
        ))}
      </VStack>
    );
  }
);
