import { HStack } from '@chakra-ui/react';
import { Button, IconButton, Tooltip } from 'Atoms';
import { Typography } from 'Tokens';
import { ArrowLeftIcon } from 'Tokens/Icons/Direction';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATHS_TO_BREAK_AT, LinkType } from './Breadcrumbs.utils';
import { checkCurrentPath, isValidPath, useGetPathName } from './Breadcrumbs.hooks';

export const BREADCRUMBS_HEIGHT = '40px';

const Breadcrumb = ({
  locations,
  currentPath,
  index,
  path,
  linkType,
  isEditTarget,
  isDataCollection,
  truncateMiddleCrumbs,
}: {
  locations: string[];
  currentPath: string;
  index: number;
  path: string;
  linkType: string;
  isEditTarget: boolean;
  isDataCollection: boolean;
  truncateMiddleCrumbs?: boolean;
}) => {
  const navigate = useNavigate();
  const { standardRef, reportingUnitId } = useParams();
  const isCurrentPage = useMemo(() => index === locations.length - 1, [locations]);
  const isTruncated = useMemo(() => path === '...', [path]);
  const pathTitle = useGetPathName(index, path, linkType, isEditTarget, isDataCollection);

  const isBusinessUnitStandard = useMemo(
    () => reportingUnitId && pathTitle === standardRef,
    [reportingUnitId, pathTitle, standardRef]
  );

  const newPath = useMemo(() => {
    if (isBusinessUnitStandard)
      return `${currentPath.slice(0, currentPath.lastIndexOf(path)) + path}/bu/${reportingUnitId}`;
    return currentPath.slice(0, currentPath.lastIndexOf(path)) + path;
  }, [currentPath, isBusinessUnitStandard]);

  const showTruncated = useMemo(
    () => index !== 0 && index !== locations.length - 1 && truncateMiddleCrumbs,
    [truncateMiddleCrumbs, locations]
  );

  return (
    <Link
      onClick={(e) => (isTruncated ? navigate(-1) : isCurrentPage ? e.preventDefault() : {})}
      to={newPath}
    >
      <Tooltip label={pathTitle} display={showTruncated ? 'block' : 'none'}>
        <HStack gap="2px">
          <Button
            size="xs"
            variant="ghost"
            p="1px 2px"
            maxW="200px"
            justifyContent="flex-start"
            minW="1px"
            _hover={{ bg: !isCurrentPage ? 'bg.hover' : 'unset' }}
          >
            <Typography
              variant="body"
              overflow="hidden"
              textOverflow="ellipsis"
              color={isCurrentPage ? 'text.hint' : 'unset'}
              borderRadius="4px"
              cursor={isCurrentPage ? 'default' : 'unset'}
              _pressed={{ bg: 'bg.pressed' }}
            >
              {showTruncated ? '...' : pathTitle}
            </Typography>
          </Button>

          <Typography>{!isCurrentPage && ' /'}</Typography>
        </HStack>
      </Tooltip>
    </Link>
  );
};

export const Breadcrumbs = ({
  onBackButtonClick,
  onBackNavigate,
  noPadding,
  truncateMiddleCrumbs,
}: {
  onBackButtonClick?: () => void;
  onBackNavigate?: () => void;
  noPadding?: boolean;
  truncateMiddleCrumbs?: boolean;
}) => {
  const locations = useLocation();
  const currentPath = useMemo(() => decodeURIComponent(locations.pathname), [locations]);
  const navigate = useNavigate();
  const { materialStandardId = '', reportingUnitId = '' } = useParams();
  const [paths, setPaths] = useState<string[]>([]);
  const breadcrumbsRef = useRef<HTMLDivElement>(null);

  const pathChecks = useMemo(() => checkCurrentPath(currentPath + locations.hash), [currentPath]);

  const linkType = useMemo(() => {
    if (pathChecks.isTaxonomy) {
      return LinkType.Taxonomy;
    } else if (pathChecks.isEsrs) {
      return LinkType.Esrs;
    } else if (pathChecks.isPai) {
      return LinkType.Pai;
    } else {
      return LinkType.Portfolios;
    }
  }, [pathChecks]);

  const filteredLocations: string[] = useMemo(() => {
    const findLastRelevantPathIndex = (pathToCheck: string, pathsToBreakAt: string[]) => {
      return pathsToBreakAt.reduce((lastIndex, path) => {
        const pathIndex = pathToCheck.toLocaleLowerCase().lastIndexOf(path);
        return pathIndex !== -1 ? pathIndex : lastIndex;
      }, -1);
    };

    const lastIndexToRead = findLastRelevantPathIndex(currentPath, PATHS_TO_BREAK_AT);
    const endIndex = lastIndexToRead !== -1 ? lastIndexToRead : currentPath.length;

    const pathConditions = {
      isEsrsStandard: pathChecks.isEsrsStandard,
      isChart: pathChecks.isChart,
      isEditTarget: pathChecks.isEditTarget,
      isDataCollection: pathChecks.isDataCollection,
      isEditAction: pathChecks.isEditAction,
      isEditTaxonomy: pathChecks.isEditTaxonomy,
    };

    const filteredLocs = currentPath
      .substring(0, endIndex)
      .split('/')
      .slice(2)
      .filter((path) =>
        isValidPath(path, pathConditions, materialStandardId, reportingUnitId, linkType)
      );

    return filteredLocs;
  }, [pathChecks, currentPath]);

  useEffect(() => {
    const newPaths: string[] = [];

    filteredLocations.forEach((path) => {
      const newPath = currentPath.slice(0, currentPath.lastIndexOf(path)) + path;
      newPaths.push(newPath);
    });

    setPaths(newPaths);
  }, [filteredLocations, currentPath]);

  return (
    <HStack ref={breadcrumbsRef} gap="2px" p={noPadding ? '' : '16px 16px 0'} w="fit-content">
      <IconButton
        size="xs"
        variant="ghost"
        icon={<ArrowLeftIcon />}
        onClick={() => {
          if (onBackNavigate) {
            onBackNavigate();
          } else if (onBackButtonClick) {
            onBackButtonClick();
          } else {
            navigate(paths[paths.length - 2]);
          }
        }}
        aria-label={'Back'}
      />
      {filteredLocations.map((path, index) => {
        return (
          <Breadcrumb
            key={path}
            locations={filteredLocations}
            currentPath={currentPath}
            index={index}
            isEditTarget={pathChecks.isEditTarget}
            isDataCollection={pathChecks.isDataCollection}
            linkType={linkType}
            path={path}
            truncateMiddleCrumbs={truncateMiddleCrumbs}
          />
        );
      })}
    </HStack>
  );
};
