import { Drawer } from 'Molecules';
import { VStack, HStack, Box } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { Button, ESRSNumberInput, Infobox, Link } from 'Atoms';
import { Controller, useForm, useFormState } from 'react-hook-form';
import {
  ActionFieldsFragment_,
  GetReportingUnitsQuery_,
  ShortUser,
  useGetSubsidiariesQuery,
} from 'models';
import { Typography } from 'Tokens';
import { getReportingUnits, getSubsidiaries, useUpsertAction } from '../Actions.hooks';
import { useUserData } from '@nhost/react';
import { useParams } from 'react-router-dom';
import { ActionGeneralInformation } from './ActionGeneralInformation';
import { ActionTimeline } from './ActionTimeline';
import { StepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { useToast } from 'utils/hooks';
import { ActionFields, DREnums } from '../../Requirement';
import { LoaderIcon } from 'Tokens/Icons/Status';
import { getDisclosureRequirementLink } from 'utils/links';

export const AddActionDrawer = ({
  isOpen,
  onClose,
  actionToEdit,
  actionName,
  setNewAction,
  reportingUnits,
  disclosureRequirementRef,
  isGroup,
  isParentOrSub,
  targetId,
  targetName = 'Target',
  targetEstimate,
  unit,
  isReadOnly = false,
  materialityAssessmentId,
}: {
  onClose: () => void;
  isOpen: boolean;
  actionToEdit?: ActionFieldsFragment_;
  actionName?: string;
  setNewAction?: (param: { value: string; label: string; estimate: number | null }) => void;
  reportingUnits?: GetReportingUnitsQuery_['reportingUnits'];
  disclosureRequirementRef: string;
  isGroup: boolean;
  isParentOrSub?: boolean;
  targetId?: string;
  targetName?: string;
  targetEstimate?: number | null;
  unit?: string;
  isReadOnly?: boolean;
  materialityAssessmentId: string;
}) => {
  const user: ShortUser | null = useUserData();
  const upsertAction = useUpsertAction();
  const { esrsAssessmentId = '', standardRef = '' } = useParams();
  const toast = useToast();

  const { data: subsidiariesData } = useGetSubsidiariesQuery({
    variables: { esrsAssessmentId },
    skip: !isGroup || !esrsAssessmentId,
  });

  const { reset, handleSubmit, control, watch } = useForm<ActionFields>({
    mode: 'all',
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    shouldFocusError: true,
    defaultValues: {
      title: actionName ?? '',
      dueDate: new Date(),
      startDate: new Date(),
      reportingUnits: [],
      targets: [],
    },
  });
  const { isValid, isDirty } = useFormState({ control });

  useEffect(() => {
    reset({
      title: actionToEdit?.title ?? actionName ?? '',
      dueDate: actionToEdit?.deadline ? new Date(actionToEdit.deadline) : new Date(),
      startDate: actionToEdit?.startDate ? new Date(actionToEdit.startDate) : new Date(),
      ownerId: actionToEdit?.ownerId ?? user?.id,
      reportingUnits: getReportingUnits(actionToEdit),
      subsidiaries: getSubsidiaries(actionToEdit),
      timeHorizon: actionToEdit?.timeHorizon,
      estimate:
        actionToEdit?.actionTargets.find((at) => at.target.id === targetId)?.estimate ??
        targetEstimate ??
        null,
    });
  }, [isOpen]);

  const onSubmit = (data: ActionFields, pageRedirect: boolean) => {
    upsertAction({
      data,
      actionToEdit,
      disclosureRequirementRef,
      userId: user?.id,
      isGroup,
      materialityAssessmentId,
      isDrawer: true,
      onClose,
      setNewAction,
      drawerTargetId: targetId,
      pageRedirect: pageRedirect,
    });
  };

  const openActionPage = () => {
    if (!!actionToEdit) {
      toast({ text: 'Saving action changes...', icon: <LoaderIcon /> });
    } else {
      toast({ text: 'Creating action...', icon: <LoaderIcon /> });
    }
    onSubmit(watch(), true);
  };

  const actionCompanyName = useMemo(
    () => actionToEdit?.materialStandard.esrsAssessment.company.name ?? '',
    [actionToEdit]
  );

  const actionLink = useMemo(() => {
    return `${getDisclosureRequirementLink({
      companyId: actionToEdit?.materialStandard.esrsAssessment.company.id,
      assessmentId: actionToEdit?.materialStandard.assessmentId,
      standardRef,
      disclosureRequirement: {
        reference: disclosureRequirementRef ?? '',
        type: DREnums.action,
      },
    })}/action-form/${actionToEdit?.id}/edit`;
  }, [actionToEdit]);

  return (
    <Drawer
      header={
        <VStack alignItems="start" spacing="0px">
          <Typography variant="h2">{actionToEdit?.title ?? 'Add action'}</Typography>
          <Typography variant="detail">action</Typography>
        </VStack>
      }
      onClose={onClose}
      isOpen={isOpen}
      size="lg"
      footer={
        !isParentOrSub ? (
          <HStack width="100%" justifyContent="space-between">
            <HStack>
              <Button
                variant="primary"
                isDisabled={!isDirty || !isValid}
                type="submit"
                form="actionForm"
              >
                Link action
              </Button>
              <Button variant="ghost" onClick={onClose}>
                Cancel
              </Button>
            </HStack>
            {(isGroup || !isReadOnly) && (
              <Button leftIcon={<ArrowUpRightIcon />} variant="ghost" onClick={openActionPage}>
                Go to the full Page
              </Button>
            )}
          </HStack>
        ) : undefined
      }
    >
      <VStack alignItems="flex-start" w="100%" flexGrow="1">
        <form onSubmit={handleSubmit((data) => onSubmit(data, false))} id="actionForm" noValidate>
          <VStack
            alignItems="flex-start"
            pl="0px"
            ml="16px"
            spacing="16px"
            mb={isReadOnly ? '40px' : ''}
          >
            {isParentOrSub && (
              <Infobox
                status="info"
                closable={false}
                withIcon={false}
                description={
                  isGroup ? (
                    <Typography variant="body">
                      This action is set by {actionCompanyName}. Here you can see a short summary of
                      this action. Open the{' '}
                      <Link to={actionLink} color="text.action" isExternal>
                        action’s page
                      </Link>{' '}
                      in the {actionCompanyName} interface (opens in a new tab) to see all details
                      along with the ESRS disclosure requirements.
                    </Typography>
                  ) : (
                    <Typography variant="body">
                      This action is set by {actionCompanyName}. Here you can see a short summary of
                      this action.
                    </Typography>
                  )
                }
              />
            )}
            <VStack
              alignItems="flex-start"
              pl="0px"
              ml="16px"
              spacing="0px"
              mb={isReadOnly ? '40px' : ''}
            >
              <ActionGeneralInformation
                control={control}
                isReadOnly={isReadOnly}
                isGroup={isGroup}
                reportingUnits={reportingUnits}
                subsidiariesData={subsidiariesData?.esrs?.subsidiaries}
              />
              <ActionTimeline control={control} watch={watch} isReadOnly={isReadOnly} />
              <Box position="relative" pl="32px" w="100%">
                <StepLabels title="Related target" ml="33px" />
                <VStack w="100%" alignItems="start" spacing="20px">
                  {!isReadOnly && (
                    <Infobox
                      status="neutral"
                      withIcon={false}
                      closable={false}
                      description={`You are adding this action from the target ${targetName.trimEnd()}. Here, you can add the estimate effect of this action on this specific target. Note that actions can be linked to multiple targets and may have different effects on each. Open the full action page for more detailed information.`}
                    />
                  )}
                  <Controller
                    name="estimate"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <VStack spacing="6px" w="100%" alignItems="start">
                        <Typography variant="bodyStrong">
                          Action's estimated effect on the target {targetName}
                        </Typography>
                        <ESRSNumberInput
                          value={value}
                          onChange={onChange}
                          isDisabled={isReadOnly}
                          unit={unit}
                          width="100%"
                        />
                      </VStack>
                    )}
                  />
                </VStack>
              </Box>
            </VStack>
          </VStack>
        </form>
        {!isReadOnly && (
          <Box>
            <Infobox
              status="info"
              mt="24px"
              mb="16px"
              withIcon={false}
              closable={false}
              description={
                <Typography as="p" variant="body">
                  This is a short summary for this action. Go to the{' '}
                  <Typography
                    as="span"
                    variant="body"
                    color="text.action"
                    onClick={openActionPage}
                    cursor="pointer"
                  >
                    full action's page
                  </Typography>{' '}
                  to see all details along with the ESRS disclosure requirements.
                </Typography>
              }
            />
          </Box>
        )}
      </VStack>
    </Drawer>
  );
};
