import {
  GetBuDisclosureRequirementMetricsQuery_,
  GetEsrsAssessmentMetadataDocument_,
  GetMetricsDrQuery_,
  QuestionType_Enum_,
  useEsrsAssessmentQuery,
  useGetEsrsAssessmentMetadataQuery,
  useGetReportingUnitQuery,
  useGetRequiredBuMaterialMetricsQuery,
  useReportingUnitsMetricsPerDisclosureQuery,
  useUpdateEsrsAssessmentMetaDataMutation,
} from 'models';
import { VStack, useDisclosure, HStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { IconButton, Infobox } from 'Atoms';
import { useEffect, useMemo, useState } from 'react';
import { LearnMoreDrawer, Loader, MetricLearnMoreHeader } from 'Molecules';
import { useParams, useSearchParams } from 'react-router-dom';
import { HelpIcon } from 'Tokens/Icons/Status';
import { OptOutModal } from './OptOutModal';
import {
  MetricsTableData,
  useMaterialStandardId,
  SelectedMetric,
  MetricViewEnums,
  filterMetricLevels,
  hasQuarterlyFrequency,
} from 'containers/Esrs';
import { DREnums, TimePeriods, TimePeriodsEnums } from '../Requirement';
import { getNestedRows } from './MetricAnswers.hooks';
import { useCurrentCompany } from 'utils/hooks';
import { MetricSidebar } from './MetricSidebar';
import { separateQualitativeMetricsFromQuantitativeParents } from './Metrics.utils';
import { isMetricStateMaterial } from 'containers/Esrs/utils';
import { MetricTableWithTitle } from './MetricTableWithTitle';
import { MetricsAISection } from './MetricAI';
import { MetricsTable } from './MetricsTable/MetricsTable';
import { QuarterSelector } from './AggregatedMetrics/AggregatedMetricsUtils';
import { uniq } from 'lodash';

export type AssessableMetrics = NonNullable<
  GetMetricsDrQuery_['DisclosureRequirement_by_pk']
>['metrics'];

export const Metrics = ({
  disclosureRequirementRef,
  setMetricSidebarOpen,
  disclosureRequirement,
}: {
  disclosureRequirementRef: string;
  setMetricSidebarOpen: (val: boolean) => void;
  disclosureRequirement:
    | GetBuDisclosureRequirementMetricsQuery_['requirements'][number]
    | undefined;
}) => {
  const { reportingUnitId = '', standardRef = '', esrsAssessmentId = '', view } = useParams();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedQuarter, setSelectedQuarter] = useState<TimePeriods>(TimePeriodsEnums.year);
  const [isOverview, setIsOverview] = useState<boolean>(false);
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const [isChartsView] = useState(false);
  const [isComments, setIsComments] = useState<boolean>(false);
  const [rowData, setRowData] = useState<MetricsTableData>();
  const [updateMetadata] = useUpdateEsrsAssessmentMetaDataMutation();
  const { company } = useCurrentCompany();

  const isMetricDr = useMemo(() => {
    return disclosureRequirement?.type === DREnums.metric;
  }, [disclosureRequirement]);

  const { companyAssessmentId, parentAssessmentId } = useMaterialStandardId(
    standardRef,
    esrsAssessmentId
  );

  useEffect(() => {
    if (rowData) setMetricSidebarOpen(true);
    else setMetricSidebarOpen(false);
  }, [rowData]);

  const { data } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const esrsAssessment = useMemo(() => data?.esrsAssessment, [data]);
  const [searchParams] = useSearchParams();
  const urlMetricRef = useMemo(() => searchParams.get('metricRef'), [searchParams]);
  const { data: requiredMaterialMetrics, loading: requiredmmLoading } =
    useGetRequiredBuMaterialMetricsQuery({
      variables: {
        disclosureRequirementRef: disclosureRequirementRef,
        materialStandardId: companyAssessmentId,
        parentStandardId: parentAssessmentId || companyAssessmentId,
      },
      skip: !disclosureRequirementRef || !companyAssessmentId,
    });

  const requiredMetrics = useMemo(
    () =>
      uniq(requiredMaterialMetrics?.esrs_MaterialMetric?.map((mm) => mm.metric.reference)) ?? [],
    [requiredMaterialMetrics]
  );

  const { data: buData, loading } = useReportingUnitsMetricsPerDisclosureQuery({
    variables: {
      disclosureRequirementRef,
      companyAssessmentId,
      requiredMetrics: requiredMetrics,
    },
    skip: !disclosureRequirementRef || !companyAssessmentId || !requiredMetrics.length,
  });

  const { data: ruData, loading: ruLoading } = useGetReportingUnitQuery({
    variables: { reportingUnitId },
    skip: !reportingUnitId,
  });
  const metrics = useMemo(() => buData?.assessableMetrics ?? ([] as AssessableMetrics), [buData]);

  //Chart view
  const { data: metaData, loading: metaDataLoading } = useGetEsrsAssessmentMetadataQuery({
    variables: {
      id: esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });
  const metadata = useMemo(() => metaData?.EsrsAssessment_by_pk?.metadata, [metaData]);
  // useEffect(() => {
  //   if (metadata?.isChartView) setIsChartsView(metadata?.isChartView);
  // }, [metaData]);
  useEffect(() => {
    if (isChartsView !== metadata?.isChartView && !metaDataLoading) {
      updateMetadata({
        variables: {
          id: esrsAssessmentId,
          metadata: { ...(metadata ?? {}), isChartView: isChartsView },
        },
        refetchQueries: [GetEsrsAssessmentMetadataDocument_],
      });
    }
  }, [isChartsView]);

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(urlMetricRef ?? '');
      if (element && urlMetricRef === element.id) {
        setTimeout(
          () => {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            });
          },
          isOverview ? 0 : 300
        );
      }
    }, 500);
  }, [metrics, urlMetricRef]);

  useEffect(() => {
    const isView = view === MetricViewEnums.overview ? true : false;
    const isRouteNotMatchingState = isView !== isOverview;
    if (isRouteNotMatchingState) {
      if (view === MetricViewEnums.overview) setIsOverview(true);
      else setIsOverview(false);
    }
  }, [view]);

  const filteredMetrics = useMemo(
    () =>
      separateQualitativeMetricsFromQuantitativeParents(
        filterMetricLevels(metrics, companyAssessmentId)
      ),
    [metrics, companyAssessmentId]
  );

  const areMetricsYearly = useMemo(
    () => filteredMetrics.every((metric) => !hasQuarterlyFrequency(metric)),
    [filteredMetrics]
  );

  const areMetricsQuarterly = useMemo(
    () => filteredMetrics.every((metric) => hasQuarterlyFrequency(metric)),
    [filteredMetrics]
  );

  useEffect(() => {
    if (areMetricsYearly) setSelectedQuarter(TimePeriodsEnums.year);
    else if (areMetricsQuarterly) setSelectedQuarter(TimePeriodsEnums.q1);
  }, [areMetricsYearly, areMetricsQuarterly, buData]);

  const nestedMetrics = useMemo(
    () =>
      filteredMetrics.map((metric) => {
        const materialStandardId = isMetricStateMaterial(
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.isMaterial
        )
          ? companyAssessmentId
          : parentAssessmentId;
        return getNestedRows(metric, materialStandardId);
      }),
    [filteredMetrics]
  );

  const qualitativeMetrics = useMemo(
    () =>
      filteredMetrics.filter(
        (metric) =>
          metric.metricType === QuestionType_Enum_.LongText_ ||
          metric.metricType === QuestionType_Enum_.YesNo_ ||
          metric.metricType === QuestionType_Enum_.SingleChoice_ ||
          metric.metricType === QuestionType_Enum_.MultipleChoice_
      ),
    [filteredMetrics]
  );

  const allFilteredMetrics = useMemo(() => {
    const metricArray: string[] = [];
    const filterMetrics = (metric: MetricsTableData, hidden?: boolean): MetricsTableData => {
      if (metric.subRows) {
        if (metricArray.includes(metric.metric.reference) && metric.parentMetric) {
          return {
            ...metric,
            hidden: hidden,
            subRows: [
              {
                metric: metric.metric,
                referenceToSource: metric.metric?.reference,
                hidden: hidden,
                subRows: metric.subRows.map((subrow) => ({
                  ...subrow,
                  hidden: true,
                  subRows: subrow?.subRows?.map((row) => filterMetrics(row, true)),
                })),
              },
            ],
          };
        }
        metricArray.push(metric.metric.reference);
        const subRows = metric?.subRows?.map((row) => filterMetrics(row, hidden));
        if (subRows) {
          return { ...metric, subRows: subRows, hidden: hidden };
        }
      }
      return { ...metric, hidden: hidden };
    };
    return [...nestedMetrics.map((metric) => filterMetrics(metric))];
  }, [nestedMetrics]);

  const someTagsNotConfigured = useMemo(() => {
    return allFilteredMetrics.some((metric) => {
      const materialMetric = metric.metric.materialMetrics?.find(
        (mm) => mm.materialStandardId === companyAssessmentId
      );
      const requiredTags = metric.metric.adminPanelTags.filter((tag) => !tag.isOptional);

      return requiredTags.some((requiredTag) => {
        const matchingTag = materialMetric?.materialMetricTags?.find(
          (tag) => tag.tagType === requiredTag.type
        );
        return !matchingTag || matchingTag.materialTagValues.length === 0;
      });
    });
  }, [allFilteredMetrics, companyAssessmentId]);

  if (loading || metaDataLoading || ruLoading || requiredmmLoading) return <Loader />;

  return (
    <HStack w="100%" alignItems="start" spacing="24px">
      <VStack spacing="24px" alignItems="start" w="100%">
        <HStack justifyContent="space-between" w="100%">
          <HStack justifyContent="space-between" w="100%">
            <Typography variant="bodyLarge" as="span" color="text.hint">
              {disclosureRequirement?.reference}{' '}
              <Typography variant="h3" as="span">
                {disclosureRequirement?.title}
              </Typography>
            </Typography>
            {!areMetricsYearly && (
              <QuarterSelector
                defaultQuarter={selectedQuarter as TimePeriodsEnums}
                setSelectedQuarter={setSelectedQuarter}
              />
            )}
          </HStack>
          <HStack>
            <IconButton
              aria-label="learn more"
              variant="ghost"
              icon={<HelpIcon />}
              size="sm"
              onClick={() => {
                setSelectedMetric({
                  title: disclosureRequirement?.title,
                  description: disclosureRequirement?.description ?? '',
                  isDisclosure: true,
                });
                onDrawerOpen();
              }}
            />
          </HStack>
        </HStack>
        <>
          <VStack alignItems="stretch" gap="12px" w="100%">
            {isMetricDr && (
              <MetricsAISection
                disclosureRequirementRef={disclosureRequirementRef}
                materialStandardId={companyAssessmentId}
                aggregatedMetricsAnswers={[]}
                reportingUnitId={reportingUnitId}
                esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                allFilteredMetrics={allFilteredMetrics}
                isGroup={false}
                rowData={rowData}
                setRowData={setRowData}
                onOpen={onOpen}
                qualitativeMetrics={qualitativeMetrics.map((m) => ({ metric: m }))}
                selectedQuarter={selectedQuarter}
                summaryQualitativeMetrics={[]}
                setSelectedMetric={setSelectedMetric}
                parentStandardId={parentAssessmentId}
                requiredMaterialMetrics={requiredMaterialMetrics?.esrs_MaterialMetric ?? []}
              />
            )}
            {someTagsNotConfigured && (
              <Infobox
                status="warning"
                closable={false}
                title="Some data points are misconfigured"
                description="Some data points in this disclosure requirement were not setup correctly. Please, go to the standard settings to complete the required configurations."
              />
            )}
          </VStack>

          <VStack spacing="40px" alignItems="stretch" w="100%" minH="fit-content" mb="40px">
            {allFilteredMetrics
              .filter((m) => m.metric.metricType === QuestionType_Enum_.Decimal_)
              .map((metric) => (
                <MetricTableWithTitle
                  metric={metric}
                  selectedQuarter={selectedQuarter}
                  companyStandardId={companyAssessmentId}
                  currency={company?.currency ?? ''}
                  esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                  rowData={rowData}
                  isGroup={company?.isGroupOwner}
                  setRowData={setRowData}
                  filteredMetrics={allFilteredMetrics}
                  isMetricDr={isMetricDr}
                  disclosureRequirementTitle={disclosureRequirement?.title ?? ''}
                  businessUnitName={ruData?.bu?.name ?? ''}
                  setIsComments={setIsComments}
                  parentStandardId={parentAssessmentId}
                  requiredMaterialMetrics={requiredMaterialMetrics?.esrs_MaterialMetric ?? []}
                />
              ))}
            {!isOverview && !!qualitativeMetrics.length && (
              <>
                <MetricsTable
                  metrics={allFilteredMetrics.filter(
                    (m) => m.metric.metricType !== QuestionType_Enum_.Decimal_
                  )}
                  selectedQuarter={selectedQuarter}
                  materialStandardId={companyAssessmentId}
                  currency={company?.currency ?? ''}
                  esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                  rowData={rowData}
                  setRowData={setRowData}
                  allMetrics={allFilteredMetrics}
                  isMetricDr={isMetricDr}
                  setIsComments={setIsComments}
                  isNarrative
                  isBusinessUnit
                  isGroup={company?.isGroupOwner ?? false}
                  parentStandardId={parentAssessmentId}
                  requiredMaterialMetrics={requiredMaterialMetrics?.esrs_MaterialMetric ?? []}
                />
              </>
            )}
          </VStack>
        </>
        <LearnMoreDrawer
          header={selectedMetric?.isDisclosure ? selectedMetric.title : undefined}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          description={selectedMetric?.description}
          customHeader={
            !selectedMetric?.isDisclosure ? (
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetric?.tags ?? []}
              />
            ) : undefined
          }
        />
        <OptOutModal
          isOpen={isOpen}
          onClose={onClose}
          assessmentId={esrsAssessmentId}
          reportingUnitId={reportingUnitId}
          metric={selectedMetric}
          isCompany={false}
        />
      </VStack>
      {!!rowData && (
        <MetricSidebar
          row={rowData}
          isComments={isComments}
          metric={rowData?.metric ?? ({} as MetricsTableData['metric'])}
          materialStandardId={companyAssessmentId}
          companyReportingUnit={reportingUnitId}
          esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
          selectedMetric={selectedMetric}
          setSelectedMetric={setSelectedMetric}
          onMenuOpen={onOpen}
          selectedQuarter={selectedQuarter}
          setRowData={setRowData}
          isGroup={false}
          isMetricDr={isMetricDr}
          companyCurrency={company?.currency ?? ''}
        />
      )}
    </HStack>
  );
};
