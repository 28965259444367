import {
  useGetActionsQuery,
  useGetTargetsQuery,
  GetTargetsDrQuery_,
  GetParentTargetsDrQuery_,
  GetTargetsSubsidiariesQuery_,
  useGetTargetsDrQuery,
  useGetTargetsSubsidiariesQuery,
  useGetParentTargetsDrQuery,
  useEsrsAssessmentActionsQuery,
  ShortUser,
  AttachmentBox,
  NoteHistory,
  useGetTargetDocumentationByIdQuery,
  useGetTargetMetricsQuery,
} from 'models';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMaterialStandardId } from 'containers/Esrs';
import { useCurrentCompany } from 'utils/hooks';

type Target =
  | GetParentTargetsDrQuery_['esrs_Target'][number]
  | NonNullable<
      GetTargetsSubsidiariesQuery_['esrs']
    >['subsidiaries'][number]['materialStandards'][number]['targets'][number];

export type CombinedTargetsData = {
  title: string;
  isSubOrParent: boolean;
  disclosureRequirementRef: string;
  earliestMilestone?: number;
  latestMilestone?: number;
  targetValue?: number;
  companyName?: string;
  companyId?: string;
  baseYear: number | undefined;
  baseline: number;
  parentTargetId: string | null;
  isRequired: boolean;
  isFlagged: boolean;
  esrsAssessment: string;
  metricRef?: string | null;
  metricTitle?: string | null;
  milestones: {
    year: number;
    value: number;
  }[];
  actions: {
    title: string;
    id: number;
  }[];
  numberOfActions: number;
  owner?: ShortUser | null;
  targetId: string;
  standard?: {
    reference: string;
    title: string;
  };
  category?: {
    reference: string;
    title: string;
  };
};

export const useCombineTargets = (
  {
    targetsData,
    parent,
    subsidiaries,
  }: {
    targetsData?: GetTargetsDrQuery_['DisclosureRequirement_by_pk'];
    parent?: GetParentTargetsDrQuery_['esrs_Target'];
    subsidiaries?: NonNullable<GetTargetsSubsidiariesQuery_['esrs']>['subsidiaries'];
  },
  companyName?: string,
  companyId?: string
) => {
  const getTargetValue = (target: Target, latestYear?: number) => {
    return target?.milestones
      .find((ml: any) => ml.year === latestYear)
      ?.milestoneResults.find((mr) => mr.reportingUnitId === null)?.value;
  };
  const mapMilestones = (target: Target) => {
    return target?.milestones.map((ml: any) => ({
      year: ml.year,
      value: ml.milestoneResults[0].value,
    }));
  };
  const mapActions = (target: Target) => {
    return target?.actionTargets.map((action: any) => ({
      title: action.action.title,
      id: action.actionId,
    }));
  };

  const mapTargets = (
    isSubOrParent: boolean,
    company?: { id: string; name: string },
    targets?: Target[]
  ) => {
    return targets?.map((target) => {
      const milestoneYears = target?.milestones.map((milestone: any) => milestone.year);
      const earliestYear = milestoneYears?.length ? Math.min(...milestoneYears) : undefined;
      const latestYear = milestoneYears?.length ? Math.max(...milestoneYears) : undefined;
      const baseline = target.keyResults.find((kr) => kr.reportingUnitId === null);
      return {
        title: target.name ?? '',
        isSubOrParent,
        earliestMilestone: earliestYear,
        baseYear: target?.keyResults?.[0]?.baseYear,
        baseline: Number(baseline?.baseline),
        latestMilestone: latestYear,
        disclosureRequirementRef: targetsData?.reference ?? '',
        targetValue: getTargetValue(target, latestYear),
        milestones: mapMilestones(target),
        companyName: company?.name ?? 'N/A',
        companyId: company?.id,
        actions: mapActions(target),
        numberOfActions: target?.actionTargets.length,
        owner: target?.owner,
        targetId: target?.id,
        parentTargetId: target.parentTargetId,
        isRequired: target.isRequired,
        isFlagged: baseline?.isFlagged ?? false,
        esrsAssessment: target.materialStandard.assessmentId,
        metricRef: target.metricRef,
        metricTitle: target.metric?.title,
      };
    });
  };

  const companyTargets = useMemo(
    () =>
      targetsData
        ? mapTargets(false, { id: companyId ?? '', name: companyName ?? '' }, targetsData.targets)
        : [],
    [targetsData, companyName]
  );

  const subsidiariesTargets = useMemo(
    () =>
      subsidiaries?.flatMap(
        (s) =>
          mapTargets(
            true,
            s?.company,
            s?.materialStandards[0]?.targets?.filter((t) => t.parentTargetId === null)
          ) ?? []
      ) ?? [],
    [subsidiaries]
  );

  const parentTargets = useMemo(
    () => mapTargets(true, parent?.[0]?.materialStandard.esrsAssessment.company, parent) ?? [],
    [parent]
  );

  const combinedData: CombinedTargetsData[] = useMemo(
    () => companyTargets?.concat(subsidiariesTargets).concat(parentTargets) ?? [],
    [companyTargets, parentTargets, subsidiariesTargets]
  );

  return combinedData;
};

export const useGetTargetsFormData = () => {
  const { esrsAssessmentId = '', standardRef = '', disclosureRequirementRef = '' } = useParams();
  const { company } = useCurrentCompany();

  const {
    companyAssessmentId,
    parentAssessmentId,
    loading: materialLoading,
  } = useMaterialStandardId(standardRef, esrsAssessmentId);

  const { data: targetsData, loading: targetsLoading } = useGetTargetsDrQuery({
    variables: {
      reference: disclosureRequirementRef,
      esrsAssessmentId,
      standardRef,
    },
    skip: !disclosureRequirementRef || !esrsAssessmentId || !standardRef,
  });

  const [targetDR, targets] = useMemo(
    () => [
      targetsData?.DisclosureRequirement_by_pk,
      targetsData?.DisclosureRequirement_by_pk?.targets,
    ],
    [targetsData]
  );

  const { data: subData, loading: subLoading } = useGetTargetsSubsidiariesQuery({
    variables: {
      esrsAssessmentId,
      standardRef,
    },
    skip: !esrsAssessmentId || !standardRef || !company?.isGroupOwner,
  });

  const subsidiaries = useMemo(() => subData?.esrs?.subsidiaries, [subData]);

  const { data: pData, loading: parentLoading } = useGetParentTargetsDrQuery({
    variables: {
      parentStandardId: parentAssessmentId,
    },
    skip: !parentAssessmentId,
  });

  const parentTargets = useMemo(() => pData?.esrs_Target, [pData]);

  const { data: actionData, loading: actionsLoading } = useGetActionsQuery({
    variables: {
      disclosureRequirementRef: targetDR?.reference ?? '',
      assessmentId: companyAssessmentId,
    },
    skip: !companyAssessmentId || !targetDR?.reference,
  });

  const { data: assessmentActions } = useEsrsAssessmentActionsQuery({
    variables: {
      esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const actions = useMemo(() => actionData?.actions, [actionData]);

  const combinedData = useCombineTargets(
    { targetsData: targetDR, parent: parentTargets, subsidiaries },
    company?.name,
    company?.id
  );

  return {
    targetDR,
    targets,
    actions,
    assessmentActions,
    combinedData,
    subsidiaries,
    parentTargets,
    loading: targetsLoading || parentLoading || subLoading || actionsLoading || materialLoading,
  };
};

export const useGetFilteredMetrics = (disclosureRequirementRef: string, assessmentId: string) => {
  const { standardRef = '', esrsAssessmentId = '' } = useParams();

  const { companyAssessmentId, parentAssessmentId } = useMaterialStandardId(
    standardRef,
    esrsAssessmentId
  );

  const { data: metrics } = useGetTargetMetricsQuery({
    variables: {
      standardRef: standardRef,
      companyAssessmentId: companyAssessmentId,
      parentAssessmentId: parentAssessmentId || companyAssessmentId,
    },
    skip: !standardRef || !companyAssessmentId,
  });

  const { data: allTData } = useGetTargetsQuery({
    variables: {
      disclosureRequirementRef,
      assessmentId,
    },
    skip: !disclosureRequirementRef || !assessmentId,
  });
  const allTargets = useMemo(() => allTData?.targets, [allTData]);

  const filteredMetrics = useMemo(
    () =>
      metrics?.metrics.filter(
        (m) => !allTargets?.find((target) => target.metricRef === m?.reference),
        [allTargets, metrics]
      ),
    [allTargets, metrics]
  );

  if (!disclosureRequirementRef || !assessmentId) {
    return ['show'];
  }

  return filteredMetrics;
};

export const useGetTargetDocumentation = (targetId: string | undefined) => {
  const { data: documentationData, loading: documentationLoading } =
    useGetTargetDocumentationByIdQuery({
      variables: {
        targetId: targetId,
      },
      skip: !targetId,
    });

  const attachmentBox: AttachmentBox | undefined = useMemo(
    () => documentationData?.esrs_Target_by_pk?.attachmentBox ?? undefined,
    [documentationData]
  );

  const noteHistory: NoteHistory | undefined = useMemo(
    () => documentationData?.esrs_Target_by_pk?.noteHistory ?? undefined,
    [documentationData]
  );
  return {
    attachmentBox,
    noteHistory,
    documentationData,
    documentationLoading,
  };
};
