import { CircularProgress, HStack, VStack, Box, Skeleton } from '@chakra-ui/react';
import { UserAvatar } from 'Organisms';
import { Typography } from 'Tokens';
import {
  useGroupStandardProgress,
  useRegularCompanyStandardProgress,
  useReportingUnitStandardProgress,
} from 'containers/Esrs/pieces/Assessment/StandardProgress/StandardProgress.hooks';
import { ShortUser, User } from 'models';
import { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { DREnums } from 'containers/Esrs';
import { useTranslation } from 'utils/translation';

const InfoCard = ({
  title,
  subtitleText,
  content,
}: {
  title: string;
  subtitleText?: string;
  content: ReactNode;
}) => {
  return (
    <VStack alignItems="flex-start" bg="bg.muted" flex={1} p="20px" borderRadius="8px" gap="0px">
      <Typography variant="h3">
        {title}
        {subtitleText && '*'}
      </Typography>
      <Box minHeight="23px;">
        {subtitleText && (
          <Typography fontSize="xs" color="text.muted" lineHeight="12px;">
            {subtitleText}
          </Typography>
        )}
      </Box>
      {content}
    </VStack>
  );
};
const RegularCompanyStandardProgress = ({
  disclosureRequirementsRefs,
  parentAssessmentId,
}: {
  disclosureRequirementsRefs: string[];
  parentAssessmentId: string;
}) => {
  const { esrsAssessmentId, standardRef } = useParams();

  const { progress, loading } = useRegularCompanyStandardProgress({
    standardRef: standardRef ?? '',
    assessmentId: esrsAssessmentId ?? '',
    drRefs: disclosureRequirementsRefs,
    parentAssessmentId: parentAssessmentId,
  });

  return (
    <Skeleton isLoaded={!loading}>
      <HStack spacing="8px">
        <CircularProgress
          size="28px"
          thickness="16px"
          color="text.info"
          value={Number(progress ?? '0')}
        />
        <Typography variant="h3">{`${progress}%`}</Typography>
      </HStack>
    </Skeleton>
  );
};

const ReportingUnitStandardProgress = ({
  disclosureRequirementsRefs,
  parentAssessmentId,
}: {
  disclosureRequirementsRefs: string[];
  parentAssessmentId: string | undefined;
}) => {
  const { esrsAssessmentId, standardRef, reportingUnitId } = useParams();

  const { progress, loading } = useReportingUnitStandardProgress({
    standardRef: standardRef ?? '',
    assessmentId: esrsAssessmentId ?? '',
    reportingUnitId: reportingUnitId ?? '',
    drRefs: disclosureRequirementsRefs,
    parentAssessmentId: parentAssessmentId,
  });

  return (
    <Skeleton isLoaded={!loading}>
      <HStack spacing="8px">
        <CircularProgress
          size="28px"
          thickness="16px"
          color="text.info"
          value={Number(progress ?? '0')}
        />
        <Typography variant="h3">{`${progress}%`}</Typography>
      </HStack>
    </Skeleton>
  );
};

const GroupStandardProgress = ({
  disclosureRequirementsRefs,
}: {
  disclosureRequirementsRefs: string[];
}) => {
  const { esrsAssessmentId, standardRef } = useParams();

  const { progress, loading } = useGroupStandardProgress({
    standardRef: standardRef ?? '',
    assessmentId: esrsAssessmentId ?? '',
    drRefs: disclosureRequirementsRefs,
  });

  return (
    <Skeleton isLoaded={!loading}>
      <HStack spacing="8px">
        <CircularProgress
          size="28px"
          thickness="16px"
          color="text.info"
          value={Number(progress ?? '0')}
        />
        <Typography variant="h3">{`${progress}%`}</Typography>
      </HStack>
    </Skeleton>
  );
};

export const DisclosureRequirementHeader = ({
  isGroup,
  owner,
  disclosureRequirementsRefs,
  parentAssessmentId,
  requirements,
}: {
  isGroup: boolean;
  owner?: Partial<User> | null;
  disclosureRequirementsRefs: string[];
  parentAssessmentId: string;
  requirements: any;
}) => {
  const { t } = useTranslation(['common']);
  const url = window.location.href;
  const isBusinessUnit = useMemo(() => url.includes('bu-standard'), [url]);

  const standardRefExcluded = useMemo(
    () =>
      requirements
        .flat()
        .filter((item: any) => item.type === DREnums.action || item.type === DREnums.target)
        .map((item: any) => item.reference),
    [requirements]
  );

  const subtitleText = useMemo(
    () =>
      standardRefExcluded.length
        ? t('common:standardRefExcludedText', { standards: standardRefExcluded.join(', ') })
        : '',
    [standardRefExcluded]
  );

  return (
    <VStack alignItems="stretch">
      <Typography variant="h2">Disclosure Requirements</Typography>
      <HStack w="100%" gap="8px">
        <InfoCard
          title="Data collection progress"
          subtitleText={subtitleText}
          content={
            isGroup ? (
              <GroupStandardProgress disclosureRequirementsRefs={disclosureRequirementsRefs} />
            ) : isBusinessUnit ? (
              <ReportingUnitStandardProgress
                disclosureRequirementsRefs={disclosureRequirementsRefs}
                parentAssessmentId={parentAssessmentId}
              />
            ) : (
              <RegularCompanyStandardProgress
                disclosureRequirementsRefs={disclosureRequirementsRefs}
                parentAssessmentId={parentAssessmentId}
              />
            )
          }
        />
        <InfoCard
          title="Main responsible"
          content={
            <HStack spacing="8px">
              <UserAvatar user={owner as ShortUser} size="sm" boxSize="28px" border="none" />
              <Typography variant="h3">{owner?.displayName}</Typography>
            </HStack>
          }
        />
      </HStack>
    </VStack>
  );
};
