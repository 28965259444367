import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { VStack, Textarea, Menu, MenuButton, MenuList, MenuItem, HStack } from '@chakra-ui/react';
import { Button } from 'Atoms';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import {
  GetMetricAnswersDocument_,
  GetSingleEsrsMetricAnswerDocument_,
  useOptOutMetricMutation,
} from 'models';
import { Modal } from 'Molecules';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { useToast } from 'utils/hooks';
import { AssessableMetrics } from './Metrics';
import { MaybeHasChild } from './MetricAnswers.hooks';

enum OptOutReasonValue {
  NOT_APPLICABLE = 'not-applicable',
  DATA_NOT_AVAILABLE = 'data-not-available',
  WRONG_DATA_FORMAT = 'wrong-data-format',
  OTHER = 'other',
  DISCLOSED_EVERYWHERE = 'disclosed_everywhere',
}

type OptOutReason = {
  value: OptOutReasonValue;
  label: string;
  getDescription: (entityType: string) => string;
};

const OPT_OUT_REASON_OPTIONS: OptOutReason[] = [
  {
    value: OptOutReasonValue.NOT_APPLICABLE,
    label: 'Not applicable',
    getDescription: (entityType: string) => `This metric is not applicable for this ${entityType}`,
  },
  {
    value: OptOutReasonValue.DISCLOSED_EVERYWHERE,
    label: 'Disclosed elsewhere',
    getDescription: () => `Data disclosed elsewhere in the report`,
  },
  {
    value: OptOutReasonValue.OTHER,
    label: 'Other reason',
    getDescription: () => `None of above are applicable`,
  },
];

const ReasonSelectorMenu = ({
  reasons,
  selectedReason,
  setSelectedReason,
  entityType,
}: {
  reasons: OptOutReason[];
  selectedReason: string;
  setSelectedReason: Dispatch<SetStateAction<string | undefined>>;
  entityType: string;
}) => {
  return (
    <Menu gutter={2}>
      <MenuButton
        as={Button}
        width="100%"
        h="36px"
        bg="bg.default"
        borderWidth="1px"
        borderColor="border.default"
        _hover={{ borderColor: 'border.hover' }}
        _focus={{
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
        }}
        _active={{
          bg: 'bg.default',
        }}
        borderRadius="8px"
        p="8px"
        rightIcon={<ChevronDownIcon color="inherit" />}
        textAlign="left"
        color="text.default"
      >
        <Typography
          variant="body"
          noOfLines={1}
          color={selectedReason ? 'text.default' : 'text.hint'}
        >
          {selectedReason
            ? reasons.find((reason) => reason.value === selectedReason)?.label
            : 'Select option'}
        </Typography>
      </MenuButton>
      <MenuList p="8px" w="455px">
        {reasons.map((reason) => {
          return (
            <MenuItem
              p="8px"
              m="0px"
              w="100%"
              onClick={() => {
                setSelectedReason(reason.value);
              }}
            >
              <HStack
                width="100%"
                justifyContent="space-between"
                color={selectedReason === reason.value ? 'text.selected' : ''}
              >
                <VStack alignItems="flex-start" spacing="0px">
                  <Typography
                    variant="bodyStrong"
                    color={selectedReason === reason.value ? 'text.selected' : 'text.muted'}
                  >
                    {reason.label}
                  </Typography>
                  <Typography variant="detail" color="text.muted">
                    {reason.getDescription(entityType)}
                  </Typography>
                </VStack>
                {selectedReason === reason.value && <CheckIcon color="inherit" />}
              </HStack>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export const OptOutModal = ({
  isOpen,
  onClose,
  reportingUnitId,
  metric,
  isCompany,
  assessmentId,
}: {
  isOpen: boolean;
  onClose: () => void;
  reportingUnitId: string;
  metric?: SelectedMetric;
  assessmentId: string;
  isCompany: boolean;
}) => {
  const [reasoning, setReasoning] = useState<string>();
  const toast = useToast();
  const [optOut] = useOptOutMetricMutation();

  const [selectedReason, setSelectedReason] = useState<string>();

  const entityType = useMemo(() => (isCompany ? 'company' : 'business unit'), [isCompany]);

  useEffect(() => {
    if (selectedReason === OptOutReasonValue.OTHER) setReasoning('');
    else
      setReasoning(OPT_OUT_REASON_OPTIONS.find((reason) => selectedReason === reason.value)?.label);
  }, [selectedReason]);

  const allMetricRefs = useMemo(() => {
    const refs = [];
    const recurseChildren = (child: MaybeHasChild): void => {
      if (child.childrenMetrics?.length) {
        if (child.parentMetrics.length === 1) refs.push(child.reference);
        return child.childrenMetrics.forEach(
          (m) => m.childMetric && recurseChildren(m.childMetric)
        );
      }
      if (child.parentMetrics.length === 1) refs.push(child.reference);
      return;
    };
    if (metric) refs.push(metric.reference);
    if (metric?.childrenMetrics?.length)
      metric.childrenMetrics.forEach((m) => m.childMetric && recurseChildren(m.childMetric));
    return refs;
  }, [metric]);

  const handleSubmit = () => {
    optOut({
      variables: {
        objects: allMetricRefs.map((ref) => ({
          metricRef: ref,
          reportingUnitId,
          assessmentId,
          hasOptedOut: true,
          optOutReason: reasoning ?? '',
        })),
      },
      refetchQueries: [GetMetricAnswersDocument_, GetSingleEsrsMetricAnswerDocument_],
    })
      .then(() => {
        onClose();
        toast({ text: 'Opted out successfully' });
      })
      .catch(() => {
        toast({ text: 'Failed to opt out', variant: 'danger' });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Mark as irrelevant"
      confirmText="Confirm"
      confirmButtonProps={{ disabled: !reasoning }}
      onConfirm={handleSubmit}
    >
      <VStack spacing="16px" alignItems="start">
        <Typography variant="body">
          A metric could be irrelevant for instance if the information has already been disclosed in
          another part of the report or either if it doesn't apply to the business at all. We
          recommend limited use of the “Mark as irrelevant” functionality since the general rule is
          that data should be disclosed for all material metrics.
        </Typography>
        <VStack spacing="16px" width="100%" alignItems="start">
          <VStack spacing="6px" w="100%" alignItems="start">
            <Typography variant="bodyStrong" as="span">
              Select a reason{' '}
              <Typography variant="bodyStrong" as="span" color="text.critical">
                *
              </Typography>
            </Typography>
            <ReasonSelectorMenu
              selectedReason={selectedReason ?? ''}
              setSelectedReason={setSelectedReason}
              reasons={OPT_OUT_REASON_OPTIONS}
              entityType={entityType}
            />
          </VStack>

          {selectedReason === OptOutReasonValue.OTHER && (
            <VStack spacing="6px" w="100%" alignItems="start">
              <Typography variant="bodyStrong" as="span">
                Provide reasoning{' '}
                <Typography variant="bodyStrong" as="span" color="text.critical">
                  *
                </Typography>
              </Typography>
              <Textarea
                value={reasoning}
                onChange={(e) => setReasoning(e.target.value)}
                borderRadius="8px"
                borderColor="border.default"
                placeholder="Enter your text"
                p="8px"
              />
            </VStack>
          )}
        </VStack>
      </VStack>
    </Modal>
  );
};
