import { Button, Tag, TruncatableText } from 'Atoms';
import { Typography } from 'Tokens';
import { CompanyAssessmentDetails, Investor, InvestorFieldsFragment_ } from 'models';
import { Menu } from 'Molecules/Menu';
import { Modal, ModalProps, Table } from 'Molecules';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { HStack, VStack, Divider, MenuButton, useDisclosure, Box } from '@chakra-ui/react';
import { useTranslation } from 'utils/translation';
import { PrivateIcon, PublicIcon } from 'Tokens/Icons/Function';
import { CompanyIcon } from 'Tokens/Icons/Data';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { CheckIcon } from 'Tokens/Icons/Status';
import { SquareIconWrapper } from 'Tokens/Icons/SquareIconWrapper';
import { AccessStatusType } from './Assessment';
import { uniqBy, sortBy } from 'lodash';
import { ColumnDef } from '@tanstack/react-table';
import { formatQuarterYear } from 'utils/date';

export const ShareAssessmentModal = ({
  isOpen,
  onClose,
  investors,
  cAssessment,
  handleSaveChanges,
  assessmentPeriod,
  reportingPeriod,
}: {
  investors: Investor[];
  cAssessment?: CompanyAssessmentDetails;
  assessmentPeriod: string;
  handleSaveChanges: (param: AccessStatusType[]) => void;
  reportingPeriod?: string;
} & Pick<ModalProps, 'isOpen' | 'onClose'>) => {
  const { t } = useTranslation(['assessment', 'common']);

  const [status, setStatus] = useState<AccessStatusType[]>([]);
  const [selectedYears, setSelectedYears] = useState<{ [portfolioId: string]: number }>({});
  const [currInvestor, setCurrInvestor] = useState(investors[0]);

  const { isOpen: replaceOpen, onClose: replaceClose, onOpen: openReplaceModal } = useDisclosure();

  const uniqueInvestors = useMemo(() => {
    const filteredInvestors = investors.filter(
      (inv) => formatQuarterYear(inv.quarter, inv.year) === reportingPeriod
    );
    return uniqBy(
      sortBy(filteredInvestors, (investor) => investor.sharedAssessment),
      'portfolioId'
    );
  }, [investors, reportingPeriod]);

  useEffect(() => {
    const allPortfolioCompanies = uniqueInvestors.map((investor) => ({
      portfolioCompanyId: investor.id,
      assessmentId: investor.sharedCompanyAssessmentId,
      year:
        investor?.year ??
        Math.max(
          ...(uniqueInvestors
            .filter((i) => i.portfolioId === investor.portfolioId && !!i?.year)
            .map((i) => i.year as number) ?? [])
        ),
      quarter: investor.quarter,
      portfolioId: investor.portfolioId,
    }));
    const initialSelectedYears = uniqueInvestors.reduce(
      (acc, investor) => {
        acc[investor.portfolioId] = Math.max(
          ...(uniqueInvestors
            .filter((i) => i.portfolioId === investor.portfolioId && !!i?.year)
            .map((i) => i?.year as number) ?? [])
        );
        return acc;
      },
      {} as { [portfolioId: string]: number }
    );
    setStatus(allPortfolioCompanies);
    setSelectedYears(initialSelectedYears);
  }, [isOpen, uniqueInvestors]);

  const handleChangeAccessStatus = useCallback(
    (assessmentToAdd: string | null, portfolioId?: string) => {
      const portfolioIdToChange = portfolioId ?? currInvestor.portfolioId;
      return setStatus((curStatuses) => [
        ...curStatuses.map((cur) => {
          if (
            cur.portfolioId === portfolioIdToChange &&
            formatQuarterYear(cur.quarter, cur.year) === reportingPeriod
          ) {
            return {
              ...cur,
              assessmentId: assessmentToAdd,
            };
          }
          return cur;
        }),
      ]);
    },
    [cAssessment, setStatus, status, selectedYears, currInvestor, reportingPeriod]
  );

  const columns: ColumnDef<InvestorFieldsFragment_>[] = [
    {
      header: t('assessment:sharedModal.investor'),
      cell: ({ row }) => {
        return (
          <HStack width="250px">
            <SquareIconWrapper icon={CompanyIcon} size="22px" color="white" />
            <TruncatableText
              variant="bodyStrong"
              text={row?.original?.portfolio?.ownerCompany?.name ?? t('common:unknown')}
            />
          </HStack>
        );
      },
    },
    {
      header: t('assessment:sharedModal.fundName'),
      cell: ({ row }) => {
        return (
          <Box width="250px">
            <Tag>
              <TruncatableText
                variant="bodyStrong"
                text={row?.original?.portfolio?.name ?? 'N/A'}
              />
            </Tag>
          </Box>
        );
      },
    },
    {
      header: t('assessment:sharedModal.accessSettings'),
      cell: ({ row }) => {
        return (
          <HStack w="150px">
            <Menu
              size="lg"
              menuButton={
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon color="inherit" />}
                  size="sm"
                  variant="ghost"
                  aria-label="share"
                >
                  <Typography variant="bodyStrong" color="text.muted">
                    {status.find(
                      (s) =>
                        s.portfolioId === row?.original?.portfolioId &&
                        formatQuarterYear(s.quarter, s.year) === reportingPeriod
                    )?.assessmentId === cAssessment?.id
                      ? t('assessment:sharedModal.access')
                      : t('assessment:sharedModal.noAccess')}
                  </Typography>
                </MenuButton>
              }
              sections={[
                {
                  actions: [
                    {
                      id: 'no access',
                      title: t('assessment:sharedModal.noAccess'),
                      variant:
                        status.find(
                          (s) =>
                            s.portfolioId === row?.original?.portfolioId &&
                            formatQuarterYear(s.quarter, s.year) === reportingPeriod
                        )?.assessmentId === cAssessment?.id
                          ? 'ghost'
                          : 'selected',
                      leftElement: <PrivateIcon color="inherit" />,
                      description: t('assessment:sharedModal.noAccessDescription'),
                      rightElement:
                        status.find(
                          (s) =>
                            s.portfolioId === row?.original?.portfolioId &&
                            formatQuarterYear(s.quarter, s.year) === reportingPeriod
                        )?.assessmentId === cAssessment?.id ? (
                          <></>
                        ) : (
                          <CheckIcon color="text.selected" />
                        ),
                      onClick: () => {
                        setCurrInvestor(row?.original);
                        handleChangeAccessStatus(null, row?.original?.portfolioId);
                      },
                    },
                    {
                      id: 'access',
                      title: t('assessment:sharedModal.access'),
                      variant:
                        status.find(
                          (s) =>
                            s.portfolioId === row?.original?.portfolioId &&
                            formatQuarterYear(s.quarter, s.year) === reportingPeriod
                        )?.assessmentId === cAssessment?.id
                          ? 'selected'
                          : 'ghost',
                      leftElement: <PublicIcon color="inherit" />,
                      description: t('assessment:sharedModal.accessDescription'),
                      rightElement:
                        status.find(
                          (s) =>
                            s.portfolioId === row?.original?.portfolioId &&
                            formatQuarterYear(s.quarter, s.year) === reportingPeriod
                        )?.assessmentId === cAssessment?.id ? (
                          <CheckIcon color="text.selected" />
                        ) : (
                          <></>
                        ),
                      onClick: () => {
                        const selectedInvestorPerIdAndYear = investors.find(
                          (inv) =>
                            inv.portfolioId === row?.original?.portfolioId &&
                            formatQuarterYear(inv.quarter, inv.year) === reportingPeriod
                        );
                        if (cAssessment?.id && selectedInvestorPerIdAndYear) {
                          setCurrInvestor(selectedInvestorPerIdAndYear);
                          const anyShared = status.find(
                            (inv) =>
                              inv.portfolioId === row?.original?.portfolioId &&
                              inv.year === selectedYears[row?.original?.portfolioId]
                          );
                          if (
                            anyShared?.assessmentId !== cAssessment?.id &&
                            anyShared?.assessmentId !== null
                          ) {
                            openReplaceModal();
                          } else {
                            handleChangeAccessStatus(cAssessment?.id, row?.original?.portfolioId);
                          }
                        }
                      },
                    },
                  ],
                },
              ]}
            />
          </HStack>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        isOpen={isOpen}
        hasFooter={!uniqueInvestors.length ? false : true}
        onClose={onClose}
        onConfirm={() => handleSaveChanges(status)}
        size={!!uniqueInvestors.length ? 'xl' : 'sm'}
        title={!!uniqueInvestors.length ? t('assessment:shareAccess') : t('assessment:notShared')}
        subtitle={
          !!uniqueInvestors.length
            ? `${cAssessment?.aggregate.title} - ${assessmentPeriod}`
            : undefined
        }
      >
        {!!uniqueInvestors.length ? (
          <>
            <Divider borderColor="border.decorative" orientation="horizontal" />
            <Table columns={columns} data={uniqueInvestors} />
          </>
        ) : (
          <Typography>
            {t('assessment:periodNotRequested', {
              assessmentPeriod: assessmentPeriod,
            })}
          </Typography>
        )}
      </Modal>
      <Modal
        isOpen={replaceOpen}
        onClose={replaceClose}
        onConfirm={() => {
          handleChangeAccessStatus(cAssessment?.id ?? null);
          replaceClose();
        }}
        size="sm"
        title="Conflict access"
        confirmText="Replace"
      >
        <VStack>
          <>
            <Typography>
              {t('assessment:assessmentAlreadyShared', {
                sharedAssessment: currInvestor?.sharedAssessment?.aggregate.title,
                currentAssessment: cAssessment?.aggregate.title,
              })}
            </Typography>
          </>
        </VStack>
      </Modal>
    </>
  );
};
