import { VStack, Box, HStack, useDisclosure, Switch } from '@chakra-ui/react';
import { Button, Infobox } from 'Atoms';
import { ContentLayout, ContentHeader, LearnMoreDrawer, Loader } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { useCompanyType, useCurrentCompany } from 'utils/hooks';
import { useAddTarget, useGetEsrsSubsidiaries, useGetTargetData } from './AddTargets.hooks';
import { MilestoneFields, TargetFields, TargetFieldsEnum } from '../../Requirement';
import { StepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { AddIcon, EditIcon } from 'Tokens/Icons/Function';
import { BaseLineModal } from './BaseLineModal';
import { useUserData } from '@nhost/react';
import { TargetActionsSection } from './TargetActionsSection';
import { Milestones } from './Milestones';
import { TargetGraph } from './TargetGraph';
import _ from 'lodash';
import { mapUnitToCompanyCurrency } from 'containers/Esrs/utils';
import { TargetDocumentation } from './TargetDocumentation';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { DocumentationFileDetailsFragment_, useUserCompaniesQuery } from 'models';
import { Baseline, TargetSectionWrapper } from './AddTargetUtils';
import { DRQuestionsTable } from '../../DRQuestionsTable';
import { MDR, MDRControllerType } from 'containers/Esrs';
import { UserAvatar } from 'Organisms';
import { AddTargetModal } from './AddTargetModal';
import { YearSelector } from 'Molecules/YearSelector';
import { MilestoneOverview } from './MilestoneOverview';
import { getTargetInfoBoxText } from './TextUtils';
import { CheckIcon, LoaderIcon, WarningIcon } from 'Tokens/Icons/Status';

type LearnMoreData = { title: string; description?: string } | undefined;

export const AddTarget = () => {
  const {
    disclosureRequirementRef = '',
    esrsAssessmentId = '',
    standardRef = '',
    targetId,
  } = useParams();
  const {
    isOpen: isBaselineOpen,
    onOpen: onBaselineOpen,
    onClose: onBaselineClose,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isTargetOpen, onOpen: onTargetOpen, onClose: onTargetClose } = useDisclosure();
  const {
    isOpen: isMilestoneOpen,
    onOpen: onMilestoneOpen,
    onClose: onMilestoneClose,
  } = useDisclosure();

  const { handleSubmit, reset, control, watch, setValue } = useForm<TargetFields>();
  const { isValid, isDirty } = useFormState({ control });
  const localAnswers = watch();

  const addTarget = useAddTarget();
  const { company } = useCurrentCompany();
  const { isGroup, loading: typeLoading } = useCompanyType();
  const user = useUserData();
  const navigate = useNavigate();
  const location = useLocation();

  const [learnMore, setLearnMore] = useState<LearnMoreData>();
  const [selectedMilestone, setSelectedMilestone] = useState<MilestoneFields>();
  const [milestoneYears, setMilestoneYears] = useState<number[]>([]);
  const [note, setNote] = useState<string | null>();
  const [filesAttached, setFilesAttached] = useState<DocumentationFileDetailsFragment_[]>();
  const [initialLoad, setInitialLoad] = useState(true);
  const [isSaved, setIsSaved] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  const {
    metrics,
    materialStandardId,
    parentStandardId,
    targetData,
    reportingUnits,
    subsidiaries,
    actionsData,
    parentActionsData,
    actionRef,
    loading,
    initialLocalAnswers,
    allSubsidiariesIds,
    targetQuestions,
    subsidiariesTargets,
    parentMilestoneYears,
    assessmentYear,
  } = useGetTargetData(disclosureRequirementRef, esrsAssessmentId, standardRef, targetId, isGroup);

  useEffect(() => {
    if (initialLocalAnswers && initialLoad) {
      reset({ ...initialLocalAnswers, ownerId: initialLocalAnswers.owner?.id ?? user?.id });
      setTimeout(() => setInitialLoad(false), 1500);
    } else if (isSaved) {
      reset(
        { ...initialLocalAnswers, ownerId: initialLocalAnswers.owner?.id ?? user?.id },
        {
          keepDirty: false,
        }
      );
      setIsSaveDisabled(false);
    } else if (isGroup) {
      setValue('isBottomUp', initialLocalAnswers.isBottomUp);
      setValue('name', initialLocalAnswers.name);
    }
  }, [initialLocalAnswers, initialLoad, isGroup, isSaved]);

  useEffect(() => {
    setMilestoneYears(initialLocalAnswers.milestones.map((m) => m.year));
  }, [initialLocalAnswers]);

  useEffect(() => {
    if (isDirty) setIsSaved(false);
  }, [isDirty]);

  const unit: string = useMemo(() => {
    const metric = metrics?.metrics?.find((m) => m.reference === localAnswers.metricRef);
    return mapUnitToCompanyCurrency(metric?.unitOfMeasurement ?? 't', company?.currency ?? '');
  }, [metrics, localAnswers]);

  const requiredReportingUnits = useMemo(
    () => reportingUnits.filter((ru) => !ru.isCompanyLevel) ?? [],
    [reportingUnits]
  );

  const relatedParentActions = useMemo(
    () =>
      parentActionsData?.filter((action) =>
        action.actionSubsidiaries?.some(
          (subsidiary) => subsidiary.subsidiaryAssessment.id === esrsAssessmentId
        )
      ) ?? [],
    [parentActionsData, esrsAssessmentId]
  );

  const showInfoBox = useMemo(
    () => isGroup || (!isGroup && !!requiredReportingUnits.length),
    [isGroup, requiredReportingUnits]
  );

  const isNoLongerRequired = useMemo(
    () => targetData?.parentTargetId === null && targetData.isRequired && !isGroup,
    [targetData, isGroup]
  );

  const isFlagged = useMemo(() => {
    return !!localAnswers?.isFlagged && !isGroup && !!targetData?.parentTargetId;
  }, [targetData, isGroup, localAnswers]);

  const data = useGetEsrsSubsidiaries({ assessmentId: esrsAssessmentId });
  const { data: userCompaniesData } = useUserCompaniesQuery({
    variables: {
      id: user?.id ?? '',
    },
  });
  const subsidaries = useMemo(() => {
    return data.data?.esrs?.subsidiaries.map((sub) => sub.company.id);
  }, [data]);
  const userCompanies = useMemo(
    () => userCompaniesData?.data?.companies.map((cp) => cp.company.id),
    [userCompaniesData]
  );

  const hasAccessToAllSubs = useMemo(
    () => subsidaries?.every((sub) => userCompanies?.includes(sub)) ?? false,
    [subsidaries]
  );

  const targetToEdit = useMemo(
    () => ({
      id: targetId ?? '',
      metricRef: watch('metricRef') ?? '',
      metricTitle: metrics?.metrics.find((m) => m.reference === watch('metricRef'))?.title ?? '',
      ownerId: targetData?.owner?.id ?? user?.id,
      name: targetData?.name ?? '',
      isBottomUp: localAnswers?.isBottomUp,
      parentTargetId: targetData?.parentTargetId,
      parentName: targetData?.parentTarget?.materialStandard.esrsAssessment.company.name,
      keyResults: targetData?.keyResults,
    }),
    [targetId, metrics, targetData, localAnswers, watch]
  );

  const handleMilestoneYears = () => {
    const removedYears = localAnswers.milestones
      ?.filter((milestone) => !milestoneYears?.includes(milestone.year))
      ?.map((m) => m.year);
    const addedYears = milestoneYears?.filter(
      (year) => !localAnswers.milestones?.some((y) => y.year === year)
    );
    setValue(
      'milestones',
      [
        ...(localAnswers?.milestones?.filter((m) => !removedYears?.includes(m.year)) ?? []),
        ...(addedYears?.map((year) => ({
          year: year,
          isRelative: true,
          value: undefined,
          subsidiaries: [],
          reportingUnits: [],
          isRequired: false,
        })) ?? []),
      ],
      { shouldDirty: true }
    );
  };

  const onSubmit = (fields: TargetFields, e?: React.BaseSyntheticEvent<any> | undefined) => {
    if (e?.target.id === 'target-form') {
      setIsSaveDisabled(true);
      addTarget(
        fields,
        targetData,
        disclosureRequirementRef,
        materialStandardId,
        requiredReportingUnits,
        subsidiaries,
        standardRef,
        targetId,
        isGroup,
        setIsSaved,
        setIsSaveDisabled,
        user?.id,
        note,
        filesAttached,
        setNote,
        setFilesAttached
      );
    }
  };

  if (loading || typeLoading) return <Loader />;

  return (
    <Box>
      <ContentLayout
        backButton
        onBackNavigate={() => navigate(location.pathname.split('target-form')[0])}
        variant="narrow"
        paddingBottom="0px !important"
        header={
          <ContentHeader
            title={targetData?.name ? `${targetData?.name}` : 'Add target'}
            subtitle={`Target related to ${targetData?.metric?.title}`}
            actions={
              <HStack spacing="12px">
                <UserAvatar user={targetData?.owner ?? watch('owner')} />
                <Box>
                  <Typography variant="h4">{targetData?.owner?.displayName}</Typography>
                  <Typography variant="body">Owner</Typography>
                </Box>
              </HStack>
            }
          />
        }
        actions={
          <Box>
            <Button variant="ghost" leftIcon={<EditIcon />} onClick={onTargetOpen}>
              Edit target
            </Button>
          </Box>
        }
      >
        <form id="target-form" onSubmit={handleSubmit(onSubmit)}>
          <VStack alignItems="flex-start" w="100%" flexGrow="1" spacing="0px">
            {isNoLongerRequired && (
              <Infobox
                status="warning"
                title="No longer requested by a parent company"
                description={`This target was created by the parent company users before and was deleted after you input some information. This means the parent company no longer needs you to fill in information here, and you are free to delete the whole target or any information irrelevant to ${company?.name}'s own reporting.`}
                closable={false}
                m="16px 0px 8px"
              />
            )}
            {!!targetData?.parentTargetId && (
              <Infobox
                status="info"
                title="Requested by a parent company"
                description={`The parent company has set a target ${targetData.parentTarget?.name}, and requested information in this target. This means you need to add baseline and milestone values requested by the parent, but you are also free to add any other information relevant to ${targetData.parentTarget?.materialStandard.esrsAssessment.company.name}'s own reporting.`}
                closable={false}
                m="16px 0px 8px"
              />
            )}
            <VStack
              alignItems="start"
              spacing="16px"
              w="100%"
              h="220px"
              border="1px solid"
              borderColor="border.decorative"
              padding="16px"
              borderRadius="8px"
              mt="16px"
              mb="40px"
            >
              <TargetGraph
                localAnswers={localAnswers}
                baseline={Number(localAnswers.baseline)}
                actions={actionsData ?? []}
                parentActions={relatedParentActions}
                targetId={targetId ?? ''}
                parentTargetId={targetData?.parentTargetId}
              />
            </VStack>
            <VStack alignItems="flex-start" maxW="800px" pl="0px" ml="16px" mb="32px" spacing="0px">
              <TargetSectionWrapper
                title="Baseline and milestones"
                description="Set this target’s baseline and milestones"
              >
                <VStack alignItems="start" spacing="20px" w="100%">
                  {showInfoBox && (
                    <Infobox
                      status="neutral"
                      title={
                        !isGroup
                          ? 'Do you want to disaggregate values by business units?'
                          : undefined
                      }
                      description={getTargetInfoBoxText({
                        isGroup,
                        isBottomUp: localAnswers.isBottomUp,
                        companyName: company?.name ?? '',
                      })}
                      closable={false}
                      withIcon={false}
                      extra={
                        !isGroup ? (
                          <HStack>
                            <Controller
                              name={TargetFieldsEnum.isBottomUp}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <Switch isChecked={value} onChange={onChange} />
                              )}
                            />
                            <Typography color="text.muted" variant="body">
                              Disaggregate
                            </Typography>
                          </HStack>
                        ) : undefined
                      }
                    />
                  )}
                  {isFlagged && (
                    <Infobox
                      status="warning"
                      closable={false}
                      title="Review a target updates"
                      description="The parent company updated a baseline year for this target. Please review and update the baseline and milestones values requested by a parent to make sure a correct reporting."
                    />
                  )}
                  <TargetSectionWrapper
                    title="Baseline"
                    subSection={true}
                    learnMore={{
                      text: '80. (d) the baseline value and base year from which progress is measured.',
                      setLearnMore: setLearnMore,
                      onOpen: onOpen,
                    }}
                  >
                    <Baseline
                      localAnswers={localAnswers}
                      onBaselineOpen={onBaselineOpen}
                      setValue={setValue}
                      unit={unit}
                      subsidiariesTargets={subsidiariesTargets}
                      isGroup={isGroup}
                      requiredLength={
                        isGroup ? subsidiariesTargets.length : requiredReportingUnits.length
                      }
                      isSubsidiaryTarget={!isGroup && targetData?.parentTarget !== null}
                      reportingUnits={requiredReportingUnits}
                      assessmentYear={assessmentYear}
                      isFlagged={isFlagged}
                    />
                  </TargetSectionWrapper>
                  <TargetSectionWrapper
                    title="Milestones and final target"
                    subSection={true}
                    learnMore={{
                      text: '',
                      setLearnMore: setLearnMore,
                      onOpen: onOpen,
                    }}
                  >
                    {localAnswers.milestones?.length && (
                      <MilestoneOverview
                        unit={unit}
                        setSelectedMilestone={setSelectedMilestone}
                        onMilestoneOpen={onMilestoneOpen}
                        localAnswers={localAnswers}
                        requiredLength={
                          isGroup ? subsidiariesTargets.length : requiredReportingUnits.length
                        }
                        isGroup={isGroup}
                        subsidiariesTargets={subsidiariesTargets}
                        reportingUnits={requiredReportingUnits}
                        isFlagged={isFlagged}
                        parentMilestoneYears={parentMilestoneYears}
                      />
                    )}
                    {!localAnswers.milestones?.length && targetData?.parentTargetId !== null && (
                      <Typography color="text.muted" variant="body">
                        Milestone values are initially set by the parent company. Additionally, you
                        have the option to set your own milestones if you wish.
                      </Typography>
                    )}
                    <YearSelector
                      value={milestoneYears}
                      setValue={setMilestoneYears}
                      buttonText="Add milestones"
                      buttonLeftIcon={<AddIcon />}
                      confirmButtonText="Add"
                      disabledAddedValues={localAnswers.milestones?.map((m) => m.year) ?? []}
                      onConfirm={handleMilestoneYears}
                      allowMultiple
                      title="Select milestone years"
                    />
                  </TargetSectionWrapper>
                </VStack>
              </TargetSectionWrapper>
              <TargetSectionWrapper
                title="Actions overview"
                description="Here you can see an overview of all actions related to this target"
              >
                <Controller
                  name={TargetFieldsEnum.actions}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TargetActionsSection
                      actions={actionsData ?? []}
                      parentActions={targetData?.parentTargetId ? parentActionsData ?? [] : []}
                      value={value}
                      onChange={onChange}
                      watchedActions={localAnswers.actions}
                      actionRef={actionRef ?? ''}
                      reportingUnits={reportingUnits}
                      targetId={targetId}
                      isGroup={isGroup}
                      allSubsidiariesIds={allSubsidiariesIds}
                      unit={unit}
                      targetName={localAnswers.name}
                    />
                  )}
                />
              </TargetSectionWrapper>
              <TargetSectionWrapper title="Minimum disclosure requirement - Targets">
                <MDR control={control as MDRControllerType} unit={unit} type="target" />
              </TargetSectionWrapper>
              {!!targetQuestions?.length && (
                <TargetSectionWrapper title="Other disclosure requirements">
                  <DRQuestionsTable
                    questions={targetQuestions ?? []}
                    answers={watch(TargetFieldsEnum.answers)}
                    setAnswers={(val) =>
                      setValue(TargetFieldsEnum.answers, val, { shouldDirty: true })
                    }
                  />
                </TargetSectionWrapper>
              )}
              <Box position="relative" pl="32px" w="100%">
                <StepLabels
                  title="Provide documentation"
                  description="Document anything for internal or audit purposes"
                  ml="33px"
                />
                <TargetDocumentation
                  targetId={targetId}
                  user={user}
                  filesAttached={filesAttached}
                  setNote={setNote}
                  setFilesAttached={setFilesAttached}
                />
              </Box>
            </VStack>
            <BaseLineModal
              isOpen={isBaselineOpen}
              onClose={onBaselineClose}
              isGroup={isGroup}
              subsidiariesTargets={subsidiariesTargets}
              reportingUnits={requiredReportingUnits}
              control={control}
              unit={unit}
              watch={watch}
              setValue={setValue}
              companyName={company?.name ?? ''}
              parentTargetId={targetData?.parentTargetId}
              targetData={targetData}
              localAnswers={localAnswers}
              isFlagged={isFlagged}
            />
          </VStack>
        </form>
        <AddTargetModal
          isOpen={isTargetOpen}
          onClose={onTargetClose}
          materialStandardId={materialStandardId}
          parentStandardId={parentStandardId}
          disclosureRequirementRef={disclosureRequirementRef}
          standardRef={standardRef}
          hasAccessToAllSubs={hasAccessToAllSubs}
          subsidiaries={subsidiaries}
          targetToEdit={targetToEdit}
          companyName={company?.name}
          isGroup={isGroup}
        />
        <Milestones
          isOpen={isMilestoneOpen}
          onClose={onMilestoneClose}
          isGroup={isGroup}
          selectedMilestone={selectedMilestone}
          companyName={company?.name ?? ''}
          reportingUnits={requiredReportingUnits}
          watch={watch}
          unit={unit}
          setValue={setValue}
          subsidiariesTargets={subsidiariesTargets}
          hideDeleteButton={parentMilestoneYears.includes(selectedMilestone?.year ?? 0)}
          targetData={targetData}
          setMilestoneYears={setMilestoneYears}
        />
        <LearnMoreDrawer
          header={learnMore?.title}
          isOpen={isOpen}
          onClose={onClose}
          description={learnMore?.description ?? ''}
        />
      </ContentLayout>
      <VStack
        position="sticky"
        bottom="0"
        left="0"
        right="0"
        w="100%"
        bg="bg.default"
        border="1px solid"
        borderColor="border.decorative"
        padding="12px 16px"
        alignItems="center"
        ml="-15px"
      >
        <HStack maxWidth="840px" minWidth="800px" justifyContent="space-between">
          <Box>
            {isSaveDisabled ? (
              <HStack spacing="6px">
                <LoaderIcon color="text.muted" />
                <Typography variant="body" color="text.muted">
                  Saving
                </Typography>
              </HStack>
            ) : isSaved && !isDirty ? (
              <HStack spacing="6px">
                <CheckIcon color="text.muted" />
                <Typography variant="body" color="text.muted">
                  All changes saved
                </Typography>
              </HStack>
            ) : (
              isDirty && (
                <HStack spacing="6px">
                  <WarningIcon color="text.warning" />
                  <Typography variant="body" color="text.warning">
                    Changes are not saved. Save target before leaving the page.
                  </Typography>
                </HStack>
              )
            )}
          </Box>
          <Button
            variant="primary"
            type="submit"
            isDisabled={!isValid || !isDirty || isSaveDisabled}
            ml="42px"
            form="target-form"
          >
            Save target
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
