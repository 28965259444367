import {
  useGetCompanyLevelDrProgressQuery,
  useGetGroupLevelDrProgressQuery,
  GetGroupLevelDrProgressQuery_,
  QuestionType_Enum_,
  GetCompanyLevelDrProgressQuery_,
  useGetCompanyLevelStandardProgressQuery,
  useGetGroupLevelStandardProgressQuery,
  useGetReportingUnitDrProgressQuery,
  useGetReportingUnitStandardProgressQuery,
  useGetGroupLevelCategoryProgressQuery,
  useGetCompanyLevelCategoryProgressQuery,
  useGetCompanyLevelProgressQuery,
  useGetGroupLevelProgressQuery,
} from 'models';
import { useMemo } from 'react';
import {
  combinedFiltersForNoTags,
  combinedFiltersForTags,
  FrequencyEnums,
  getTagsCombination,
  getUniqueDataPoints,
  TimePeriodsEnums,
} from '../../DisclosureRequirements';
import { BooleanEnum } from 'Molecules';
import { DataCollectionLevel } from '../../DataCollection';
import { GetReportingUnitDrProgressQuery_ } from '../../../../../../backend/functions/_utils/__generated__/graphql';
import { uniq } from 'lodash';

type MaterialMetrics = GetGroupLevelDrProgressQuery_['groupLevelMaterialMetrics'];
type Answers = GetGroupLevelDrProgressQuery_['groupLevelAnswers'];
type DataPoints = Answers[number]['datapoints'];

const cleanUpProgressResult = (percentage: number) => {
  if (Number.isNaN(percentage)) return '0';
  if (percentage > 100) return '100';
  if (Number.isInteger(percentage)) {
    return percentage.toString(); // No decimals needed, convert directly to string
  } else {
    return percentage.toFixed(0);
  }
};

const getIsParentLocked = (
  materialMetric: MaterialMetrics[number],
  groupLevelAnswers: Answers | undefined
) => {
  const parentBooleanMetric = materialMetric.metric.parentMetrics.find(
    (pm) =>
      pm.parentMetric.metricType === QuestionType_Enum_.YesNo_ && pm.parentMetric.unlockCondition
  )?.parentMetric;
  if (!parentBooleanMetric) return false;
  const parentBooleanAnswer = groupLevelAnswers?.find(
    (answer) => answer.metricRef === parentBooleanMetric?.reference
  );
  const factValue = parentBooleanAnswer?.datapoints.find(
    (dp) => dp.timeframe === TimePeriodsEnums.year
  )?.factValue;
  const unlockCondition = parentBooleanMetric?.unlockCondition;
  const unlockTerm =
    unlockCondition === null ? '' : unlockCondition ? BooleanEnum.True : BooleanEnum.False;
  const isParentLocked = unlockCondition !== null && factValue !== unlockTerm;
  return isParentLocked;
};

const getMetricTags = (
  materialMetric: MaterialMetrics[number],
  parentMetrics: MaterialMetrics[number][]
) => {
  const hasTags = !!materialMetric?.materialMetricTags?.length;
  const parentsWithTags = parentMetrics.filter(
    (parentMetric) => !!parentMetric?.materialMetricTags?.length
  );
  const parentHasTags = !!parentsWithTags.length;
  const isAssessable = materialMetric.metric.isAssessable;

  const parentTags = parentHasTags
    ? parentsWithTags.flatMap(
        (parentMetric) => getTagsCombination(parentMetric?.materialMetricTags)?.allTagsArray
      )
    : [];
  const metricTags = hasTags
    ? getTagsCombination(materialMetric?.materialMetricTags)?.allTagsArray
    : [];

  if (parentHasTags && !isAssessable) {
    return { tags: parentTags, hasTags: true };
  }
  if (parentHasTags && isAssessable && hasTags) {
    return { tags: [...parentTags, ...metricTags], hasTags: true };
  }
  return { tags: metricTags, hasTags: hasTags };
};

const filterCorrectDatapoints = (
  answer: Answers[number] | undefined,
  materialMetric: MaterialMetrics[number],
  parentMetrics: MaterialMetrics[number][]
) => {
  if (!answer || answer?.datapoints?.length === 0) return undefined;

  // narrative metric
  const isNarrative = materialMetric?.metric?.metricType !== QuestionType_Enum_.Decimal_;
  const narrativeDatapoint = answer?.datapoints.find(
    (dp) => dp.timeframe === TimePeriodsEnums.year
  );
  if (isNarrative) return !!narrativeDatapoint ? [narrativeDatapoint] : [];

  const isYearly = materialMetric?.frequency === FrequencyEnums.yearly;
  const { tags, hasTags } = getMetricTags(materialMetric, parentMetrics);

  if (hasTags)
    return answer?.datapoints?.filter((datapoint) =>
      combinedFiltersForTags(datapoint, isYearly, tags)
    );

  return answer?.datapoints?.filter((datapoint) => combinedFiltersForNoTags(datapoint, isYearly));
};

const getRequiredDPsNumber = (
  materialMetric: GetGroupLevelDrProgressQuery_['groupLevelMaterialMetrics'][number],
  parentMetrics: GetGroupLevelDrProgressQuery_['groupLevelMaterialMetrics'][number][]
) => {
  const isYearly = materialMetric?.frequency === FrequencyEnums.yearly;
  const { tags, hasTags } = getMetricTags(materialMetric, parentMetrics);
  const frequencyCount = isYearly ? 1 : 4;
  const tagsCount = hasTags ? tags?.length : 0;
  const total = frequencyCount * tagsCount;
  return total === 0 ? 1 : total;
};

const getTotalPercentage = (
  groupLevelPercentage: number,
  subsidiaryLevelPercentage: number,
  groupLevelMaterialMetrics: MaterialMetrics,
  subLevelMaterialMetrics: any[],
  count: number
) => {
  const groupSummaryMetrics =
    groupLevelMaterialMetrics.filter(
      (mm) =>
        (mm.dataCollection === DataCollectionLevel.subsidiaries ||
          mm.dataCollection === DataCollectionLevel.reportingUnits) &&
        mm.metric.metricType !== QuestionType_Enum_.Decimal_
    ) ?? [];
  const groupMetrics =
    groupLevelMaterialMetrics.filter(
      (mm) =>
        !(
          (mm.dataCollection === DataCollectionLevel.subsidiaries ||
            mm.dataCollection === DataCollectionLevel.reportingUnits) &&
          mm.metric.metricType !== QuestionType_Enum_.Decimal_
        )
    ) ?? [];
  const groupLevelCount = groupMetrics?.length + groupSummaryMetrics.length / count;

  const subNarrativeMetrics =
    subLevelMaterialMetrics.filter((mm) => mm.metric.metricType !== QuestionType_Enum_.Decimal_) ??
    [];
  const subNonNarrativeMetrics =
    subLevelMaterialMetrics.filter((mm) => mm.metric.metricType === QuestionType_Enum_.Decimal_) ??
    [];
  const subLevelCount =
    subNonNarrativeMetrics?.length + (subNarrativeMetrics.length * (count - 1)) / count;
  const totalPercentage =
    (groupLevelPercentage * groupLevelCount + subsidiaryLevelPercentage * subLevelCount) /
    (groupLevelCount + subLevelCount);
  return totalPercentage;
};

const getAnswersPercentagePerRU = (
  materialMetrics: GetGroupLevelDrProgressQuery_['groupLevelMaterialMetrics'] | undefined,
  answers: GetGroupLevelDrProgressQuery_['groupLevelAnswers'] | undefined
) => {
  if (!materialMetrics?.length) return 0;
  const metricsToDiscard: string[] = [];

  const metricAnswersPercentages =
    materialMetrics
      ?.map((mm) => {
        const isBoolean = mm.metric.metricType === QuestionType_Enum_.YesNo_;

        const isParentMetric = mm.metric.childrenMetrics_aggregate.aggregate?.count ?? 0 > 0;
        if (isParentMetric && !isBoolean) {
          metricsToDiscard.push(mm.metricRef);
          return undefined;
        }

        const parentMetrics =
          materialMetrics.filter((metric) =>
            mm.metric.parentMetrics.some((pm) => pm.parentMetric.reference === metric.metricRef)
          ) ?? [];

        const answer = answers?.find((answer) => answer.metricRef === mm.metricRef);
        const datapoints = filterCorrectDatapoints(answer, mm, parentMetrics)?.map((dp) => ({
          ...dp,
          metricRef: answer?.metricRef ?? '',
          tagValues: dp.datapointTags.map((tag) => tag.tagValue),
          answerId: answer?.id,
        }));
        const uniqueDatapoints: DataPoints = datapoints ? getUniqueDataPoints(datapoints) : [];

        //Choices case: choice + text should both be answered
        const isMultipleChoice =
          mm.metric.metricType === QuestionType_Enum_.MultipleChoice_ ||
          mm.metric.metricType === QuestionType_Enum_.SingleChoice_;
        const isMultipleChoiceDataPointAnswered =
          (uniqueDatapoints?.[0]?.datapointChoices_aggregate.aggregate?.count ?? 0) > 0 &&
          !!uniqueDatapoints?.[0]?.value;

        //Boolean case: yes/no + text should both be answered
        const isBooleanDataPointAnswered =
          !!uniqueDatapoints?.[0]?.factValue && !!uniqueDatapoints?.[0]?.value;

        //Locked boolean parent case:
        const isParentLocked = getIsParentLocked(mm, answers);
        if (isParentLocked) {
          metricsToDiscard.push(mm.metricRef);
          return undefined;
        }

        const datapointsCount = isBoolean
          ? isBooleanDataPointAnswered
            ? 1
            : 0
          : isMultipleChoice
            ? isMultipleChoiceDataPointAnswered
              ? 1
              : 0
            : uniqueDatapoints?.filter((dp) => dp.value !== null)?.length ?? 0;
        return (datapointsCount / getRequiredDPsNumber(mm, parentMetrics)) * 100;
      })
      ?.filter((n): n is number => n !== undefined) ?? [];

  const totalPercentages = metricAnswersPercentages.reduce((a, b) => a + b, 0);
  const requiredMaterialMetrics = materialMetrics?.filter(
    (mm) => !metricsToDiscard.includes(mm.metricRef)
  );
  const materialMetricsCount =
    requiredMaterialMetrics?.length === 0 ? 1 : requiredMaterialMetrics?.length;
  const percentage = totalPercentages / materialMetricsCount;
  return percentage;
};

const getGroupDRProgress = (data?: GetGroupLevelDrProgressQuery_) => {
  const groupLevelMaterialMetrics = data?.groupLevelMaterialMetrics;
  const groupLevelAnswers = data?.groupLevelAnswers;

  const groupLevelPercentage = getAnswersPercentagePerRU(
    groupLevelMaterialMetrics,
    groupLevelAnswers
  );

  const subLevelMaterialMetrics = data?.subsidiaryLevelMaterialMetrics;
  if (!subLevelMaterialMetrics?.length) return groupLevelPercentage;
  const subLevelAnswers = data?.subsidiaries?.subsidiaryAssessments.map((sub) => {
    const materialMetrics = sub.materialStandards?.[0]?.materialMetrics;
    const companyLevelRU = sub.reportingUnits.find((ru) => ru.isCompanyLevel);
    const reportingUnits = sub.reportingUnits.filter((ru) => !ru.isCompanyLevel);

    if (!materialMetrics?.length) return 0;

    const relevantMaterialMetrics = materialMetrics.filter(
      (mm) =>
        !!subLevelMaterialMetrics?.some((subLevelmm) => subLevelmm.metricRef === mm.metricRef) ||
        ((!!mm.materialMetricTags?.length &&
          mm.metric.childrenMetrics_aggregate.aggregate?.count) ??
          0 > 0)
    );

    const companyLevelMaterialMetrics = relevantMaterialMetrics.filter(
      (mm) =>
        mm.dataCollection === DataCollectionLevel.company ||
        mm.metric.metricType !== QuestionType_Enum_.Decimal_
    );
    const buLevelMaterialMetrics = relevantMaterialMetrics.filter(
      (mm) => mm.dataCollection === DataCollectionLevel.reportingUnits
    );

    const companyLevelPercentage = getAnswersPercentagePerRU(
      companyLevelMaterialMetrics,
      companyLevelRU?.answers
    );
    const reportingUnitsPercentages = reportingUnits?.map((ru) =>
      getAnswersPercentagePerRU(buLevelMaterialMetrics, ru.answers)
    );

    const reportingUnitCount = reportingUnits?.length === 0 ? 1 : reportingUnits?.length;
    const reportingUnitLevelPercentage =
      reportingUnitsPercentages.reduce((a, b) => a + b, 0) / reportingUnitCount;
    const allReportingUnitsCount = (reportingUnits?.length ?? 0) + 1;
    return getTotalPercentage(
      companyLevelPercentage,
      reportingUnitLevelPercentage,
      companyLevelMaterialMetrics ?? [],
      buLevelMaterialMetrics,
      allReportingUnitsCount
    );
  });

  const subsidiaryLevelPercentage =
    (subLevelAnswers?.reduce((a, b) => a + b, 0) ?? 0) /
    (data?.subsidiaries?.subsidiaryAssessments?.length ?? 1);
  const allCompaniesCount = (data?.subsidiaries?.subsidiaryAssessments.length ?? 0) + 1;

  const percentage = getTotalPercentage(
    groupLevelPercentage,
    subsidiaryLevelPercentage,
    groupLevelMaterialMetrics ?? [],
    subLevelMaterialMetrics,
    allCompaniesCount
  );
  return percentage;
};

const getRegularCompanyDRProgress = (data?: GetCompanyLevelDrProgressQuery_) => {
  const requiredParentMetrics = data?.requiredMaterialMetrics.map((m) => m.metricRef);

  const companyLevelMaterialMetrics = data?.companyLevelMaterialMetrics.filter(
    (mm) => mm.isMaterial || requiredParentMetrics?.includes(mm.metricRef)
  );
  const companyLevelAnswers = data?.companyLevelAnswers;

  const companyLevelPercentage = getAnswersPercentagePerRU(
    companyLevelMaterialMetrics,
    companyLevelAnswers
  );

  const buLevelMaterialMetrics = data?.buLevelMaterialMetrics.filter(
    (mm) => mm.isMaterial || requiredParentMetrics?.includes(mm.metricRef)
  );

  const reportingUnits = data?.reportingUnits ?? [];
  if (!buLevelMaterialMetrics?.length) return companyLevelPercentage;

  const reportingUnitsPercentages = reportingUnits?.map((ru) =>
    getAnswersPercentagePerRU(buLevelMaterialMetrics, ru.answers)
  );

  const reportingUnitCount = reportingUnits?.length === 0 ? 1 : reportingUnits?.length;
  const reportingUnitLevelPercentage =
    reportingUnitsPercentages.reduce((a, b) => a + b, 0) / reportingUnitCount;
  const allReportingUnitsCount = (reportingUnits?.length ?? 0) + 1;

  const progress = getTotalPercentage(
    companyLevelPercentage,
    reportingUnitLevelPercentage,
    companyLevelMaterialMetrics ?? [],
    buLevelMaterialMetrics,
    allReportingUnitsCount
  );
  return progress;
};

const getReportingUnitDRProgress = (data?: GetReportingUnitDrProgressQuery_) => {
  const requiredParentMetrics = data?.requiredMaterialMetrics.map((m) => m.metricRef);

  const materialMetrics =
    data?.materialMetrics.filter(
      (mm) => mm.isMaterial || requiredParentMetrics?.includes(mm.metricRef)
    ) ?? [];
  const progress = getAnswersPercentagePerRU(materialMetrics, data?.reportingUnit[0].answers);

  return progress;
};

export const useRegularCompanyDRProgress = ({
  drRef,
  assessmentId,
  standardRef,
  parentAssessmentId,
}: {
  drRef: string;
  assessmentId: string;
  standardRef: string;
  parentAssessmentId: string | undefined;
}) => {
  const { data: drProgressData, loading } = useGetCompanyLevelDrProgressQuery({
    variables: {
      drRef,
      assessmentId,
      standardRef,
      parentAssessmentId: parentAssessmentId || assessmentId,
    },
    skip: !drRef || !standardRef || !assessmentId,
  });

  const progress = useMemo(() => {
    return getRegularCompanyDRProgress(drProgressData);
  }, [drProgressData]);

  return {
    progress: cleanUpProgressResult(progress),
    loading: loading,
  };
};

export const useGroupDRProgress = ({
  drRef,
  assessmentId,
  standardRef,
}: {
  drRef: string;
  assessmentId: string;
  standardRef: string;
}) => {
  const { data: drProgressData, loading } = useGetGroupLevelDrProgressQuery({
    variables: {
      drRef: drRef,
      assessmentId: assessmentId,
      standardRef: standardRef,
    },
    skip: !drRef || !assessmentId || !standardRef,
  });

  const progress = useMemo(() => {
    return getGroupDRProgress(drProgressData);
  }, [drProgressData]);

  return {
    progress: cleanUpProgressResult(progress),
    loading: loading,
  };
};

export const useBusinessUnitDRProgress = ({
  drRef,
  assessmentId,
  standardRef,
  parentAssessmentId,
  reportingUnitId,
}: {
  drRef: string;
  assessmentId: string;
  standardRef: string;
  parentAssessmentId: string | undefined;
  reportingUnitId: string;
}) => {
  const { data: drProgressData, loading } = useGetReportingUnitDrProgressQuery({
    variables: {
      drRef,
      assessmentId,
      standardRef,
      reportingUnitId,
      parentAssessmentId: parentAssessmentId || assessmentId,
    },
    skip: !drRef || !standardRef || !assessmentId,
  });

  const progress = useMemo(() => {
    return getReportingUnitDRProgress(drProgressData);
  }, [drProgressData]);

  return {
    progress: cleanUpProgressResult(progress),
    loading: loading,
  };
};

export const useStandardProgress = ({
  standardRef,
  assessmentId,
  drRefs,
  parentAssessmentId,
  isGroup,
}: {
  standardRef: string;
  assessmentId: string;
  drRefs: string[];
  parentAssessmentId: string | undefined;
  isGroup: boolean;
}) => {
  const results = !isGroup
    ? useRegularCompanyStandardProgress({
        standardRef,
        assessmentId,
        parentAssessmentId,
        drRefs,
      })
    : useGroupStandardProgress({ standardRef, assessmentId, drRefs });

  return results;
};

export const useRegularCompanyStandardProgress = ({
  standardRef,
  assessmentId,
  drRefs,
  parentAssessmentId,
}: {
  standardRef: string;
  assessmentId: string;
  drRefs: string[];
  parentAssessmentId: string | undefined;
}) => {
  const { data, loading } = useGetCompanyLevelStandardProgressQuery({
    variables: {
      standardRef: standardRef,
      assessmentId: assessmentId,
      drRefs: drRefs,
      parentAssessmentId: parentAssessmentId || assessmentId,
    },
    skip: !standardRef || !assessmentId || !drRefs?.length,
  });

  const drsData = useMemo(
    () =>
      drRefs.map((dr) => {
        return {
          buLevelMaterialMetrics:
            data?.buLevelMaterialMetrics.filter(
              (bu) => bu.metric.disclosureRequirementRef === dr
            ) ?? [],
          companyLevelAnswers:
            data?.companyLevelAnswers.filter((ca) => ca.metric.disclosureRequirementRef === dr) ??
            [],
          companyLevelMaterialMetrics:
            data?.companyLevelMaterialMetrics.filter(
              (mm) => mm.metric.disclosureRequirementRef === dr
            ) ?? [],
          reportingUnits:
            data?.reportingUnits.map((ru) => ({
              ...ru,
              answers: ru.answers.filter((a) => a.metric.disclosureRequirementRef === dr) ?? [],
            })) ?? [],
          requiredMaterialMetrics:
            data?.requiredMaterialMetrics.filter(
              (mm) => mm.metric.disclosureRequirementRef === dr
            ) ?? [],
        };
      }),
    [drRefs, data]
  );

  const progress = useMemo(() => {
    const drsProgressArray = drsData.map((dr) => getRegularCompanyDRProgress(dr));
    const drsDataCount = !drsData?.length ? 1 : drsData?.length;
    const drsProgressTotal = drsProgressArray.reduce((a, b) => a + Number(b), 0);
    return drsProgressTotal / drsDataCount;
  }, [drsData, standardRef]);

  return {
    progress: cleanUpProgressResult(progress),
    loading: loading,
  };
};

export const useGroupStandardProgress = ({
  standardRef,
  assessmentId,
  drRefs,
}: {
  standardRef: string;
  assessmentId: string;
  drRefs: string[];
}) => {
  const { data, loading } = useGetGroupLevelStandardProgressQuery({
    variables: {
      standardRef: standardRef,
      assessmentId: assessmentId,
      drRefs: drRefs,
    },
    skip: !standardRef || !assessmentId || !drRefs?.length,
  });

  const drsData = useMemo(
    () =>
      drRefs?.map((dr) => {
        return {
          groupLevelAnswers:
            data?.groupLevelAnswers.filter((a) => a.metric.disclosureRequirementRef === dr) ?? [],
          groupLevelMaterialMetrics:
            data?.groupLevelMaterialMetrics.filter(
              (mm) => mm.metric.disclosureRequirementRef === dr
            ) ?? [],
          subsidiaries: {
            ...(data?.subsidiaries ?? {}),
            id: data?.subsidiaries,
            subsidiaryAssessments:
              data?.subsidiaries?.subsidiaryAssessments.map((sub) => ({
                ...sub,
                materialStandards: sub.materialStandards.map((ms) => ({
                  ...ms,
                  materialMetrics:
                    ms.materialMetrics.filter((mm) => mm.metric.disclosureRequirementRef === dr) ??
                    [],
                })),
                reportingUnits: sub.reportingUnits.map((ru) => ({
                  ...ru,
                  answers: ru.answers.filter((a) => a.metric.disclosureRequirementRef === dr) ?? [],
                })),
              })) ?? [],
          },
          subsidiaryLevelMaterialMetrics:
            data?.subsidiaryLevelMaterialMetrics.filter(
              (mm) => mm.metric.disclosureRequirementRef === dr
            ) ?? [],
        };
      }) ?? [],
    [drRefs, data]
  );

  const progress = useMemo(() => {
    const drsProgressArray = drsData.map((dr) => getGroupDRProgress(dr));
    const drsDataCount = !drsData?.length ? 1 : drsData?.length;
    const drsProgressTotal = drsProgressArray.reduce((a, b) => a + Number(b), 0);
    return drsProgressTotal / drsDataCount;
  }, [drsData, standardRef]);

  return {
    progress: cleanUpProgressResult(progress),
    loading: loading,
  };
};

export const useReportingUnitStandardProgress = ({
  standardRef,
  assessmentId,
  drRefs,
  parentAssessmentId,
  reportingUnitId,
}: {
  standardRef: string;
  assessmentId: string;
  drRefs: string[];
  parentAssessmentId: string | undefined;
  reportingUnitId: string;
}) => {
  const { data, loading } = useGetReportingUnitStandardProgressQuery({
    variables: {
      standardRef: standardRef,
      assessmentId: assessmentId,
      drRefs: drRefs,
      parentAssessmentId: parentAssessmentId || assessmentId,
      reportingUnitId,
    },
    skip: !standardRef || !assessmentId || !drRefs?.length || !reportingUnitId,
  });

  const drsData = useMemo(
    () =>
      drRefs.map((dr) => {
        return {
          materialMetrics:
            data?.materialMetrics.filter((bu) => bu.metric.disclosureRequirementRef === dr) ?? [],
          reportingUnit: [
            {
              id: data?.reportingUnit?.[0].id,
              answers:
                data?.reportingUnit?.[0]?.answers.filter(
                  (a) => a.metric.disclosureRequirementRef === dr
                ) ?? [],
            },
          ],
          requiredMaterialMetrics:
            data?.requiredMaterialMetrics.filter(
              (mm) => mm.metric.disclosureRequirementRef === dr
            ) ?? [],
        };
      }),
    [drRefs, data]
  );

  const progress = useMemo(() => {
    const drsProgressArray = drsData.map((dr) => getReportingUnitDRProgress(dr));
    const drsDataCount = !drsData?.length ? 1 : drsData?.length;
    const drsProgressTotal = drsProgressArray.reduce((a, b) => a + Number(b), 0);
    return drsProgressTotal / drsDataCount;
  }, [drsData, standardRef]);

  return {
    progress: cleanUpProgressResult(progress),
    loading: loading,
  };
};

export const useGroupReportCategoryProgress = ({
  categoryRef,
  assessmentId,
}: {
  categoryRef: string;
  assessmentId: string;
}) => {
  const { data, loading } = useGetGroupLevelCategoryProgressQuery({
    variables: {
      categoryRef,
      assessmentId,
    },
    skip: !categoryRef || !assessmentId,
  });

  const standardsData = useMemo(() => {
    const standards =
      data?.EsrsCategory_by_pk?.standards.filter(
        (standard) => !!standard.materialStandards?.length
      ) ?? [];
    return (
      standards.map((standard) => {
        const materialStandard = standard.materialStandards?.[0];
        const allDrRefs = uniq(
          [
            ...(materialStandard?.subsidiaryLevelMaterialMetrics ?? []),
            ...(materialStandard?.groupLevelMaterialMetrics ?? []),
          ].map((mm) => mm.metric.disclosureRequirementRef)
        );
        const drRefs =
          standard.reference === 'ESRS-E1'
            ? allDrRefs.filter((drRef) => drRef !== 'E1-GOV-3')
            : standard.reference === 'ESRS-G1'
              ? allDrRefs.filter((drRef) => drRef !== 'G1-GOV-1')
              : allDrRefs;

        const drsData = drRefs.map((dr) => {
          return {
            subsidiaryLevelMaterialMetrics:
              materialStandard.subsidiaryLevelMaterialMetrics.filter(
                (sub) => sub.metric.disclosureRequirementRef === dr
              ) ?? [],
            groupLevelAnswers:
              data?.groupLevelAnswers.filter((ca) => ca.metric.disclosureRequirementRef === dr) ??
              [],
            groupLevelMaterialMetrics:
              standard.materialStandards?.[0]?.groupLevelMaterialMetrics.filter(
                (mm) => mm.metric.disclosureRequirementRef === dr
              ) ?? [],
            subsidiaries: {
              ...(data?.subsidiaries ?? {}),
              id: data?.subsidiaries,
              subsidiaryAssessments:
                data?.subsidiaries?.subsidiaryAssessments.map((sub) => ({
                  ...sub,
                  materialStandards: sub.materialStandards.map((ms) => ({
                    ...ms,
                    materialMetrics:
                      ms.materialMetrics.filter(
                        (mm) => mm.metric.disclosureRequirementRef === dr
                      ) ?? [],
                  })),
                  reportingUnits: sub.reportingUnits.map((ru) => ({
                    ...ru,
                    answers:
                      ru.answers.filter((a) => a.metric.disclosureRequirementRef === dr) ?? [],
                  })),
                })) ?? [],
            },
            requiredMaterialMetrics: [],
          };
        });
        const drsProgressArray = drsData.map((dr) => getGroupDRProgress(dr));
        const drsDataCount = !drsData?.length ? 1 : drsData?.length;
        const drsProgressTotal = drsProgressArray.reduce((a, b) => a + Number(b), 0);
        return drsProgressTotal / drsDataCount;
      }) ?? []
    );
  }, [data]);

  const progress = useMemo(() => {
    const standardsDataCount = !standardsData?.length ? 1 : standardsData?.length;
    const standardsProgressTotal = standardsData.reduce((a, b) => a + Number(b), 0);
    return standardsProgressTotal / standardsDataCount;
  }, [standardsData]);

  return {
    progress: cleanUpProgressResult(progress),
    loading: loading,
  };
};

export const useCompanyReportCategoryProgress = ({
  categoryRef,
  assessmentId,
}: {
  categoryRef: string;
  assessmentId: string;
}) => {
  const { data, loading } = useGetCompanyLevelCategoryProgressQuery({
    variables: {
      categoryRef,
      assessmentId,
    },
    skip: !categoryRef || !assessmentId,
  });

  const standardsData = useMemo(() => {
    const standards =
      data?.EsrsCategory_by_pk?.standards.filter(
        (standard) => !!standard.materialStandards?.length
      ) ?? [];
    return (
      standards.map((standard) => {
        const materialStandard = standard.materialStandards?.[0];
        const allDrRefs = uniq(
          [
            ...(materialStandard?.buLevelMaterialMetrics ?? []),
            ...(materialStandard?.companyLevelMaterialMetrics ?? []),
          ].map((mm) => mm.metric.disclosureRequirementRef)
        );
        const drRefs =
          standard.reference === 'ESRS-E1'
            ? allDrRefs.filter((drRef) => drRef !== 'E1-GOV-3')
            : standard.reference === 'ESRS-G1'
              ? allDrRefs.filter((drRef) => drRef !== 'G1-GOV-1')
              : allDrRefs;

        const drsData = drRefs.map((dr) => {
          return {
            buLevelMaterialMetrics:
              materialStandard.buLevelMaterialMetrics.filter(
                (bu) => bu.metric.disclosureRequirementRef === dr
              ) ?? [],
            companyLevelAnswers:
              data?.companyLevelAnswers.filter((ca) => ca.metric.disclosureRequirementRef === dr) ??
              [],
            companyLevelMaterialMetrics:
              standard.materialStandards?.[0]?.companyLevelMaterialMetrics.filter(
                (mm) => mm.metric.disclosureRequirementRef === dr
              ) ?? [],
            reportingUnits:
              data?.reportingUnits.map((ru) => ({
                ...ru,
                answers: ru.answers.filter((a) => a.metric.disclosureRequirementRef === dr) ?? [],
              })) ?? [],
            requiredMaterialMetrics: [],
          };
        });
        const drsProgressArray = drsData.map((dr) => getRegularCompanyDRProgress(dr));
        const drsDataCount = !drsData?.length ? 1 : drsData?.length;
        const drsProgressTotal = drsProgressArray.reduce((a, b) => a + Number(b), 0);
        return drsProgressTotal / drsDataCount;
      }) ?? []
    );
  }, [data]);

  const progress = useMemo(() => {
    const standardsDataCount = !standardsData?.length ? 1 : standardsData?.length;
    const standardsProgressTotal = standardsData.reduce((a, b) => a + Number(b), 0);
    return standardsProgressTotal / standardsDataCount;
  }, [standardsData]);

  return {
    progress: cleanUpProgressResult(progress),
    loading: loading,
  };
};

export const useGetGroupCompanyReportProgress = (assessmentId: string) => {
  const { data, loading } = useGetGroupLevelProgressQuery({
    variables: {
      assessmentId,
    },
    skip: !assessmentId,
  });

  const standardsData = useMemo(
    () =>
      data?.EsrsAssessment_by_pk?.materialStandards.map((materialStandard) => {
        const drRefs = uniq(
          [
            ...(materialStandard?.subsidiaryLevelMaterialMetrics ?? []),
            ...(materialStandard?.groupLevelMaterialMetrics ?? []),
          ].map((mm) => mm.metric.disclosureRequirementRef)
        );

        const drsData = drRefs.map((dr) => {
          return {
            subsidiaryLevelMaterialMetrics:
              materialStandard.subsidiaryLevelMaterialMetrics.filter(
                (sub) => sub.metric.disclosureRequirementRef === dr
              ) ?? [],
            groupLevelAnswers:
              data?.groupLevelAnswers.filter((ca) => ca.metric.disclosureRequirementRef === dr) ??
              [],
            groupLevelMaterialMetrics:
              materialStandard?.groupLevelMaterialMetrics.filter(
                (mm) => mm.metric.disclosureRequirementRef === dr
              ) ?? [],
            subsidiaries: {
              ...(data?.EsrsAssessment_by_pk?.subsidiaryAssessments ?? {}),
              id: data?.EsrsAssessment_by_pk?.id,
              subsidiaryAssessments:
                data?.EsrsAssessment_by_pk?.subsidiaryAssessments.map((sub) => ({
                  ...sub,
                  materialStandards: sub.materialStandards.map((ms) => ({
                    ...ms,
                    materialMetrics:
                      ms.materialMetrics.filter(
                        (mm) => mm.metric.disclosureRequirementRef === dr
                      ) ?? [],
                  })),
                  reportingUnits: sub.reportingUnits.map((ru) => ({
                    ...ru,
                    answers:
                      ru.answers.filter((a) => a.metric.disclosureRequirementRef === dr) ?? [],
                  })),
                })) ?? [],
            },
            requiredMaterialMetrics: [],
          };
        });
        const drsProgressArray = drsData.map((dr) => getGroupDRProgress(dr));
        const drsDataCount = !drsData?.length ? 1 : drsData?.length;
        const drsProgressTotal = drsProgressArray.reduce((a, b) => a + Number(b), 0);
        return drsProgressTotal / drsDataCount;
      }) ?? [],
    [data]
  );

  const progress = useMemo(() => {
    const standardsDataCount = !standardsData?.length ? 1 : standardsData?.length;
    const standardsProgressTotal = standardsData.reduce((a, b) => a + Number(b), 0);
    return standardsProgressTotal / standardsDataCount;
  }, [standardsData]);

  return {
    progress: cleanUpProgressResult(progress),
    loading: loading,
  };
};

export const useGetCompanyLevelReportProgress = (assessmentId: string) => {
  const { data, loading } = useGetCompanyLevelProgressQuery({
    variables: {
      assessmentId,
    },
    skip: !assessmentId,
  });

  const standardsData = useMemo(
    () =>
      data?.EsrsAssessment_by_pk?.materialStandards?.map((materialStandard) => {
        const drRefs = uniq(
          [
            ...(materialStandard?.buLevelMaterialMetrics ?? []),
            ...(materialStandard?.companyLevelMaterialMetrics ?? []),
          ].map((mm) => mm.metric.disclosureRequirementRef)
        );
        const drsData = drRefs.map((dr) => {
          return {
            buLevelMaterialMetrics:
              materialStandard.buLevelMaterialMetrics.filter(
                (bu) => bu.metric.disclosureRequirementRef === dr
              ) ?? [],
            companyLevelAnswers:
              data?.companyLevelAnswers.filter((ca) => ca.metric.disclosureRequirementRef === dr) ??
              [],
            companyLevelMaterialMetrics:
              materialStandard?.companyLevelMaterialMetrics.filter(
                (mm) => mm.metric.disclosureRequirementRef === dr
              ) ?? [],
            reportingUnits:
              data?.reportingUnits.map((ru) => ({
                ...ru,
                answers: ru.answers.filter((a) => a.metric.disclosureRequirementRef === dr) ?? [],
              })) ?? [],
            requiredMaterialMetrics: [],
          };
        });
        const drsProgressArray = drsData.map((dr) => getRegularCompanyDRProgress(dr));
        const drsDataCount = !drsData?.length ? 1 : drsData?.length;
        const drsProgressTotal = drsProgressArray.reduce((a, b) => a + Number(b), 0);
        return drsProgressTotal / drsDataCount;
      }) ?? [],
    [data]
  );

  const progress = useMemo(() => {
    const standardsDataCount = !standardsData?.length ? 1 : standardsData?.length;
    const standardsProgressTotal = standardsData.reduce((a, b) => a + Number(b), 0);
    return standardsProgressTotal / standardsDataCount;
  }, [standardsData]);

  return {
    progress: cleanUpProgressResult(progress),
    loading: loading,
  };
};
