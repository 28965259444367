import { Button, Infobox, Tooltip } from 'Atoms';
import { AggregatedQualitativeAnswers } from '../../AggregatedMetrics/AggregatedMetrics.hooks';
import { GeneratedSummaryStatus, MetricsTableData, TimePeriodsEnums } from '../../..';
import { useEffect, useMemo, useRef, useState } from 'react';
import { summarizeNarrativeAnswers } from '../../MetricAI/AIRequestFunctions';
import { useCurrentCompany, useToast } from 'utils/hooks';
import { Typography } from 'Tokens';
import {
  HStack,
  MenuButton,
  VStack,
  useToast as useChakraToast,
  useDisclosure,
} from '@chakra-ui/react';
import { AIIcon, RefreshIcon, RemoveIcon } from 'Tokens/Icons/Function';
import { useTranslation } from 'utils/translation';
import { usePopulateMetricSummary } from './AnswersSummarizer.hook';
import { useFeatureFlags } from 'containers/Navigation';
import { Menu } from 'Molecules/Menu';
import { ChevronDownIcon } from 'Tokens/Icons/Direction';
import { AIProgressModal, InteractionBlocker } from '../../MetricAI/AIUtils';
import { Language } from 'utils/language';

const cleanUpHTMLText = (text: string) => {
  return (
    text
      .replace(/(<([^>]+)>)/gi, ' ')
      .replace(/\n/g, ' ')
      .trim() ?? ''
  );
};

export const AnswersSummarizer = ({
  metric,
  answersData,
  companyReportingUnit,
  generatedSummaryStatus,
  setGeneratedSummaryStatus,
}: {
  metric: MetricsTableData['metric'];
  answersData: AggregatedQualitativeAnswers[number];
  companyReportingUnit?: string;
  generatedSummaryStatus?: GeneratedSummaryStatus;
  setGeneratedSummaryStatus?: React.Dispatch<React.SetStateAction<GeneratedSummaryStatus>>;
}) => {
  const { t } = useTranslation('ai');

  const company = useCurrentCompany();
  const { hasAiAccess } = useFeatureFlags(company);

  const aiConfig = useMemo(() => {
    return {
      preferredLanguage: company.company?.aiConfig?.preferredLanguage ?? Language.EnglishUK,
      toneOfVoice: company.company?.aiConfig?.toneOfVoice ?? '',
    };
  }, [company.company?.aiConfig]);

  const { preferredLanguage, toneOfVoice } = aiConfig;

  const abortControllerRef = useRef<AbortController | null>(null);

  const [generatedSummary, setGeneratedSummary] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const isApproved = useMemo(() => {
    return (
      (generatedSummaryStatus?.isApproved &&
        generatedSummaryStatus.metricRef === metric.reference) ??
      false
    );
  }, [generatedSummaryStatus, metric]);

  const hasGeneratedSummary = useMemo(() => {
    return !!generatedSummary && generatedSummaryStatus?.metricRef === metric.reference;
  }, [generatedSummary, generatedSummaryStatus, metric]);

  const [showMore, setShowMore] = useState(false);
  const numberOfLines = useMemo(
    () => Math.ceil((generatedSummary?.length ?? 1) / 48),
    [generatedSummary]
  );

  const toast = useToast();
  const chakraToast = useChakraToast();

  const isForBusinessUnits = useMemo(() => !!answersData.reportingUnits, [answersData]);

  const answers = useMemo(() => {
    if (isForBusinessUnits) {
      return answersData.reportingUnits;
    }
    return answersData.subsidiaries;
  }, [answersData, isForBusinessUnits]);

  const metricAnswers = useMemo(() => {
    return {
      metricRef: answersData.metricRef,
      metricTitle: metric?.title,
      metricDescription: cleanUpHTMLText(metric?.description ?? ''),
      subsidiaries: answersData.subsidiaries?.map((s) => s.subsidiary.company.name),
      businessUnits: answersData.reportingUnits?.map((bu) => bu.reportingUnit.name),
      answers: answers
        ?.map(
          (a) => a.answer?.datapoints.find((dp) => dp.timeframe === TimePeriodsEnums.year)?.value
        )
        .filter((a) => !!a),
    };
  }, [answersData, metric, answers]);

  const { populateAnswer } = usePopulateMetricSummary({ answersData });

  const generateAggregatedNarrativeAnswers = async () => {
    const answersString = metricAnswers.answers?.join(' - ') ?? '';
    const subsidiariesString = metricAnswers.subsidiaries?.map((s) => `"${s}"`).join(', ') ?? '';
    const businessUnitsString =
      metricAnswers.businessUnits?.map((bu) => `"${bu}"`).join(', ') ?? '';

    if (!answersString) {
      throw new Error(t('esrs.summary.noSubsidiaryAnswers'));
    }

    const timedToastId = toast({
      text: t('esrs.summary.writingSummary'),
      closable: true,
      duration: null,
    });

    try {
      setIsGenerating(true);

      abortControllerRef.current = new AbortController();

      const summarizedAnswer = await summarizeNarrativeAnswers(
        metricAnswers.metricTitle,
        metricAnswers.metricDescription ?? '',
        answersString,
        isForBusinessUnits ? businessUnitsString : subsidiariesString,
        isForBusinessUnits,
        preferredLanguage,
        toneOfVoice,
        abortControllerRef.current.signal
      );

      const aggregatedAnswerData = summarizedAnswer.data.summary;

      setIsGenerating(false);
      chakraToast.close(timedToastId);

      return aggregatedAnswerData;
    } catch (error) {
      setIsGenerating(false);

      chakraToast.close(timedToastId);

      if (abortControllerRef.current?.signal.aborted) {
        toast({
          text: 'Summary generation cancelled',
        });
      } else {
        toast({
          variant: 'danger',
          text: t('esrs.errors.summary'),
        });
        console.log(error);
      }
    }
  };

  const handleGenerate = async () => {
    try {
      const summary = await generateAggregatedNarrativeAnswers();
      setGeneratedSummary(summary);
      setGeneratedSummaryStatus?.({
        isApproved: false,
        metricRef: metricAnswers.metricRef ?? '',
      });
    } catch (error) {
      toast({
        text: isForBusinessUnits
          ? t('esrs.summary.noBusinessUnitAnswers')
          : t('esrs.summary.noSubsidiaryAnswers'),
      });
    }
  };

  const onApprove = () => {
    try {
      populateAnswer(generatedSummary, companyReportingUnit ?? '');
      setGeneratedSummaryStatus?.({
        isApproved: true,
        metricRef: metricAnswers.metricRef ?? '',
      });
    } catch (error) {
      toast({
        text: t('esrs.errors.populate'),
      });
    }
  };

  const {
    isOpen: isAIProgressModalOpen,
    onClose: onAIProgressModalClose,
    onOpen: onAIProgressModalOpen,
  } = useDisclosure();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (isGenerating) {
        // Check if the click is on the InteractionBlocker
        const blocker = document.getElementById('interaction-blocker');
        if (blocker && blocker.contains(e.target as Node)) {
          onAIProgressModalOpen();
        }
      }
    };

    if (isGenerating) {
      window.addEventListener('click', handleClick);
    } else {
      window.removeEventListener('click', handleClick);
      onAIProgressModalClose();
    }

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [isGenerating, onAIProgressModalOpen, onAIProgressModalClose]);

  return (
    <>
      <Infobox
        status="info"
        closable={false}
        withIcon={false}
        title={
          isForBusinessUnits
            ? t('esrs.summary.businessUnitsTitle')
            : t('esrs.summary.subsidiariesTitle')
        }
        description={
          <VStack spacing="20px" alignItems="start">
            <Typography variant="body">
              {isForBusinessUnits
                ? t('esrs.summary.businessUnitInfoboxTitle')
                : t('esrs.summary.subsidiaryInfoboxTitle')}
            </Typography>

            {!hasGeneratedSummary ? (
              <Tooltip label={t('common:aiConsent')} isDisabled={hasAiAccess}>
                <Button
                  isLoading={isGenerating}
                  leftIcon={<AIIcon color="inherit" />}
                  onClick={handleGenerate}
                  isDisabled={!hasAiAccess}
                >
                  {t('generateAnswer')}
                </Button>
              </Tooltip>
            ) : (
              <VStack
                spacing="16px"
                alignItems="start"
                borderTop="1px solid"
                borderColor="border.decorative"
                pt="16px"
              >
                <VStack spacing="4px" alignItems="start">
                  <HStack spacing="6px">
                    <AIIcon boxSize="18px" color="text.info" />
                    <Typography variant="h3" color="text.info">
                      {t('esrs.summary.generated')}
                    </Typography>
                  </HStack>
                  <Typography variant="body" color="text.info">
                    {t('esrs.summary.approvalTitle')}
                  </Typography>
                </VStack>

                {/* Summary display */}
                <VStack spacing="4px" w="100%" alignItems="start">
                  <Typography variant="body" noOfLines={showMore ? undefined : 5}>
                    {generatedSummary}
                  </Typography>
                  {numberOfLines > 5 && (
                    <Button
                      variant="ghost"
                      size="sm"
                      alignVertically={true}
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? 'Show less' : 'Show more'}
                    </Button>
                  )}
                </VStack>

                {/* Approval */}
                <HStack spacing="10px">
                  {isApproved ? (
                    <Button selected>{t('esrs.answers.approved')}</Button>
                  ) : (
                    <Button variant="primary" isDisabled={isGenerating} onClick={onApprove}>
                      {t('esrs.answers.approve')}
                    </Button>
                  )}

                  <Menu
                    gutter={2}
                    usePortal
                    menuButton={
                      <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon color="inherit" />}
                        size="md"
                        variant="ghost"
                        aria-label="share"
                      >
                        More options
                      </MenuButton>
                    }
                    sections={[
                      {
                        actions: [
                          {
                            id: 'try-again',
                            title: 'Try again',
                            leftElement: <RefreshIcon />,
                            onClick: () => {
                              handleGenerate();
                            },
                          },
                          {
                            id: 'discard',
                            title: 'Discard',
                            leftElement: <RemoveIcon />,
                            onClick: () => {
                              setGeneratedSummary('');
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </HStack>
              </VStack>
            )}
          </VStack>
        }
      />

      <AIProgressModal
        isOpen={isAIProgressModalOpen}
        onClose={onAIProgressModalClose}
        setIsGenerating={setIsGenerating}
        cancelRequest={() => {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
        }}
      />

      {isGenerating && <InteractionBlocker id="interaction-blocker" />}
    </>
  );
};
