import { Box } from '@chakra-ui/react';
import { ActiveStepLabels } from 'containers/Esrs/EsrsUtilComponents';
import { Control, Controller } from 'react-hook-form';
import { useMemo } from 'react';
import {
  MappedDisclosureRequirements,
  MaterialityFields,
  MaterialityState,
} from '../DoubleMaterialityAssessment.d';
import { MaterialityRadioGroup } from '../MaterialityRadioGroup';

export const AssessDisclosureRequirement = ({
  disclosureRequirement,
  control,
  showTable,
  isStandardMandatory,
  disableMaterial,
  isCollectOnly,
  updateAllMateriality,
}: {
  disclosureRequirement?: MappedDisclosureRequirements[number];
  isStandardMandatory: boolean;
  control: Control<MaterialityFields, any>;
  showTable: boolean;
  disableMaterial: boolean;
  isCollectOnly: boolean;
  updateAllMateriality: (val: MaterialityState | null) => void;
}) => {
  const drMateriality = useMemo(
    () => disclosureRequirement?.materialityStatus,
    [disclosureRequirement]
  );
  const status = useMemo(
    () =>
      isStandardMandatory
        ? MaterialityState.mandatory
        : (disclosureRequirement?.materialityStatus as MaterialityState),
    [disclosureRequirement, isStandardMandatory]
  );
  const canCollectDisclosure = useMemo(
    () =>
      drMateriality === MaterialityState.collectData ||
      drMateriality === MaterialityState.doNotCollect,
    [disclosureRequirement]
  );

  return (
    <Box
      pl="32px"
      ml="14px"
      pb="32px"
      borderLeft={showTable ? '1px dashed' : ''}
      borderColor="border.hover"
    >
      <ActiveStepLabels
        title={'Assess Disclosure Requirement'}
        hasSteps={false}
        clickable={false}
        ml="63px"
      />
      <Controller
        name="material"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <MaterialityRadioGroup
              value={value ?? MaterialityState.toAssess}
              defaultValue={status}
              isMaterialDisabled={disableMaterial}
              onChange={(val) => {
                onChange(val);
                updateAllMateriality(val);
              }}
              isCollectOnly={isCollectOnly ? canCollectDisclosure : false}
              isDr={true}
              width="613px"
            />
          );
        }}
      />
    </Box>
  );
};
