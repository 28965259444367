import { Box, Grid, VStack } from '@chakra-ui/react';
import { EmptyState } from 'Atoms';
import { ContentLayout, ContentHeader } from 'Molecules';
import { Typography } from 'Tokens';
import { MetricDataCollection } from 'containers/Esrs';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReportingUnitStandardProgress } from '../StandardProgress/StandardProgress';
import { useGetBusinessUnitStandardsOverview } from './AssessmentOverview.hooks';
import { DataCollectionLevel } from '../../DataCollection';

export const BusinessUnitStandardsOverview = ({
  businessUnitAssessmentId,
  companyAssessmentId,
  businessUnitName,
  isBusinessUnitLevel,
}: {
  businessUnitAssessmentId: string;
  companyAssessmentId: string;
  businessUnitName?: string;
  isBusinessUnitLevel: boolean;
}) => {
  const navigate = useNavigate();

  const { categories, loading } = useGetBusinessUnitStandardsOverview(
    'all',
    companyAssessmentId,
    isBusinessUnitLevel
  );

  const dataCollectionLevel = useMemo(() => {
    return isBusinessUnitLevel
      ? MetricDataCollection.reportingUnitLevel
      : MetricDataCollection.companyLevel;
  }, [isBusinessUnitLevel]);

  const filteredCategories = useMemo(() => {
    return categories.map((category) => {
      return {
        ...category,
        materialStandards: category.materialStandards.filter((standard) => {
          if (
            standard.materialMetrics?.some(
              (metric) =>
                metric.dataCollection === dataCollectionLevel &&
                standard.parentMaterialMetrics?.find((pm) => pm.metricRef === metric.metricRef)
                  ?.dataCollection === DataCollectionLevel.subsidiaries
            )
          )
            return true;
          else
            return (
              !!standard.materialMetrics?.length &&
              standard.materialMetrics?.some(
                (metric) => metric.dataCollection === dataCollectionLevel && metric.isMaterial
              )
            );
        }),
      };
    });
  }, [categories, dataCollectionLevel]);

  if (filteredCategories.filter((c) => c.materialStandards.length !== 0).length === 0) {
    return (
      <ContentLayout
        isLoading={loading}
        variant="inline"
        paddingTop="24px"
        paddingBottom="56px"
        header={<ContentHeader title={`${businessUnitName} view`} size="md" />}
      >
        <Box w="100%" flexGrow="1">
          <EmptyState
            title="Start collecting data"
            description="No available data points. Go to settings to select metrics that should be collected from this business unit"
            callToAction={{
              text: 'Go to settings',
              variant: 'secondary',
              onClick: () => navigate('settings'),
            }}
            component={true}
          />
        </Box>
      </ContentLayout>
    );
  }

  return (
    <ContentLayout
      isLoading={loading}
      variant="inline"
      paddingTop="32px"
      paddingBottom="56px"
      header={<ContentHeader title={`${businessUnitName} view`} size="md" />}
    >
      <VStack alignItems="start" spacing="40px" mt="24px">
        {/* <Box width="280px">
          <Select<{ value: string; label: string }>
            value={{
              label: MATERIAL_TOPIC_FILTER_OPTIONS.find((v) => v.value === filter)?.label ?? '',
              value: filter,
            }}
            onChange={(value) => setFilter(value?.value ? value.value : 'all')}
            options={MATERIAL_TOPIC_FILTER_OPTIONS.map((currOption) => {
              return {
                label: currOption?.label ?? '',
                value: currOption.value,
              };
            })}
            size="md"
          />
        </Box> */}
        {filteredCategories.map((category, categoryIndex) => (
          <>
            {category.materialStandards.length > 0 && (
              <VStack key={category.categoryRef} alignItems="start" width="100%">
                <Typography variant="overline">{category.categoryName}</Typography>
                <Grid templateColumns="repeat(3, 1fr)" overflow="visible" gap="12px" w="full">
                  {category.materialStandards.map((standard, standardIndex) => (
                    <ReportingUnitStandardProgress
                      standard={standard}
                      standardIndex={standardIndex}
                      categoryIndex={categoryIndex}
                      reportingUnitId={businessUnitAssessmentId}
                      key={standard.standardRef}
                      parentAssessmentId={standard.parentEsrsAssessmentId}
                      drRefs={standard.drRefs}
                    />
                  ))}
                </Grid>
              </VStack>
            )}
          </>
        ))}
      </VStack>
    </ContentLayout>
  );
};
