import { Box, HStack, VStack } from '@chakra-ui/react';
import { Alert, Tag, TruncatableText } from 'Atoms';
import { groupBy, uniqBy } from 'lodash';
import {
  Esrs_MaterialMetricTag_Constraint_,
  Esrs_MaterialMetricTag_Update_Column_,
  Esrs_MaterialTagValue_Constraint_,
  Esrs_MaterialTagValue_Update_Column_,
  EsrsAssessmentDocument_,
  GetMetricsDrDocument_,
  GetRequiredBuMaterialMetricsDocument_,
  GetRequiredMaterialMetricsDocument_,
  GetRequiredTagsMaterialMetricsQuery_,
  MaterialMetricsPerDisclosureDocument_,
  MetricRequiredTagsPerStandardDocument_,
  ReportingUnitsMetricsPerDisclosureDocument_,
  useDeleteManyMaterialTagValuesMutation,
  useUpsertBulkTagsMaterialMetricsMutation,
} from 'models';
import { Modal } from 'Molecules';
import { useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { TagValuesSelector } from './MetricConfigModal/TagSelectMenus';
import { useToast } from 'utils/hooks';

type ConfiguredTag = {
  tagType: string;
  tagValue: string;
};

export const TagBulkEditingModal = ({
  isOpen,
  onClose,
  materialMetrics,
  loading,
}: {
  isOpen: boolean;
  onClose: () => void;
  materialMetrics: GetRequiredTagsMaterialMetricsQuery_['esrs_MaterialMetric'];
  loading: boolean;
}) => {
  const toast = useToast();

  const [configuredTags, setConfiguredTags] = useState<ConfiguredTag[]>([]);

  const [upsertMaterialMetrics] = useUpsertBulkTagsMaterialMetricsMutation();
  const [deleteMaterialTagValues] = useDeleteManyMaterialTagValuesMutation();

  const groupedTags = useMemo(() => {
    const tagsWithMetrics = materialMetrics.flatMap((materialMetric) => {
      return materialMetric.metric.tags.map((tag) => ({
        materialMetricId: materialMetric.id,
        tagType: tag.type,
        materialMetric: materialMetric,
      }));
    });

    const grouped = groupBy(tagsWithMetrics, 'tagType');

    const tagsArray = Object.keys(grouped).map((tagType) => ({
      tagType,
      materialMetrics: grouped[tagType].map((item) => item.materialMetric),
      tagValues: grouped[tagType][0].materialMetric.metric.tags
        .find((tag) => tag.type === tagType)
        ?.tagType.tagValues.map((tv) => tv.value),
    }));

    return tagsArray;
  }, [materialMetrics]);

  const initialTags = useMemo(() => {
    const tagsWithValues = groupedTags.flatMap((tag) => {
      return tag.materialMetrics.flatMap((materialMetric) => {
        return materialMetric.materialMetricTags
          .filter((materialMetricTag) => materialMetricTag.tagType === tag.tagType)
          .flatMap((materialMetricTag) => {
            return materialMetricTag.materialTagValues.flatMap((materialTagValue) => {
              return {
                tagType: tag.tagType,
                tagValue: materialTagValue.tagValue,
              };
            });
          });
      });
    });

    return uniqBy(tagsWithValues, (tag) => `${tag.tagType}-${tag.tagValue}`);
  }, [groupedTags]);

  useEffect(() => {
    setConfiguredTags(initialTags);
  }, [initialTags]);

  // Deleting tagValues
  const tagsToDelete = useMemo(() => {
    return initialTags.filter(
      (tag) =>
        !configuredTags.some((ct) => ct.tagType === tag.tagType && ct.tagValue === tag.tagValue)
    );
  }, [initialTags, configuredTags]);

  const materialTagsToDelete = useMemo(() => {
    return tagsToDelete
      .map((tag) => {
        return materialMetrics
          .filter((mm) => mm.metric.tags.some((metricTag) => metricTag.type === tag.tagType))
          .map((mm) => ({
            tagType: tag.tagType,
            tagValue: tag.tagValue,
            materialMetricId: mm.id,
          }));
      })
      .flat();
  }, [tagsToDelete, materialMetrics]);

  const tagsToDeletePayload = useMemo(() => {
    return materialTagsToDelete.map((tagToDelete) => {
      return {
        tagType: { _eq: tagToDelete.tagType },
        tagValue: { _eq: tagToDelete.tagValue },
        materialMetricTag: {
          materialMetricId: { _eq: tagToDelete.materialMetricId },
        },
      };
    });
  }, [materialTagsToDelete]);

  const confirmedTags = useMemo(() => {
    return (
      materialMetrics.map((materialMetric) => {
        const correspondingTags = materialMetric.metric.tags.map((metricTag) => {
          const correspondingConfiguredTags = configuredTags.filter(
            (configuredTag) => configuredTag.tagType === metricTag.type
          );

          const correspondingTagValues = correspondingConfiguredTags.map((tag) => tag.tagValue);

          return {
            tagType: metricTag.type,
            tagValues: correspondingTagValues,
          };
        });

        return {
          materialMetricId: materialMetric.id,
          metricRef: materialMetric.metric.reference,
          materialStandardId: materialMetric.materialStandardId,
          tags: correspondingTags,
        };
      }) ?? []
    );
  }, [materialMetrics, configuredTags]);

  const handleConfirm = async () => {
    try {
      await upsertMaterialMetrics({
        variables: {
          objects: confirmedTags.map((mm) => ({
            id: mm?.materialMetricId,
            metricRef: mm?.metricRef,
            materialStandardId: mm?.materialStandardId,
            materialMetricTags: {
              data: mm?.tags.map((tag) => ({
                tagType: tag?.tagType,
                materialTagValues: {
                  data: tag?.tagValues.map((val) => ({
                    tagType: tag?.tagType,
                    tagValue: val,
                  })),
                  on_conflict: {
                    constraint:
                      Esrs_MaterialTagValue_Constraint_.MaterialTagValueMaterialMetricTagIdTagValueTagTypeKey_,
                    update_columns: [Esrs_MaterialTagValue_Update_Column_.TagValue_],
                  },
                },
              })),
              on_conflict: {
                constraint:
                  Esrs_MaterialMetricTag_Constraint_.MaterialMetricTagMaterialMetricIdTagTypeKey_,
                update_columns: [Esrs_MaterialMetricTag_Update_Column_.IsOptional_],
              },
            },
          })),
        },
        refetchQueries: [
          ReportingUnitsMetricsPerDisclosureDocument_,
          MaterialMetricsPerDisclosureDocument_,
          EsrsAssessmentDocument_,
        ],
      });

      // handle delete
      if (tagsToDelete?.length) {
        await deleteMaterialTagValues({
          variables: {
            materialMetricTagValues: tagsToDeletePayload,
          },
          refetchQueries: [
            GetMetricsDrDocument_,
            ReportingUnitsMetricsPerDisclosureDocument_,
            GetRequiredMaterialMetricsDocument_,
            GetRequiredBuMaterialMetricsDocument_,
            MaterialMetricsPerDisclosureDocument_,
            MetricRequiredTagsPerStandardDocument_,
          ],
        });
      }

      toast({
        text: 'Tags updated successfully',
      });
    } catch (error) {
      console.error(error);
      toast({
        text: 'Tags update failed',
        variant: 'danger',
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Bulk editing"
      loading={loading}
      size="md"
      onConfirm={() => {
        handleConfirm();
        onClose();
      }}
    >
      <VStack w="100%" alignItems="start" spacing="24px">
        <Alert
          status="info"
          closable={false}
          title="Note that these settings will be applied to all data points"
        />

        <VStack w="100%" alignItems="start" spacing="16px">
          <Typography variant="body">
            Configure required disaggregation. Metrics that are required by the ESRS to be
            disaggregated.
          </Typography>
          <VStack spacing="6px" w="100%" alignItems="start">
            {groupedTags.map((tag, index) => {
              return (
                <HStack
                  key={`${tag.tagType}_${index}`}
                  bgColor="bg.interactive"
                  h="48px"
                  borderRadius="6px"
                  w="100%"
                  justifyContent="space-between"
                >
                  <Box paddingInline="8px" w="172px">
                    <TruncatableText variant="bodyStrong" text={tag.tagType} />
                  </Box>
                  <Box px="8px" w="118px">
                    <Tag variant="warning">To setup ({tag.materialMetrics.length})</Tag>
                  </Box>
                  <Box px="8px">
                    <TagValuesSelector
                      options={(tag.tagValues ?? []).map((v) => ({
                        tagType: tag.tagType,
                        tagValue: v,
                      }))}
                      configured={configuredTags}
                      type={tag.tagType}
                      setConfigured={setConfiguredTags}
                    />
                  </Box>
                </HStack>
              );
            })}
          </VStack>
        </VStack>
      </VStack>
    </Modal>
  );
};
