import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import { Button, Checkbox } from 'Atoms';
import { SearchInput } from 'Molecules';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Typography } from 'Tokens';
import { TagBreakdown } from './MetricConfigModalParent';
import { useDebounce } from 'utils/hooks';

type ConfiguredTag = {
  tagType: string;
  tagValue: string;
};

type TagValuesSelectorProps = {
  options: ConfiguredTag[];
  configured: ConfiguredTag[];
  type: string;
  setConfigured: (newConfigured: ConfiguredTag[]) => void;
};

export const TagValuesSelector: React.FC<TagValuesSelectorProps> = ({
  options,
  configured,
  setConfigured,
  type,
}) => {
  const [tempChecked, setTempChecked] = useState<ConfiguredTag[]>(configured);
  const [menuOpen, setMenuOpen] = useState(false);
  const [allSelected, setAllSelected] = useState(false);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 300);
  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.tagValue.toLowerCase().includes(debouncedSearch.toLowerCase())
    );
  }, [debouncedSearch, options]);

  useEffect(() => {
    if (menuOpen) {
      setTempChecked(configured);
    }
  }, [menuOpen, configured]);

  useEffect(() => {
    if (
      tempChecked.filter((tag) => filteredOptions.some((option) => option.tagType === tag.tagType))
        .length === filteredOptions.length
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [tempChecked, filteredOptions]);

  const handleSelectTag = (tag: ConfiguredTag) => {
    setTempChecked((prevChecked) => {
      const exists = prevChecked.some(
        (item) => item.tagType === tag.tagType && item.tagValue === tag.tagValue
      );
      return exists
        ? prevChecked.filter(
            (item) => !(item.tagType === tag.tagType && item.tagValue === tag.tagValue)
          )
        : [...prevChecked, tag];
    });
  };

  const handleConfirm = () => {
    setConfigured(tempChecked);
    setMenuOpen(false);
  };

  const handleSelectAll = () => {
    if (allSelected) {
      setTempChecked([]);
      setAllSelected(false);
    } else {
      const allTags = filteredOptions.filter((tag) => {
        return !tempChecked.some(
          (item) => item.tagType === tag.tagType && item.tagValue === tag.tagValue
        );
      });
      setTempChecked([...tempChecked, ...allTags]);
      setAllSelected(true);
    }
  };

  const menuText = useMemo(() => {
    if (tempChecked.filter((tag) => tag.tagType === type).length > 0) {
      return tempChecked
        .filter((tag) => tag.tagType === type)
        .map((tag, index) =>
          index === tempChecked.filter((checkedTag) => checkedTag.tagType === type).length - 1
            ? tag.tagValue
            : tag.tagValue + ', '
        )
        .join('');
    }
    return 'Select value';
  }, [tempChecked, type]);

  return (
    <Popover
      gutter={2}
      isOpen={menuOpen}
      placement="bottom-start"
      trigger="click"
      onOpen={() => setMenuOpen(true)}
      onClose={() => setMenuOpen(false)}
    >
      <PopoverTrigger>
        <Button
          width="150px"
          h="28px"
          bg="bg.default"
          borderWidth="1px"
          borderColor="border.default"
          _hover={{ borderColor: 'border.hover' }}
          _focus={{
            borderColor: 'border.selected.accent',
            boxShadow: 'none',
          }}
          _active={{
            bg: 'bg.default',
          }}
          borderRadius="8px"
          p="8px"
          rightIcon={<ChevronDownIcon color="inherit" />}
          textAlign="left"
          overflow="hidden"
          color="text.default"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <Typography
            variant="body"
            minW="110px"
            maxW="110px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {menuText}
          </Typography>
        </Button>
      </PopoverTrigger>

      <PopoverContent as={VStack} spacing="8px" p="8px" alignItems="stretch" w="256px">
        <SearchInput
          width="100%"
          placeholder="Search"
          search={search}
          setSearch={setSearch}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (e.key === ' ' && e.target !== e.currentTarget) {
              e.preventDefault();
            }
          }}
        />
        <HStack
          key="tags-select-all"
          p="8px"
          m="0px"
          w="100%"
          _hover={{
            bg: 'bg.hover',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
          onClick={handleSelectAll}
        >
          <Checkbox pointerEvents="none" isChecked={allSelected} />
          <Typography variant="bodyStrong">Select all</Typography>
        </HStack>
        <Divider color="border.decorative" ml="-8px" pr="16px" />
        {filteredOptions.length ? (
          <Box maxH="200px" overflowY="auto">
            {filteredOptions.map((option) => (
              <HStack
                key={`menu-${option?.tagValue}`}
                p="8px"
                m="0px"
                w="100%"
                _hover={{
                  bg: 'bg.hover',
                  borderRadius: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => handleSelectTag(option)}
              >
                <Checkbox
                  onChange={() => handleSelectTag(option)}
                  isChecked={tempChecked.some(
                    (item) => item.tagType === option.tagType && item.tagValue === option.tagValue
                  )}
                />
                <Typography variant="bodyStrong">{option.tagValue}</Typography>
              </HStack>
            ))}
          </Box>
        ) : (
          <Box alignContent="center" paddingY="20px" textAlign="center">
            <Typography variant="body">No tag values found</Typography>
          </Box>
        )}
        <Divider color="border.decorative" ml="-8px" pr="16px" />
        <Button variant="primary" w="100%" onClick={handleConfirm}>
          Confirm
        </Button>
      </PopoverContent>
    </Popover>
  );
};

export const TagSelectionMenu = ({
  options,
  checked,
  setChecked,
}: {
  options: TagBreakdown[];
  checked: TagBreakdown[];
  setChecked: Dispatch<SetStateAction<TagBreakdown[]>>;
}) => {
  const [tempChecked, setTempChecked] = useState<TagBreakdown[]>([...checked]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSelectTag = (tag: TagBreakdown) => {
    setTempChecked((prev) =>
      prev.includes(tag) ? prev.filter((tagType) => tagType.type !== tag.type) : [...prev, tag]
    );
  };

  const handleConfirm = () => {
    setChecked(tempChecked);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    setTempChecked([...checked]);
  }, [checked]);

  return (
    <Menu
      size="lg"
      gutter={2}
      isOpen={isMenuOpen}
      onOpen={() => setIsMenuOpen(true)}
      onClose={() => setIsMenuOpen(false)}
    >
      <MenuButton
        as={Button}
        variant="ghost"
        leftIcon={<AddIcon color="inherit" />}
        color="text.default"
      >
        Add breakdown
      </MenuButton>
      <MenuList p="8px">
        <Typography p="8px" variant="detail">
          Add breakdown by
        </Typography>
        {options.map((tag) => {
          return (
            <MenuItem
              p="8px"
              m="0px"
              w="100%"
              closeOnSelect={false}
              onClick={() => handleSelectTag(tag)}
            >
              <HStack w="100%" key={`menu-${tag.type}`} spacing="8px" paddingX="8px">
                <Checkbox
                  onChange={() => handleSelectTag(tag)}
                  isChecked={tempChecked.includes(tag)}
                />
                <Typography variant="bodyStrong">{tag.type}</Typography>
              </HStack>
            </MenuItem>
          );
        })}
        <Divider color="border.decorative" my="8px" ml="-8px" pr="16px" />
        <Box w="100%">
          <Button variant="primary" w="100%" onClick={handleConfirm}>
            Confirm
          </Button>
        </Box>
      </MenuList>
    </Menu>
  );
};
