import { GetParentMaterialityAssessmentQuery_ } from 'models';
import { Requirement } from '../MaterialityAssessment.d';

export enum StandardMaterialityState {
  mandatory = 'mandatory',
  toAssess = 'toAssess',
  material = 'material',
  notMaterial = 'notMaterial',
  gatherData = 'gatherData',
  collectDataMandatory = 'collectDataMandatory',
  collectData = 'collectData',
  doNotCollect = 'doNotCollect',
}

export enum MetricMaterialityState {
  toAssess = 'toAssess',
  material = 'material',
  notMaterial = 'notMaterial',
  gatherData = 'gatherData',
  collectData = 'collectData',
  doNotCollect = 'doNotCollect',
}

export enum MaterialityState {
  materialMandatory = 'materialMandatory',
  mandatory = 'mandatory',
  toAssess = 'toAssess',
  material = 'material',
  notMaterial = 'notMaterial',
  gatherData = 'gatherData',
  collectDataMandatory = 'collectDataMandatory',
  collectData = 'collectData',
  doNotCollect = 'doNotCollect',
}

export enum ParentMaterialityState {
  materialMandatory = 'materialMandatory',
  mandatory = 'mandatory',
  toAssess = 'toAssess',
  material = 'material',
  notMaterial = 'notMaterial',
  gatherData = 'gatherData',
}

export type Materiality =
  | 'material'
  | 'notMaterial'
  | 'materialMandatory'
  | 'mandatory'
  | 'toAssess'
  | 'gatherData'
  | 'collectDataMandatory'
  | 'collectData'
  | 'doNotCollect';

export enum MaterialityStatus {
  material = 'Material',
  notMaterial = 'Not material',
  toAssess = 'To assess',
  gatherData = 'Not material but gather data',
  collectData = 'Collect data',
  doNotCollect = 'Do not collect',
}

export type ParentMaterialMetrics = {
  metricRef: string;
  isMaterial?: boolean | null;
  dataCollection?: string | null | undefined;
}[];

export type StandardMaterialityFields = {
  material: StandardMaterialityState | null;
};

export type MaterialityFields = {
  material: MaterialityState | null;
};

export type AdditionalType = Requirement['additionalTypes'][number]['additionalType'];

export type ParentStandardMaterialityData = NonNullable<
  NonNullable<GetParentMaterialityAssessmentQuery_['EsrsAssessment_by_pk']>['parentAssessment']
>['materialStandards'][number];

export type ParentMaterialMetricsType = ParentStandardMaterialityData['materialMetrics'];

export type MappedDisclosureRequirements = {
  title: string;
  drRef: string;
  materialityStatus: MaterialityState;
  order: number;
  parentMateriality: Materiality | MaterialityState;
  metrics: (Requirement['metrics'][number] & {
    tags: {
      type: string;
    }[];
  })[];
  description: string | null | undefined;
  additionalTypes: AdditionalType[];
  isMandatory: boolean;
}[];
